import { FC, createContext, useState } from 'react';
import { ITask } from '../models/IUser';

const TasksCtx = createContext<any>([]);

const TasksProvider: FC = ({ children }) => {
  const [tasks, setTasks] = useState<ITask[] | undefined>(undefined);

  const removeTaskById = (id: string) => {
    if (tasks?.length) {
        const newTasks = tasks.filter((task) => task.Id !== id);
        setTasks(newTasks);
    }
  }

  return (
    <TasksCtx.Provider
      value={{
         tasks,
         setTasks,
         removeTaskById: removeTaskById,
      }}
    >
      {children}
    </TasksCtx.Provider>
  );
};

export { TasksCtx, TasksProvider};