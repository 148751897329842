import styled from 'styled-components';

export const StyledOverlayBanner = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
`;

export const StyledOverlayText = styled.h1`
    color: black;
    opacity: 10%;
    transform: rotate(-27deg);
    font-family: Teko;
    font-size: 23em;
    font-weight: 400;
    text-align: left;
`