import React, { useEffect } from "react";
import {
  Path,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import { StyledErrorMessage, StyledReactHookForm } from "./Form.styled";

// Reference: https://react-hook-form.com/advanced-usage/#SmartFormComponent
type obj = {
  [key: string]: any;
};
type selectObj = { key: string; value: string };

export type FormValues = {
  fieldLabel: string,
  fieldName: string,
  fieldType: 'input' | 'select',
  fieldInputType: string,
  value?: string,
  options?: selectObj[],
  placeholder?: string,
  validate?: obj,
  validatemessage?: obj,
};

type FormProps<T extends FieldValues> = {
  defaultValues: T;
  children: React.ReactNode;
  onSubmit: SubmitHandler<T>;
  isLoading?: boolean;
  style?: React.CSSProperties;
};

export function GenericReactHookForm<T extends FieldValues>({
  defaultValues,
  children,
  onSubmit,
  isLoading,
  style,
}: FormProps<T>) {
  const methods = useForm<T>(defaultValues);
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  useEffect(() => {
    reset(defaultValues);
  }, [reset])
  const generateForm: any = (children: React.ReactNode) => {
    return Array.isArray(children) ?
      children.map((child, idx) => {
        if (child?.props?.children && Array.isArray(child.props.children) && child.props.children.find((each: any) => each?.props?.name)) {
          return generateForm(child.props.children)
        }
        return child?.props?.name ? (
          <>
            {React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                defaultValue: defaultValues[child.props.name],
                className: errors[child.props.name] ? 'hasError' : '',
              },
            })}
            {errors[child.props.name] &&
              Object.keys(child.props.validate).map((eachValidation) => {
                if (eachValidation === errors[child.props.name]!.type) {
                  return (
                    <StyledErrorMessage key={idx}>
                      {child.props.validatemessage[eachValidation]}
                    </StyledErrorMessage>
                  );
                }
                return <></>;
              })}
          </>
        ) : (
          child
        );
      })
      : children
  }

  return (
    <StyledReactHookForm onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} style={style}>
      {generateForm(children)}
    </StyledReactHookForm>
  );
}

type InputProps = {
  register?: UseFormRegister<any>;
  name: Path<any>;
  defaultValue?: string;
  [rest: string]: any;
};
export function Input({ register, name, defaultValue, ...rest }: InputProps) {
  return (
    <input key={name} {...register!(name, rest.validate)} defaultValue={defaultValue} {...rest} />
  );
}

type SelectProps = {
  register?: UseFormRegister<any>;
  name: Path<any>;
  options: selectObj[];
  defaultValue?: string;
  [rest: string]: any;
};

export function Select({ register, options, name, defaultValue, ...rest }: SelectProps) {
  return (
    <select key={name} {...register!(name, rest.validate)} defaultValue={defaultValue} {...rest}>
      <option value="">{rest.placeholder}</option>
      {options.map((each, idx) => (
        <option key={each.key} value={each.value}>
          {each.value}
        </option>
      ))}
    </select>
  );
}

type labeledSelectObject = { key: string; value: string; label: string; isDisabled?: boolean; }

type LabeledSelectProps = {
  register?: UseFormRegister<any>;
  name: Path<any>;
  options: labeledSelectObject[];
  defaultValue?: string;
  [rest: string]: any;
};

export function LabeledSelect({ register, options, name, defaultValue, ...rest }: LabeledSelectProps) {
  return (
    <select key={name} {...register!(name, rest.validate)} defaultValue={defaultValue} {...rest}>
      <option value="">{rest.placeholder}</option>
      {options.map((each, idx) => (
        <option key={each.key} value={each.value} disabled={each.isDisabled || false}>
          {each.label}
        </option>
      ))}
    </select>
  );
}

type GenerateDotProps = {
  max: number;
  current: number;
}

export function GenerateDot({ max, current }: GenerateDotProps) {
  const dot = [];

  for (let i = 0; i < max; i++) {
    const color = i === current ? '#000000' : '#898787'
    dot.push(<div style={{ backgroundColor: color, width: '8px', height: '8px', borderRadius: '50%' }}></div>)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', gap: '10px' }}>
      {
        dot.map((each) => each)
      }
    </div>
  )
}