import React, { useLayoutEffect, useState } from 'react';
import {
  StyledDisabledField,
  StyledFormLabel, 
  StyledReactHookForm,
  StyledFormFooterText
} from '../Form.styled';
import { useHistory } from 'react-router';
import { FACILITATOR_REVIEW_RESULT, IFacilitator } from '../../../models/IUser';
import Api from '../../../services/Api';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Modal, QuickModal } from '../../Modal/Modal';
import { StyledFormButtonContainerRow } from '../Form.styled';
import approveIcon from "../../../images/check.svg"; 
import { FacilitatorArchiveForm } from '../ArchiveForms/FacilitatorArchiveForm';
import { redirectBack } from '../../../utils/helper';
import { TasksCtx } from '../../../contexts/TasksContext';
import { Button } from '../../Button/Button';

interface IUserFormProps {
  facilitatorId: string,
}


interface IModalProps {
  header: string,
  message: string
}

export const FacilitatorReviewFormBasic: React.FC<IUserFormProps> = ({ facilitatorId }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCompletionModalInner, setShowCompletionModalInner] = useState<IModalProps>();
  const [archiveModal, setArchiveModal] = useState<any>();
  const [ originalFacilitator, setOriginalFacilitator ] = useState<IFacilitator | null>(null);
  const { removeTaskById } = React.useContext(TasksCtx);

  useLayoutEffect(() => {
    Api.getFacilitatorById(facilitatorId)
      .then(async (user: IFacilitator | undefined) => {
        if (!user) {
          setShowCompletionModalInner({header: "Error", message: "Facilitator not found. Please try again"});
        }
        else {
          setOriginalFacilitator(user);
        }
      })
      .catch(err => {
        if (err.message.includes("ACCESS DENIED")) {
          setShowCompletionModalInner({header: "Warning", message: "You do not have access to approve this faciliator"});

        } else {
          console.log(err);
          setShowCompletionModalInner({header: "Error", message: "Retrieve user failed. Please try again"});
        }
      })
      .finally(() => {
        
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitatorId]);

  const renderArchiveModal = (facilitatorId: string) => {
    const hideArchiveModal = () => setArchiveModal(null);
    setArchiveModal(<Modal>
      <FacilitatorArchiveForm facilitatorId={facilitatorId} onCancel={hideArchiveModal} onArchive={() => {
        hideArchiveModal();
        history.goBack();
        removeTaskById(facilitatorId);
      }} />
    </Modal>)
  }

  const reviewUser = async (reviewResult: FACILITATOR_REVIEW_RESULT) => {
    setIsLoading(true);

    return new Promise((resolve, reject) => {
      Api.reviewFacilitator(facilitatorId, reviewResult)
        .then((res) => {
          setIsLoading(false);
          if (reviewResult === FACILITATOR_REVIEW_RESULT.Approved) {
            setShowCompletionModalInner({header: "Facilitator Approved", message: "Facilitator has been approved."});
          }
          else if (reviewResult === FACILITATOR_REVIEW_RESULT.Rejected) {
            setShowCompletionModalInner({header: "Facilitator Not Approved", message: "Facilitator has been denied."});
          }
          resolve(true);
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err.message);
          setShowCompletionModalInner({header: "Error", message: 'Facilitator review was unable to be recorded, please try again.'});
          reject();
        })
      });
  };

  const getCompletionModal = () => {
    console.log(`test: ${!showCompletionModalInner?.header || !showCompletionModalInner?.message} `);
    if (!showCompletionModalInner?.header || !showCompletionModalInner?.message) return null;
    return (
      <QuickModal
        innerHeader={showCompletionModalInner?.header}
        innerText={showCompletionModalInner?.message}
        buttons={[
          {
            solid: true,
            label: "Ok",
            width: "100%",
            onClick: () => {
              removeTaskById(facilitatorId);
              redirectBack(history);
            },
          },
        ]}
      />
    );
  }

  return <>
        <StyledReactHookForm>
          <StyledFormLabel>First Name <br /><StyledDisabledField>{originalFacilitator?.FirstName}</StyledDisabledField></StyledFormLabel>
          <StyledFormLabel>Last Name <br /><StyledDisabledField>{originalFacilitator?.LastName}</StyledDisabledField></StyledFormLabel>
          <StyledFormLabel>Email <br /><StyledDisabledField>{originalFacilitator?.Email}</StyledDisabledField></StyledFormLabel>
          <StyledFormLabel>Training and Accreditation<br /><StyledDisabledField>By selecting ‘Approve’ you are declaring that this Facilitator has completed all training and certifications required for approval</StyledDisabledField></StyledFormLabel>

          <StyledFormButtonContainerRow>
            <Button solid icon={approveIcon} type="button" label="Approve" onClick={() => reviewUser(FACILITATOR_REVIEW_RESULT.Approved)} />
            <Button type="button" label="Cancel" onClick={() => history.goBack()} />
            <Button type="button" label="Archive" onClick={() => renderArchiveModal(facilitatorId)} />
          </StyledFormButtonContainerRow>
          <StyledFormFooterText align='left'>Clicking 'Approve' will send an invitation email to your Facilitator</StyledFormFooterText>
        </StyledReactHookForm>
        { isLoading && <LoadingSpinner /> }
        {showCompletionModalInner && getCompletionModal()}
        {archiveModal}
  </>

};

// Comment out the code in case we need it
// export const FacilitatorReviewForm: React.FC<IUserFormProps> = ({ facilitatorId }) => {

//   const history = useHistory();
//   const { updateBreadCrumb } = React.useContext(BreadCrumbCtx);

//   const payload = { type: BREADCRUMB_ACTIONS.FORWARD, payload: { title: 'Review', description: '', toLocation: '/' } }
 
//   useMemo(() => {
//     updateBreadCrumb(payload);
//   }, []);

//   return (
//     <StyledFormWrapper>
//       <StyledFormContainer>
//         <StyledClose onClick={() =>redirectBack(history)} />
//         <Heading level='h4' primary>REVIEW {USER_ROLE_NAMES[USER_ROLE.Facilitator].toUpperCase()}</Heading>
//         <FacilitatorReviewFormBasic facilitatorId={facilitatorId}/>
//       </StyledFormContainer>

//     </StyledFormWrapper>
//   )
// }
