import styled, { createGlobalStyle } from 'styled-components';
import { IStyledProps } from './theme/AotgAdminTheme';
import Div100vh from 'react-div-100vh';
import image from "./images/background.png"; 

export const GlobalStyle = createGlobalStyle`
  @import url(${(props: IStyledProps) => {
    return `https://fonts.googleapis.com/css?family='${props.theme.fonts.body}'&display=swap`
  }});
  * {
    box-sizing: border-box;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: ${(props: IStyledProps) => props.theme.fonts.body}, sans-serif;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
    background-image: url(${image});
    background-size: cover;
  }
  a {
    text-decoration: none;
    color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300};
  }
  iframe.fullScreen {
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width:100%;
    height:100%;
    border:none;
    margin:0;
    padding:0;
    overflow:hidden;
    z-index:999999;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .ag-theme-alpine .ag-header-container .ag-header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ag-theme-alpine .ag-cell-label-container {
    justify-content: space-around;
    flex-direction: row-reverse !important;
  }

  .ag-theme-alpine .ag-header-cell-label {
    flex: unset;
  }
`;

export const StyledEnvironmentIndicator = styled.div`
  width: 100px;
  height: 100px;
  transform: rotate(-45deg);
  position: absolute;
  left: -50px;
  top: -50px;
  display: flex;
  align-items: end;
  justify-content: center;
  text-transform: uppercase;
  white-space: pre;
  font-size: 12px;
  text-align: center;
`;

export const StyledApp = styled(Div100vh)`
  position:relative;
  margin: 0 auto;
  max-width: 1200px;
  display: block;
`;

export const StyledAppHeader = styled.header`
  flex: 1;
  color: white;
  padding: 56px 0;
  position: static;

  #header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
  }
  
  #logout {
    color: #7F7F7F;
  }

  a {
    color: ${(props: IStyledProps) => props.theme.colors.white};
  }
`;

export const StyledAppFooter = styled.footer`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const StyledAppBody = styled.div`
  min-height: calc( 100vh - 225px );
`;

export const StyledTestingTools = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledTestingToolsButtons = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 20px;
    padding: 4px;
  }
`;
