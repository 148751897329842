import React from 'react';
import { StyledLoadingSpinner } from './LoadingSpinner.styled';

interface IVideoOverlayProps {
  backgroundColour?: string
}

//A layer over a videoPlayer which holds elements inside, creating an overlay
export const LoadingSpinner: React.FC<IVideoOverlayProps> = ({ backgroundColour }) => (
    <StyledLoadingSpinner backgroundColour={backgroundColour} />
);