import React, { useState, useEffect, forwardRef } from "react";
import Api from '../../services/Api';
import editIcon from '../../images/edit.svg';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { GridFilterConfig, completedPercentRenderer, userStatusRenderer } from "./GridContainer/GridContainer";
import { LOCAL_STORAGE, SORTED_COUNTRY_OPTIONS, SORTED_SPORT_OPTIONS, USER_STATUS } from '../../constants';
import { getUserStatus, isoDateToLocalDate, sortRowsByIdFn } from "../../utils/helper";
import { ResendEmailModal } from "../ResendEmailModal/ResendEmailModal";
import { IPartner } from "../../models/IUser";
import { AgGridReact } from 'ag-grid-react';

export const DEFAULT_PARTNER_GRID_FILTERS: GridFilterConfig = {
  'status-active': {
    label: 'Active',
    isOn: true,
    filterFn: (data: IPartner) => getUserStatus(data) === USER_STATUS.Active
  },
  'status-email-sent': {
    label: 'Email Sent',
    isOn: true,
    filterFn: (data: IPartner) => getUserStatus(data) === USER_STATUS.EmailSent
  },
  'status-archived': {
    label: 'Archived',
    isOn: false,
    filterFn: (data: IPartner) => getUserStatus(data) === USER_STATUS.Archived
  }
};

//For the MANAGEMENT of facilitators
//TODO: a few 'any's getting thrown around, hard to nail down types though
export const PartnersGrid: React.FC<any> = forwardRef((props, agGridRef: any) => {
  const [rowData, setRowData] = useState();
  const [emailForResend, setEmailForResend] = useState<string>(''); //null/'' = no modal being shown

  useEffect(() => {
    Api.getPartners()
      .then((partners: any) => {
        setRowData(partners.sort(sortRowsByIdFn));
      });
  }, []);

  return <>
    {emailForResend && <ResendEmailModal email={emailForResend} onClose={() => setEmailForResend('')} />}
    <AgGridReact 
      {...props}
      ref={agGridRef}
      rowData={rowData}
      columnDefs={[
        {
          field: "Id",
          hide: true
        },
        {
          field: "FirstName",
          headerName: "Organisation",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/partner/${params.data.Id}`}>{params.value}</Link>,
          headerTooltip: "Name of the Implementation Partner",
          suppressSizeToFit: true,
          width: 100,
          flex: 1
        },
        {
          field: "RegistrationStatus",
          headerName: "Status",
          width: 180,
          cellRenderer: (params: ICellRendererParams) => {
            const ip = params.data;
            return userStatusRenderer(ip, () => setEmailForResend(ip.Email));
          },
          headerTooltip: "Status of the Email Invitation to Implementation Partner",
          suppressSizeToFit: true
        },
        {
          field: "LastLoginAt",
          headerName: "Last Login",
          cellRenderer: (params: ICellRendererParams) => {
            const ip = params.data;
            return ip.LastLoginAt ? isoDateToLocalDate(ip.LastLoginAt) : 'N/A'
          },
          headerTooltip: "Date of last login of the Implementation Partner",
          maxWidth: 140,
          suppressSizeToFit: true,
          hide: !localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled)
        },
        {
          field: "Partner_Country",
          headerName: "Country",
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value) {
              const matchedCountry = SORTED_COUNTRY_OPTIONS.find(country => country.value === params.value);
              if (matchedCountry) return matchedCountry.label;
            }
            return ''
          },
          headerTooltip: "Country of the Implementation Partner",
          width: 120
        },
        {
          field: "Partner_Sport",
          headerName: "Sport",
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value) {
              const matchedSport = SORTED_SPORT_OPTIONS.find(sport => sport.value === params.value);
              if (matchedSport) return matchedSport.label;
            }
            return ''
          },
          headerTooltip: "Sport of the Implementation Partner",
          width: 120
        },
        {
          field: "CountData.DeliveryPartnerCount",
          headerName: "Delivery Partners",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/partner/${params.data.Id}/deliverypartners`}>{params.value || 0}</Link>,
          headerTooltip: "Number of Delivery Partners assigned to Implementation Partner",
          type: "numericColumn",
          width: 110,
          suppressSizeToFit: true
        },
        {
          field: "CountData.FacilitatorCount",
          headerName: "Facilitators",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/partner/${params.data.Id}/facilitators`}>{params.value || 0}</Link>,
          headerTooltip: "Number of Facilitators assigned to Implementation Partner",
          type: "numericColumn",
          width: 110
        },
        {
          field: "CountData.CohortCount",
          headerName: "Cohorts",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/partner/${params.data.Id}/cohorts`}>{params.value || 0}</Link>,
          headerTooltip: "Number of Cohorts assigned to Implementation Partner",
          type: "numericColumn",
          width: 110
        },
        {
          field: "CountData.PlayerCount",
          headerName: "Players",
          cellRenderer: (params: ICellRendererParams) => params.value || 0,
          headerTooltip: "Number of Players assigned to Implementation Partner",
          type: "numericColumn",
          width: 110
        },
        {
          field: "CountData.PlayerCompletedCount",
          headerName: "Completed",
          cellRenderer: completedPercentRenderer,
          headerTooltip: "Percentage of Players assigned to Implementation Partner that have completed the Challenge",
          type: "numericColumn",
          width: 110
        },
        {
          field: "Edit",
          headerName: "Edit",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/edit/partner/${params.data.Id}`}><img src={editIcon} alt='edit icon' /></Link>,
          headerTooltip: "Edit the details of an Implementation Partner",
          suppressSizeToFit: true,
          suppressMenu: true,
          resizable: false,
          width: 80
        }
      ]}
    >
    </AgGridReact>
  </>
});