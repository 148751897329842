import React from "react"
import { StyledFormButtonContainerRow } from "../Forms/Form.styled"
import { Modal } from "../Modal/Modal"
import { StyledEmailModalWrapper } from "../Modal/Modal.styled"
import { Button } from "../Button/Button"
import Api from "../../services/Api"

interface IResendEmailModalProps {
  email: string,
  onClose: () => void
}

//TODO: make a generic modal?
export const ResendEmailModal: React.FC<IResendEmailModalProps> = ({ email, onClose }) => {
    const resendInvitation = async (email: string) => {
        await Api.resetPassword(email);
    }

    return <Modal onCancel={onClose}>
        <StyledEmailModalWrapper>
            <p>You are about to resend an email invitation to:</p>
            <p><b>{email}</b></p>
            <p>Are you sure you want to resend this registration email?</p>
            <StyledFormButtonContainerRow>
              <Button width="100%" label="No, cancel" onClick={onClose} />
              <Button width="100%" solid label={`Yes, resend`} onClick={() => {
                  resendInvitation(email)
                  onClose()
              }} />
            </StyledFormButtonContainerRow>
        </StyledEmailModalWrapper>
    </Modal>
}
