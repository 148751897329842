import React, { forwardRef, useState } from "react";
import editIcon from '../../images/edit.svg';
import { SESSION_STATUS, SESSION_TYPE } from "../../constants";
import { ICellRendererParams } from 'ag-grid-community';
import { Link } from "react-router-dom";
import { ICohort, ISession } from "../../models/ICohort";
import { canUserEditSession, getSessionStatus, isSessionConsideredStarted } from "../../utils/helper";
import { DisabledEditModal } from "../DisabledEditModal/DisabledEditModal";
import { useHistory } from "react-router";
import { EditIcon } from "../Modal/Modal.styled";
import { AgGridReact } from 'ag-grid-react';

interface ISessionGridProps {
  forCohortId?: string;
  sessions: ISession[] | null;
  cohort: ICohort;
  
  className?: string;
}

enum TooltipsContent {
  SessionName = 'Name of the Session',
  Type = 'Type of Session',
  DeliveryDate = 'Delivery Date of the Session',
  FacilitatorName = 'Facilitator of the Session',
  Status = 'Status of the Session',
  Edit = 'Edit the details of a Session'
}

//For the MANAGEMENT of sessions
export const SessionGrid: React.FC<ISessionGridProps> = forwardRef((props, agGridRef: any) => {
  const { forCohortId, sessions, cohort } = props;

  const history = useHistory();
  const [showDisabledModal, setShowDisabledModal] = useState<boolean>(false);

  //TODO: As part of (AOTG-867), standardise how edit denies are displayed/handled for all session types (HOAM, The Challenge) and statuses
  const editSession = (session: ISession) => {
    
    getSessionStatus(session) === "Archived"
      ? setShowDisabledModal(true) 
      : history.push(`/edit/cohort/${forCohortId}/session/${session.Id}/?${cohort.DeliveryPartnerId ? `dpOwnerId=${cohort.DeliveryPartnerId}` : `ipOwnerId=${cohort.PartnerId}`}`);
  }

  return <><AgGridReact
    {...props}
    ref={agGridRef}
    rowData={sessions && sessions.map(iSession => ({ cohort: cohort, ...iSession }))}
    tooltipShowDelay={0}
    columnDefs={[
      {
        field: "SessionName",
        headerName: "Session Name",
        cellRenderer: (params: ICellRendererParams) => {
          const fullName = cohort.ClubName + ' ' + cohort.AgeGroup + ' ' + cohort.Gender + ' ' + cohort.Audience;
          return <Link to={`/cohort/${cohort.Id}/session/${params.data.Id}`}>{fullName}</Link>
        },
        flex: 1,
        headerTooltip: TooltipsContent.SessionName
      },
      {
        field: "Type",
        headerName: "Session Type",
        cellRenderer: (params: ICellRendererParams) => {
          // const session = params.data as ISession;
          // return session.Type
          if (params.data.Type === SESSION_TYPE.HelpOutAMate) return 'HOAM';
          if (params.data.Type === SESSION_TYPE.TheChallenge) return 'The Challenge';
        },
        headerTooltip: TooltipsContent.Type
      },
      {
        field: "DeliveryDate",
        headerName: "Delivery Date",
        headerTooltip: TooltipsContent.DeliveryDate,
        suppressSizeToFit: true
      },
      {
        field: "FacilitatorName",
        headerName: "Facilitator",
        headerTooltip: TooltipsContent.FacilitatorName,
        suppressSizeToFit: true
      },
      {
        field: "Status",
        headerName: "Status",
        cellRenderer: (params: ICellRendererParams) => {
          const session = params.data as ISession;
      
          return getSessionStatus(session);
        },
        headerTooltip: TooltipsContent.Status
      },
      {
        field: "Edit",
        headerName: "Edit",
        cellRenderer: (params: ICellRendererParams) => {
          if (!canUserEditSession(params.data)) {
            return <></>
          }
          return <EditIcon onClick={() => editSession(params.data)}><img src={editIcon} alt='edit icon' /></EditIcon>
        },
        headerTooltip: TooltipsContent.Edit,
        suppressMenu: true,
        width: 80,
        resizable: false
      }
    ]}
  />
    {showDisabledModal && <DisabledEditModal onClose={() => setShowDisabledModal(false)} />}
  </>
});