import { useState } from "react";
import SearchField from "../../Search/Search";
import { GridConfig } from "../GridContainer/GridContainer";
import { GridFilterButton } from "../GridFilterButton/GridFilterButton";

interface GridFiltersProps {
    activeGrid: GridConfig
    onSearchChange: (value: string) => void
    onFilterChange: (filterId: string, value: any, searchValue?: string) => void
}

const GridFilters: React.FC<GridFiltersProps> = ({ activeGrid, onSearchChange, onFilterChange }) => {
    const [disbaleFilterButton, setDisableFilterButton] = useState<boolean>(false);
    const placeHolderText = activeGrid.title || "Search";

    const handleSearchChange = (value: string) => {
        const searching = value.length > 0
        searching ? setDisableFilterButton(true) : setDisableFilterButton(false);
        onSearchChange(value);
    }

    return (
        <>
            <SearchField placeHolderText={placeHolderText} onChange={handleSearchChange}/>
            {activeGrid.filterConfig && <GridFilterButton filterConfig={activeGrid.filterConfig} isDisabled={disbaleFilterButton} onFilterChange={onFilterChange} />}
        </>
    )
}

export default GridFilters;