import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ICohort, ISession } from '../../../models/ICohort';
import Api from '../../../services/Api';
import startIcon from "../../../images/start.svg"; 
import editIcon from "../../../images/edit.svg"; 
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  StyledContainer,
  StyledContainerHeader,
  StyledContainerTitle,
  StyledColumnContainer,
  StyledInfo,
  StyledInfoInner,
  StyledInfoLabel,
  StyledRowContainer,
  StyledButtonContainer,
  StyledFormLinkContainer
} from '../View.styled';
import React from 'react';
import { AUDIENCE_TYPE, COHORT_ARCHIVED_AFTER_DAYS, NOTIFICATION_TYPE, PAGE, SESSION_TYPE } from '../../../constants';
import { TeamsDisplay } from '../../Forms/CohortEditForm/TeamsDisplay/TeamsDisplay';
import { Modal } from '../../Modal/Modal';
import { StartSessionForm } from '../../Forms/CohortEditForm/StartSessionForm';
import { canUserEditSession, canUserViewPlayers, getSessionStatus, isSessionConsideredStarted, openFacilitatorApp } from '../../../utils/helper';
import { DisabledEditModal } from '../../DisabledEditModal/DisabledEditModal';
import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { PlayersGrid } from '../../Grids/PlayersGrid';
import { GridButton } from '../../Grids/GridButton/GridButton';
import NotificationBar from '../../NotificationBar/NotificationBar';
import ArchivedOverlay from '../../Overlay/Overlay';
import { makeBreadcrumbs } from '../../../utils/breadcrumbs';
import UserData from '../../../services/UserData';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';


interface ICohortViewProps {
  cohortId: string
  sessionId: string
  initialTabId?: string
}

export const SessionView: React.FC<ICohortViewProps> = ({ cohortId, sessionId, initialTabId = 'players' }) => {
  const history = useHistory();
  const [cohort, setCohort] = useState<ICohort | null>(null);
  const [session, setSession] = useState<ISession | null>(null);
  const [showSessionModal, setShowSessionModal] = useState<boolean>(false);

  const [showDisabledModal, setShowDisabledModal] = useState<boolean>(false);
  const [cohortUpdatedTime, setCohortUpdatedTime] = useState<number>(0);

  useEffect(() => {
    Api.getCohortById(cohortId)
      .then(async (cohort: ICohort | undefined) => {
        if (!cohort) throw new Error('Cohort not found')
        if (cohort.Sessions.Resilience?.FacilitatorId || cohort.Sessions.Literacy?.FacilitatorId) {
          let facilitatorOwnerId = '';
          if (cohort.Sessions.Resilience?.Id === sessionId && cohort.Sessions.Resilience?.FacilitatorId) {
            facilitatorOwnerId = cohort.Sessions.Resilience?.FacilitatorId;
          } else if (cohort.Sessions.Literacy?.Id === sessionId && cohort.Sessions.Literacy?.FacilitatorId) {
            facilitatorOwnerId = cohort.Sessions.Literacy?.FacilitatorId;
          } else {
            throw new Error('Invalid session facilitator ID')
          }
          const facilitator = await Api.getFacilitatorById(facilitatorOwnerId)
          if (!facilitator) throw new Error('Invalid facilitator ID');
          const facilitatorName = `${facilitator.FirstName} ${facilitator.LastName} ${facilitator.ArchivedAt ? '(Archived)' : ''}`

          setCohort(cohort);

          if (cohort.Sessions?.Resilience?.Id === sessionId) setSession({ ...cohort.Sessions.Resilience, Type: SESSION_TYPE.TheChallenge, FacilitatorName: facilitatorName });
          if (cohort.Sessions?.Literacy?.Id === sessionId) setSession({ ...cohort.Sessions.Literacy, Type: SESSION_TYPE.HelpOutAMate, FacilitatorName: facilitatorName });    
        } else {
          throw new Error('Invalid cohort ID')
        }
      });
  }, [cohortId, cohortUpdatedTime]);

  const getArchivedDate = () => {
    if (!session?.SessionStartDateTime) return false;

    const date = new Date(session.SessionStartDateTime);
    date.setDate(date.getDate() + COHORT_ARCHIVED_AFTER_DAYS.Archived)
    return date;
  }

  const sendBreadCrumbInfo = (cohort: ICohort, session: ISession) => {
    return makeBreadcrumbs(PAGE.SESSION, UserData.getUserRole(), undefined , cohort, session);
  }

  const onSessionStart = () => {
    setShowSessionModal(true);
  }

  const startButton = () => {
    if (session && isSessionConsideredStarted(session)) {
      return <>
        <GridButton icon={startIcon} label='Resume Session' onClick={() => openFacilitatorApp(cohort?.Sport)} />
      </>
    } else {
      return <>
        <GridButton icon={startIcon} label='Start Session' onClick={onSessionStart} />
      </>
    }
  }

  const editSession = (cohort: ICohort) => {
    if (session) isSessionArchived(session) ? setShowDisabledModal(true) : history.push(`/edit/cohort/${cohortId}/session/${sessionId}?${cohort.DeliveryPartnerId ? `dpOwnerId=${cohort.DeliveryPartnerId}` : `ipOwnerId=${cohort.PartnerId}`}`);
  }

  const facilitatorGuideLinks = () => {
    if (cohort) {
      switch (cohort.Audience) {
        case AUDIENCE_TYPE.players:
          return <Link to={{ pathname: 'https://movember.box.com/s/cgvbdali6k1ar7r3o715xd5xj9bm11gc' }} target={'_blank'}>View</Link>;
        case AUDIENCE_TYPE.parents:
          return <Link to={{ pathname: 'https://movember.app.box.com/s/47uda5zgbtcbnk6mjkzvjqgml5ghfmm1/file/1225474474586' }} target={'_blank'}>View</Link>;
        case AUDIENCE_TYPE.coaches:
          return <Link to={{ pathname: 'https://movember.app.box.com/s/47uda5zgbtcbnk6mjkzvjqgml5ghfmm1/file/1226151173455' }} target={'_blank'}>View</Link>;
        default:
          return null;
      }
    }
  }

  const isSessionArchived = (session: ISession): boolean => getSessionStatus(session) === 'Archived'

  return <div>
    {cohort && session
      ?
      <>
        <Breadcrumbs breadCrumbs={sendBreadCrumbInfo(cohort, session)}/>
          {
            canUserEditSession(session) &&
            <StyledButtonContainer>
              <GridButton icon={editIcon} label='Edit Session' onClick={() => editSession(cohort)} />
              {startButton()}
            </StyledButtonContainer>
          }
          <StyledContainer>
            {isSessionArchived(session) &&
              <>
                <NotificationBar type={NOTIFICATION_TYPE.INFO} message={"This Session has been Archived"} /> 
                <ArchivedOverlay />
              </> 
            }
            <StyledContainerHeader>
              <StyledContainerTitle>SESSION DETAILS</StyledContainerTitle>
            </StyledContainerHeader>
            <StyledInfo>
              <StyledInfoInner>
                <StyledColumnContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Type</StyledInfoLabel>
                    <div><b>{session.Type === SESSION_TYPE.TheChallenge ? 'The Challenge' : 'Literacy Session (HOAM)'}</b></div>
                  </StyledRowContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Facilitator</StyledInfoLabel>
                    <div><b>{session.FacilitatorName}</b></div>
                  </StyledRowContainer>
                  {session.Type === SESSION_TYPE.TheChallenge && <StyledRowContainer>
                    <StyledInfoLabel>Team Feedback</StyledInfoLabel>
                    <div>{session.IsTeamFeedbackEnabled ? <b>Enabled</b> : <b>Disabled</b>}</div>
                  </StyledRowContainer>}
                  <StyledRowContainer>
                    <StyledInfoLabel>Headcount</StyledInfoLabel>
                    {session.HeadCount ? <div><b>{session.HeadCount}</b></div> : <div><b>N/A</b></div>}
                  </StyledRowContainer>
                </StyledColumnContainer>
                <StyledColumnContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Session Start Date/Time</StyledInfoLabel>
                    <div><b>{session.SessionStartDateTime ? new Date(session.SessionStartDateTime).toLocaleString() : <div>N/A</div>}</b></div>
                  </StyledRowContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Session End Date/Time</StyledInfoLabel>
                    <div><b>{session.SessionStartDateTime ? getArchivedDate().toLocaleString() : <div>N/A</div>}</b></div>
                  </StyledRowContainer>
                  {session.Type === SESSION_TYPE.TheChallenge && session.IsTeamFeedbackEnabled && <StyledRowContainer>
                    <StyledInfoLabel>Team Names</StyledInfoLabel>
                    <div><b><TeamsDisplay teams={session.Teams} /></b></div>
                  </StyledRowContainer>}
                </StyledColumnContainer>
              </StyledInfoInner>
              <StyledFormLinkContainer>
                <StyledColumnContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Facilitator Guide</StyledInfoLabel>
                    <div><b>
                      {session.Type === SESSION_TYPE.HelpOutAMate ? facilitatorGuideLinks() : <Link to={{ pathname: 'https://movember.box.com/s/shzbenf12e1w9hhjdmq03gj3v1826zme' }} target={'_blank'}>View</Link>}
                    </b></div>
                  </StyledRowContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Facilitator Presentation</StyledInfoLabel>
                    <div><b>{session.Type === SESSION_TYPE.TheChallenge ? <Link to={{ pathname: 'https://movember.app.box.com/s/tm4yiagmi3xq1qelu2aygcwmsvv9rez2' }} target={'_blank'}>View</Link> : 'N/A'}</b></div>
                  </StyledRowContainer>
                </StyledColumnContainer>
              </StyledFormLinkContainer>
            </StyledInfo>
            {session.Type === SESSION_TYPE.TheChallenge && canUserViewPlayers(cohort) && <>
              <GridContainer gridConfigs={[{
                id: 'players',
                title: 'Players',
                gridElement: <PlayersGrid forCohortId={cohortId} />
              }]} / >
            </>}
          </StyledContainer>
          {showSessionModal && <Modal onCancel={() => setShowSessionModal(false)}>
            <StartSessionForm
              cohortId={cohortId}
              sessionId={sessionId}
              onClose={() => setShowSessionModal(false)}
              onSuccess={(headCount: number, sessionStartDateTime: string) => {
                setShowSessionModal(false);

                // force a reload of our current session/cohort.
                setCohortUpdatedTime(Date.now())
              }} />
          </Modal>}
        {showDisabledModal && <DisabledEditModal onClose={() => setShowDisabledModal(false)} />}
      </>
      : <LoadingSpinner />}
  </div >;
}
