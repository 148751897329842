import React, { useLayoutEffect, useState } from 'react';
import { StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormWrapper, StyledPopupFormContainer } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm } from '../ReactHookForm';
import Api from '../../../services/Api';
import { IRemainingSessions } from '../../../models/ICohort';
import { Modal } from '../../Modal/Modal';
import { Link } from 'react-router-dom';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Button } from '../../Button/Button';

interface IUserFormProps {
  facilitatorId: string;
  onArchive?: () => void;
  onError?: () => void;
  onCancel?: () => void;
}

interface ISessionData {
  cohortId: string[];
  sessionId: string[];
  sessionTitles: string[];
}

export const FacilitatorArchiveForm: React.FC<IUserFormProps> = ({ facilitatorId, onArchive, onError, onCancel }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isInital, setIsInital] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [remainingSessionsModal, setRemainingSessionsModal] = useState<boolean>(false);

  const [sessionData, setSessionData] = useState<ISessionData>({
    cohortId: [],
    sessionId: [],
    sessionTitles: [],
  });

  useLayoutEffect(() => {
    Api.getRemainingSessions(facilitatorId).then((sessions: IRemainingSessions[][]) => {
      //sessions[0] is the past sessions
      const remainingSessionInfo = sessions[1].map(session => ({
        CohortId: session.CohortId,
        SessionId: session.Id,
        SessionTitle: session.CohortTitle,
      }));

      if (remainingSessionInfo.length > 0) {
        setSessionData({
          ...sessionData,
          cohortId: remainingSessionInfo.map(session => session.CohortId),
          sessionId: remainingSessionInfo.map(session => session.SessionId),
          sessionTitles: remainingSessionInfo.map(session => session.SessionTitle),
        });
  
        setRemainingSessionsModal(true);
      }
      setIsInital(true);
    })
  },[]);

  const archiveFacilitator = (facilitatorId: string) => {
    setIsLoading(true);
    Api.archiveFacilitator(facilitatorId)
      .then((res: any) => {
        setIsLoading(false);
        onArchive && onArchive();
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        console.log(err.message);
        if (err.message.includes('sessions remaining')) {
         
        } else {
          setErrorMessage('Network Error. Please try again');
          onError && onError();
        }
      })
  }

  const onSubmit = () => {
    setErrorMessage('');
    archiveFacilitator(facilitatorId);
  }

  const displayRemainingSessionsModal = () => {
    return <>
      <Modal onCancel={() => setRemainingSessionsModal(false)}>
        <StyledPopupFormContainer>
            <Heading level='h4' primary >
              <span style={{color: 'rgba(0, 0, 0, 0.5)', fontSize:'95%'}}>
                facilitator assigned to sessions
              </span>
            </Heading>

            
          <GenericReactHookForm<any> defaultValues={{}} onSubmit={onSubmit} isLoading={isLoading}>
            <br/>
            <div>
            The facilitator you are trying to archive is currently assigned to one or more sessions. 
            </div>
            <br/>
            <div>
            Please reassign the below session/s and retry
            </div>
            <br/>
            {sessionData.sessionTitles && sessionData.sessionTitles.map((title, index) => (
              <div>
                <Link target='_blank' to={`/edit/cohort/${sessionData.cohortId[index]}/session/${sessionData.sessionId[index]}`} key={index}>{title}</Link>
              </div>
            ))}

            <StyledFormButtonContainerRow>
              <Button width="100%" onClick={(e) => {
                e.preventDefault(); //form will try to submit without this
                onCancel && onCancel();
              }} label="Cancel" />
              <Button width="100%" solid type="submit" label={'Archive'} />
            </StyledFormButtonContainerRow>
            <StyledErrorMessage>
              {errorMessage}
            </StyledErrorMessage>
          </GenericReactHookForm>
        </StyledPopupFormContainer>
      </Modal>
    </>
  }

  return <>
    {!isInital ? 
      <LoadingSpinner />
    : 
    remainingSessionsModal
      ?
      displayRemainingSessionsModal()
      :
      <StyledFormWrapper>
        <StyledFormContainer>
          <Heading level='h4' primary>
            CONFIRM FACILITATOR ARCHIVE
          </Heading>
          <GenericReactHookForm<any> defaultValues={{}} onSubmit={onSubmit} isLoading={isLoading}>
            <br />
            <div>Once a Facilitator is archived, you may only restore them by contacting the Movember Admin Team</div>
            <br />
            <div>You can still view Archived data on the Admin Portal by using the Filters provided.</div>
            <br />

            <div>Are you sure you want to continue?</div>

            <StyledFormButtonContainerRow>
              <Button width="100%" onClick={(e) => {
                e.preventDefault(); //form will try to submit without this
                onCancel && onCancel();
              }} label="Cancel" />
              <Button width="100%" solid type="submit" label={'Archive'} />
            </StyledFormButtonContainerRow>
            <StyledErrorMessage>
              {errorMessage}
            </StyledErrorMessage>
          </GenericReactHookForm>
        </StyledFormContainer>
      </StyledFormWrapper>}</>
};