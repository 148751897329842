import { NOTIFICATION_TYPE } from "../../constants";
import { StyledCloseButton, StyledContainerDiv, StyledMessage } from "./NotificationBar.styled";
import InfoIcon from '../../images/info.svg';
import { useEffect, useState } from "react";

interface IOverlayProps {
    type: NOTIFICATION_TYPE;
    message: string;
}

const overlaySybmol = (type: NOTIFICATION_TYPE) => {
    switch (type) {
        case NOTIFICATION_TYPE.INFO:
            return InfoIcon;
    }
}

const NotificationBar : React.FC<IOverlayProps> = ({ type, message }) => {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {
        // Sliding up overlay after 3 seconds
        const timeout = setTimeout(() => {
            setIsVisible(false)
        }, 3000)

        return () => clearTimeout(timeout) // Cleanup function to clear the timeout if the component unmounts
    })

    return (
        <StyledContainerDiv type={type} isVisible={isVisible}>
            <img src={overlaySybmol(type)} />
            <StyledMessage type={type}>{message}</StyledMessage>
            <StyledCloseButton onClick={() => setIsVisible(false)}/>
        </StyledContainerDiv>
    )
}



export default NotificationBar;