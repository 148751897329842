import styled from 'styled-components';

export const StyledDropdownContainer = styled.div`
  position: absolute;
  min-width: 100%;
  top:100%;
  left: 0px;
  z-index: 99;
  border: 1px solid ${props => props.theme.colors.black}cc;
  background: ${props => props.theme.colors.white};
`;

export const StyledDropdownOption = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.black}33;
  }
`;

export const StyledDropdownButtonContainer = styled.div`
  position: relative;
`;