import { hotjar } from 'react-hotjar';

export const HotjarInit = () => {
    const siteId = 5015423;
    const hotjarVersion = 6;

    hotjar.initialize({
      id: siteId,
      sv: hotjarVersion,
    });
    console.log('Hotjar initialised');
}