import React, { useLayoutEffect } from 'react';
import { TaskGrid } from '../Grids/TaskGrid';
import { GridContainer } from '../Grids/GridContainer/GridContainer';
import { makeTaskBreadcrumb } from '../../utils/breadcrumbs';
import { PAGE } from '../../constants';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

export const MyTaskView: React.FC = () => {

    const sendInfoToBreadcrumb = () => {
        return makeTaskBreadcrumb(PAGE.TASK);
    }
    
    return (
        <>
            <Breadcrumbs breadCrumbs={sendInfoToBreadcrumb()}/>
            <GridContainer gridConfigs={[{
                id: 'tasks',
                gridElement: <TaskGrid className='task-grid' />
            }]} />
        </>
)
}