import React, { useEffect, useState } from 'react';
import { StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormWrapper } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm } from '../ReactHookForm';
import Api from '../../../services/Api';
import { ICohort, ISession } from '../../../models/ICohort';
import { ReactComponent as Alert } from '../../../images/alert.svg';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Button } from '../../Button/Button';
import { getSessionStatus } from '../../../utils/helper';

interface IUserFormProps {
  cohortId: string;
  onArchive?: () => void;
  onError?: () => void;
  onCancel: () => void;
}

export const CohortArchiveForm: React.FC<IUserFormProps> = ({ cohortId, onArchive, onError, onCancel }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cohort, setCohort] = useState<ICohort | null>(null);

  useEffect(() => {
    Api.getCohortById(cohortId)
      .then(async (cohort: ICohort | undefined) => {
        if (!cohort) throw new Error('Cohort not found');
        setCohort(cohort);
      });
  }, [cohortId]);

  const archiveCohort = (cohortId: string) => {
    setIsLoading(true);
    Api.archiveCohort(cohortId)
      .then((res: any) => {
        setIsLoading(false);
        onArchive && onArchive();
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        console.log(err.message);
        setErrorMessage('Network Error. Please try again');
        onError && onError();
      })
  }

  const onSubmit = () => {
    setErrorMessage('');
    archiveCohort(cohortId);
  }

  const displayActiveSessionAlert = () => {
    if (!cohort) return null;

    const { Resilience: resilienceSessions, Literacy: literacySessions } = cohort.Sessions || {};
    const isSessionActive = (session?: ISession) => session && getSessionStatus(session) === "Active";
    const areSessionsActive = [resilienceSessions, literacySessions].some((session) => isSessionActive(session));

    return (areSessionsActive &&
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '2rem', marginBottom: '0.5rem' }}>
        <Alert width={'4rem'} />
        <p>All sessions assigned to this cohort will also be archived with this action</p>
      </div>)
  }

  return <StyledFormWrapper>
    {cohort
      ?
      <StyledFormContainer>
        <Heading level='h4' primary>
          CONFIRM COHORT ARCHIVE
        </Heading>
        <GenericReactHookForm<any> defaultValues={{}} onSubmit={onSubmit} isLoading={isLoading}>
          {displayActiveSessionAlert()}
          <br />
          <div>Once a Cohort is archived, you may only restore them by contacting the Movember Admin Team.</div>
          <br />
          <div>Are you sure you want to continue?</div>
          <StyledFormButtonContainerRow>
            <Button width="100%" type="button" onClick={(e) => {
              e.preventDefault(); //form will try to submit without this
              onCancel();
            }} label="Cancel" />
            <Button width="100%" solid type="submit" label={'Archive'} />
          </StyledFormButtonContainerRow>
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
        </GenericReactHookForm>
      </StyledFormContainer> :
      <LoadingSpinner />}
  </StyledFormWrapper>

};