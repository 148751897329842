import React from "react"
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner"
import { StyledModalWrapper, StyledModalContainer, StyledModalWrapperCloseButton, StyledDisabledNotificationWrapper, StyledDisabledNotificationHeading } from "./Modal.styled"
import { Button, IButtonProps } from "../Button/Button"
import { StyledFormButtonContainerRow } from "../Forms/Form.styled"

interface IModalProps {
  loading?: boolean,
  onCancel?: () => void
};

export const Modal: React.FC<IModalProps> = ({ onCancel, loading, children }) => {
  return <StyledModalWrapper>
    {
      loading 
      ? <LoadingSpinner />
      : <>
        {onCancel && <StyledModalWrapperCloseButton onClick={onCancel} />}
        <StyledModalContainer>
          { children }
        </StyledModalContainer>
      </>
    }
  </StyledModalWrapper>
}

interface IQuickModalProps {
  innerHeader: string,
  innerText: any, 
  buttons: IButtonProps[]
}

export const QuickModal: React.FC<IQuickModalProps> = ({innerHeader, innerText, buttons}) => {
  return <Modal>
    <StyledDisabledNotificationWrapper>
      <StyledDisabledNotificationHeading>{innerHeader}</StyledDisabledNotificationHeading>
      <p>
        {innerText}
      </p>

      <StyledFormButtonContainerRow>
        {buttons.map((buttonProps, idx) => (
           <Button
            key={idx}
            {...buttonProps}
          />
        ))}
      </StyledFormButtonContainerRow>
    </StyledDisabledNotificationWrapper>
  </Modal>;
}