import styled from "styled-components";
import { Button } from "../../Button/Button";

export const StyledGridButton = styled(Button)`
  padding: 8px 9px;

  h5 {
    font-size: 1rem;
    line-height: 1em;
  }
`;