import React, { ReactNode } from "react"
import { StyledInfoPopupWrapper, StyledInfoPopupContainer, StyledInfoPopupCloseButton } from "./InfoPopup.styled";

interface IInfoPopupProps {
  children: ReactNode[] | ReactNode,
  value: React.ReactNode,
};

export const InfoPopup: React.FC<IInfoPopupProps> = ({ children, value }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return <StyledInfoPopupWrapper>
    {isOpen && <StyledInfoPopupContainer>
        <StyledInfoPopupCloseButton onClick={() => setIsOpen(false)} />

        {value}
      </StyledInfoPopupContainer>
    }
    <a onClick={() => setIsOpen(!isOpen)}>{children}</a>
  </StyledInfoPopupWrapper>
}
