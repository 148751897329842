import React, { useEffect } from 'react';
import { StyledTaskNavigation, StyledTaskNumber } from './TaskNavigation.styled';
import { useHistory } from 'react-router';
import { TasksCtx } from '../../contexts/TasksContext';
import Api from '../../services/Api';
import { ITask } from '../../models/IUser';
import { sortByFunctions, sortRowsByDateFn } from '../../utils/helper';
import { IS_FACILITATOR_REVIEW_DISABLED } from '../../constants';

export const TaskNavigation: React.FC = () => {

    const history = useHistory();
    const { tasks, setTasks } = React.useContext(TasksCtx);

    useEffect(() => {
        if (!IS_FACILITATOR_REVIEW_DISABLED && !tasks) { //no api call if IS_FACILITATOR_REVIEW_DISABLED
            Api.getMyTasks()
            .then((tasks: ITask[]) => {
              const sortedTasks = tasks.sort((a, b) => sortByFunctions(a, b, [sortRowsByDateFn]));
              setTasks(sortedTasks);
            });
        }
    }, []);

    if(IS_FACILITATOR_REVIEW_DISABLED) return null; // no display if IS_FACILITATOR_REVIEW_DISABLED

    return (
        <>
            {<StyledTaskNavigation onClick={() => {history.push( '/task')} }>
                Tasks <StyledTaskNumber tasks={tasks}>{tasks ? tasks.length : '...'}</StyledTaskNumber>
            </StyledTaskNavigation>
            }
        </>
    )
}