import styled from 'styled-components';
import { AotgAdminTheme } from '../../theme/AotgAdminTheme';

export const StyledInfoPopupWrapper = styled.div`
  display: inline-block;
  position: relative;

  a {
    cursor: pointer;
    user-select: none;
  }
`;

export const StyledInfoPopupContainer = styled.div`
  display: block;
  position: absolute;
  top:100%;
  left:0;
  width: 400px;
  padding: 1rem;
  padding-right: 2rem;
  background: ${AotgAdminTheme.colors.white};
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
  z-index: 1;
`;

export const StyledInfoPopupCloseButton = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  :before, :after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    background: ${AotgAdminTheme.colors.black};
    width: 1.25rem;
    height: 0.2rem;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  :before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;
