import styled from "styled-components";
import { IStyledProps } from "../../../theme/AotgAdminTheme";

export const StyledAccessCodeContainer = styled.div`
  display: flex;
  gap: 7px; 
  align-items: center; 
  margin-top: -3px;

  b {
    border: 1px solid ${(props: IStyledProps) => props.theme.colors.black}; 
    padding: 3px;
    height: 24px;
    text-align: center; 
  }

  img {
    display: flex;
    width: 14px; 
    height: 16px; 
  }

`;

