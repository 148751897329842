import Environment, { ENVIRONMENT_TYPE } from './Environment';
import { AotgAdminTheme } from '../theme/AotgAdminTheme';

declare global {
    interface Window {
      dataLayer: any[];
    }
  }
  
  const DataLayer = {
    init() {
        const breakpoints = AotgAdminTheme.breakpoints;
        const viewportWidth = document.documentElement.clientWidth;
        const uiExperience =
          (viewportWidth <= +breakpoints.xs.replace('px', '') && 'mobile') ||
          (viewportWidth <= +breakpoints.sm.replace('px', '') && 'tablet') ||
          (viewportWidth <= +breakpoints.md.replace('px', '') && 'small desktop') ||
          (viewportWidth <= +breakpoints.lg.replace('px', '') && 'large desktop') ||
          (viewportWidth <= +breakpoints.xl.replace('px', '') && 'extra large desktop') ||
          undefined;
        // initialising google tag manager
        const GTM_IDS = 'GTM-KKFPTZN';
        this.push({
          content: {
            environment: Environment.getCurrentEnvironment(),
            uiExperience,
          },
        });

        // Send the initial route
        this.pushRouteChange(window.location.pathname, '/', window.location.pathname);

      const setDataLayerSnippet = (w: any, d: any, s: any, l: any, i: any) => {
        w[l] = w[l] || []; // eslint-disable-line no-param-reassign
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      };
      setDataLayerSnippet(window, document, 'script', 'dataLayer', GTM_IDS);
    },
  
    push(data: any) {
      if (!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD)) console.log(data);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    },
  
    pushRouteChange(to: string, from: string, pathName: string) {
      //The title is the last portion of the route (eg. about, contact, research)
      const urlArray = pathName.split('/').filter((p) => p !== '');
      const title = urlArray.length > 0 ? urlArray[0] : '/';
  
      this.push({
        event: 'page_view',
        current_uri: to,
        last_uri: from,
        page_title: title,
      });
    },
};

export default DataLayer;