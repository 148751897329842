import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ICohort, ISession } from '../../../models/ICohort';
import Api from '../../../services/Api';
import { SessionGrid } from '../../Grids/SessionGrid';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import {
  StyledContainer,
  StyledContainerHeader,
  StyledContainerTitle,
  StyledContainerRightItems,
  StyledInfo,
  StyledInfoLabel,
  StyledInfoInner,
  StyledColumnContainer,
  StyledRowContainer,
  StyledFormLinkContainer,
} from '../View.styled';
import { StyledAccessCodeContainer } from './CohortView.styled'
import regenerateIcon from '../../../images/regenerate.svg';
import React from 'react';
import { CreateNewButton } from '../../CreateNewButton/CreateNewButton';
import { AUDIENCE_TYPE, NOTIFICATION_TYPE, PAGE, SESSION_TYPE } from '../../../constants';
import { InfoPopup } from '../../InfoPopup/InfoPopup';
import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { GridButton } from '../../Grids/GridButton/GridButton';
import NotificationBar from '../../NotificationBar/NotificationBar';
import { sortSessionsByStatus, getCohortStatus } from '../../../utils/helper';
import ArchivedOverlay from '../../Overlay/Overlay';
import { makeBreadcrumbs } from '../../../utils/breadcrumbs';
import UserData from '../../../services/UserData';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

interface ICohortViewProps {
  cohortId: string
  initialTabId?: string
}

export const CohortView: React.FC<ICohortViewProps> = ({ cohortId, initialTabId = 'players' }) => {
  const history = useHistory();

  const [cohort, setCohort] = useState<ICohort | null>(null);
  // we want sessions to begin as 'null' because it's passed into the sessions grid. the grid only displays 'Loading' while 'sessions' = null (empty array will not show 'Loading')
  const [sessions, setSessions] = useState<ISession[] | null>(null); 

  useEffect(() => {
    Api.getCohortById(cohortId)
      .then(async (cohort: ICohort | undefined) => {
        if (!cohort) throw new Error('Cohort not found');
        setCohort(cohort);

        let sessionArray: ISession[] = [];
        if (cohort.Sessions.Resilience?.Id) sessionArray.push({ ...cohort.Sessions.Resilience, Type: SESSION_TYPE.TheChallenge });
        if (cohort.Sessions.Literacy?.Id) sessionArray.push({ ...cohort.Sessions.Literacy, Type: SESSION_TYPE.HelpOutAMate });
        sessionArray = await getFacilitatorToSession(sessionArray);

        // sorting sessions by status. archived session at bottom
        sessionArray = sortSessionsByStatus(sessionArray);
        
        setSessions(sessionArray);
      });
  }, [cohortId]);

  const getFacilitatorToSession = async (sessions: ISession[]) => {
    let newSessionArray = [];
    for (const eachSession of sessions) {
      if (eachSession.FacilitatorId) {
        const facilitator = await Api.getFacilitatorById(eachSession.FacilitatorId);
        if (facilitator) {
          eachSession.FacilitatorName = facilitator.FirstName + ' ' + facilitator?.LastName;
        }
      }
      newSessionArray.push(eachSession)

    }
    return newSessionArray
  }

  const sendBreadCrumbInfo = (cohort: ICohort) => {
    return makeBreadcrumbs(PAGE.COHORT, UserData.getUserRole(), undefined , cohort);
  }

  const onCodeRegenerate = async (e: any) => {
    e.preventDefault();

    const newAccessCode = await Api.regenerateCohortAccessCode(cohortId);
    if (cohort && newAccessCode) {
      let newCohort = { ...cohort, AccessCode: newAccessCode };
      setCohort(newCohort);
    } else {
      // reload the page, and do the thingie
      history.go(0);
    }
  };

  const isCohortArchived = (cohort: ICohort): boolean => getCohortStatus(cohort) === 'Archived';

  return <div>
    {cohort
      ? <>
          <Breadcrumbs breadCrumbs={sendBreadCrumbInfo(cohort)}/>
          <StyledContainer>
            {
              isCohortArchived(cohort) && 
              <>
                <NotificationBar type={NOTIFICATION_TYPE.INFO} message={"This Cohort has been Archived"} />
                <ArchivedOverlay />
              </>
            }
            <StyledContainerHeader>
              <StyledContainerTitle>COHORT DETAILS</StyledContainerTitle>
              <StyledContainerRightItems>
                <GridButton onClick={() => history.push(`/edit/cohort/${cohortId}`)} label='Edit Cohort' />
                <CreateNewButton canAddSessionForCohort={cohort} appendIdString={`${cohort.Id}/session?${cohort.DeliveryPartnerId ? `dpOwnerId=${cohort.DeliveryPartnerId}` : `ipOwnerId=${cohort.PartnerId}`}`} />
              </StyledContainerRightItems>
            </StyledContainerHeader>
            <StyledInfo>
              <StyledInfoInner>
                <StyledColumnContainer>
                  {cohort.Audience === AUDIENCE_TYPE.players && <>
                    <StyledRowContainer>
                      <StyledInfoLabel>Access code</StyledInfoLabel>
                      {!!cohort.Sessions.Resilience ? 
                        <StyledAccessCodeContainer>
                          <b>{cohort.AccessCode}</b>{!cohort.Sessions.Resilience.SessionStartDateTime && <><a href="" onClick={e => onCodeRegenerate(e)}><img src={regenerateIcon} alt='regenerate icon' /></a></>}
                        </StyledAccessCodeContainer>
                      : <div data-access-code={cohort.AccessCode}><i>{`Create 'The Challenge' session first`}</i></div>} 
                      {/* Kept the accessCode as a data prop for dev usage */}
                    </StyledRowContainer></>}
                  {/* display audience for other cohorts */}
                  {cohort.Audience !== AUDIENCE_TYPE.players &&
                    <StyledRowContainer>
                      <StyledInfoLabel>Cohort Audience</StyledInfoLabel>
                      <div><b className='audience'>{cohort.Audience ? `${cohort.Audience}` : 'undefined'}</b></div>
                    </StyledRowContainer>}
                  <StyledRowContainer>
                    <StyledInfoLabel>Postcode</StyledInfoLabel>
                    <div>{cohort.Postcode ? <span><b>{cohort.Postcode}</b></span> : <i>No postcode set</i>}</div>
                  </StyledRowContainer>
                  { cohort.IsDemo && <StyledRowContainer>
                    <StyledInfoLabel>Is Demo</StyledInfoLabel>
                    <div><b>TRUE</b> <InfoPopup value={<>Demo mode allows facilitators, delivery partners, and partners to test out the Challenge session. Players will <b>not</b> be able to register or login to the provided Access code.</>}>(What's demo?)</InfoPopup></div>
                  </StyledRowContainer>}
                </StyledColumnContainer>
                {/* display audience for player cohorts */}
                <StyledColumnContainer>
                  {cohort.Audience === AUDIENCE_TYPE.players &&
                    <StyledRowContainer>
                      <StyledInfoLabel>Cohort Audience</StyledInfoLabel>
                      <div><b className='audience'>{cohort.Audience ? `${cohort.Audience}` : 'undefined'}</b></div>
                    </StyledRowContainer>}
                  <StyledRowContainer>
                    <StyledInfoLabel>Cohort Gender</StyledInfoLabel>
                    <div><b>{cohort.Gender ? `${cohort.Gender}` : 'undefined'}</b></div>
                  </StyledRowContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Cohort Age Group</StyledInfoLabel>
                    <div><b>{cohort.AgeGroup ? `${cohort.AgeGroup}` : 'undefined'}</b></div>
                  </StyledRowContainer>
                </StyledColumnContainer>
              </StyledInfoInner>
              <StyledFormLinkContainer>
                <StyledColumnContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Incident Report Link</StyledInfoLabel>
                    <div><b>N/A</b></div>
                  </StyledRowContainer>
                  <StyledRowContainer>
                    <StyledInfoLabel>Self-Reflection Survey Link</StyledInfoLabel>
                    <div><b><Link to={{ pathname: 'https://movember.qualtrics.com/jfe/preview/previewId/46ce6087-347d-4bb7-beaa-bd097736b32a/SV_b1OVtzURiPye83c?Q_CHL=preview&Q_SurveyVersionID=current' }} target={'_blank'} rel={'noopener noreferrer'}>www.box.com/form</Link></b></div>
                  </StyledRowContainer>
                </StyledColumnContainer>
              </StyledFormLinkContainer>
            </StyledInfo>
            <StyledContainerHeader>
              <StyledContainerTitle>ORGANISATION DETAILS</StyledContainerTitle>
            </StyledContainerHeader>
            <StyledInfo>
              <StyledColumnContainer>
                <StyledRowContainer>
                  <StyledInfoLabel>Organisation Name</StyledInfoLabel>
                  <div><b>{cohort.ClubName}</b></div>
                </StyledRowContainer>
                <StyledRowContainer>
                  <StyledInfoLabel>Contact Name</StyledInfoLabel>
                  <div><b>{cohort.ClubContactName ? `${cohort.ClubContactName}` : 'undefined'}</b></div>
                </StyledRowContainer>
                <StyledRowContainer>
                  <StyledInfoLabel>Contact Number</StyledInfoLabel>
                  <div><b>{cohort.ClubContactPhoneNumber ? `${cohort.ClubContactPhoneNumber}` : 'N/A'}</b></div>
                </StyledRowContainer>
                <StyledRowContainer>
                  <StyledInfoLabel>Contact Email</StyledInfoLabel>
                  <div><b>{cohort.ClubContactEmail ? `${cohort.ClubContactEmail}` : 'undefined'}</b></div>
                </StyledRowContainer>
                <br />
                <StyledRowContainer>
                  <StyledInfoLabel>Equipment</StyledInfoLabel>
                  {cohort.EquipmentAvailable ? <div><b>{cohort.EquipmentAvailable}</b></div> : <div><b>N/A</b></div>}
                </StyledRowContainer>
                <StyledRowContainer>
                  <StyledInfoLabel>Additional Information</StyledInfoLabel>
                  {cohort.Notes ? <div><b>{cohort.Notes}</b></div> : <div><b>N/A</b></div>}
                </StyledRowContainer>
              </StyledColumnContainer>
            </StyledInfo>
            <GridContainer
              key={sessions?.toString()} //used to trigger rerender when sessions changes
              gridConfigs={[{
                id: 'sessions',
                title: 'Sessions',
                gridElement: <SessionGrid className='session-grid' forCohortId={cohortId} sessions={sessions} cohort={cohort} />,
                count: sessions?.length ?? 0
              }]}
            />
          </StyledContainer>
      </>
      : <LoadingSpinner />}
  </div>;
}