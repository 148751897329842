import styled from 'styled-components';
import { IStyledProps } from '../../../theme/AotgAdminTheme';

interface IStyledCohortEditFormProps extends IStyledProps {
  isTeamFeedbackEnabled: boolean
}

export const StyledTeamFeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.5rem;
`;

export const StyledTeamFeedbackTitle = styled.div`
  font-size: 18px;
`;

export const StyledTeamFeedbackSubTitle = styled.div`
  margin-bottom: 0.2rem;
  font-size: 0.75rem;
  color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark500};
  opacity: 0.6;
  margin-bottom: ${(props: IStyledCohortEditFormProps) => props.isTeamFeedbackEnabled ? '0rem' : '2.6rem' };
`;

export const StyledTeamsContainer = styled.div`
  display: ${(props: IStyledCohortEditFormProps) => props.isTeamFeedbackEnabled ? 'initial' : 'none' };
`;

export const StyledAddContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > input {
    width:calc(100% - 60px);
  }
  
`

export const StyledAddButton = styled.div`
  color: black;
  border: 1px solid black;
  width: 52px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 18px;
    
  }
`;

export const StyledRemove = styled.div`
  color: blue;
  padding-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledContent = styled.ul`
  padding-inline-start: 20px;
  margin: 24px 0 0 0;
  & span {
   color: black;
   opacity: 1;
   font-weight: 600;
 }
`;

export const StyledHint = styled.div`
  font-size:12px;
  margin-top:5px;
`;