import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme/AotgAdminTheme';
import { Button } from '../Button/Button';

import closeIcon from "../../images/close.svg"; 


interface IStyledFormProps extends IStyledProps {
  isLoading?: boolean
}

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc( 100vh - 225px );
`;

export const StyledForm = styled.form`
  position: relative;
  width: 70%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }

  ${(props: IStyledFormProps) => props.isLoading && css`
    > * {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`;

export const StyledHeadingContainer = styled.div`
  margin: 15px 0 30px;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }
`;

export const StyledDescContainer = styled.div`
  opacity: 0.6;
  margin-top: 2rem;
  font-size: 16px;
  color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark500};
`;

interface ITextProps extends IStyledProps {
  primary?: boolean //undefined, true = 1em, false = 0.75em. 
}

export const StyledFormText = styled.div`
  color: ${(props: ITextProps) => props.theme.colors.brandThemeDark500};
  font-size: ${(props: ITextProps) => props.primary === false ? '0.75em' : '1em'}
`;

export const StyledFormTerms = styled(StyledFormText).attrs({ primary: false })`
  opacity: 0.7;
  margin-bottom: 1.5em;
  a {
    text-decoration: underline;
    color: inherit;
  }
`;

interface IButtonProps extends IStyledProps {
  primary?: boolean
}

export const StyledFormButtonContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
`;

export const StyledFormButtonContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const StyledErrorMessage = styled.div`
  color: #FF0000;
  font-size:12px;
  line-height: 15px;
  margin-top:3px;
`;

export const StyledReactHookForm = styled.form`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #242424;

  > * {
    width: 100%;
  }

  span {
    opacity: 0.6;
  }

  input, select {
    height: 40px;
    font-size:16px;
    padding: 0 10px;
    outline: 1px solid;
    border-radius: 2px;
    border: 0;

    &.hasError {
      outline: 1px solid #ff0000;
    }
    
    :focus {
      outline: 2px solid #2183C8;
    }
  }

  input[type=checkbox] {
    height: unset;
    outline: unset;
  }

  > select {
      border-right: 10px solid transparent
  }

  ${(props: IStyledFormProps) => props.isLoading && css`
    > * {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`;

export const StyledFormLabel = styled.label`
  margin: 24px 0px 3px 0px;
  font-size: 16px;

  &.countrySport {
    text-transform: capitalize;
  }
`;

export const StyledFormContainer = styled.div`
  position: relative;
  width:400px;
  margin: 50px auto;
  background: #D9D9D9;
  padding: 40px 40px;
`;

export const StyledPopupFormContainer = styled.div`
  width:410px;
  margin: 50px auto;
  background: #D9D9D9;
  padding: 40px 40px;
`;

export const StyledDisabledField = styled.div`
  opacity: 0.7;
  margin-top: 8px;
`;

export const StyledFormFooterText = styled.div<{ align?: 'center' | 'right' | 'left' }>`
  font-size: 12px;
  text-align: ${(props) => props.align || 'center'};
  opacity: 0.7;
  margin-top: 1rem;
`;

export const StyledClose = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    transform: translate(50%, -50%);
    background-image: url(${closeIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;