//a function component checkbox that takes a label and onChange event
import React from 'react';
import { StyledCheckbox, StyledCheckboxBox } from './Checkbox.styled';
import { v4 as uuid} from "uuid";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  const id = `cb-${uuid()}`;

  return <StyledCheckbox
      htmlFor={id}
      onChange={(e: any) => onChange(e.target.checked)}>
        <StyledCheckboxBox checked={checked} />
        <input id={id} type="checkbox" name="checkbox" checked={checked} />{label}
    </StyledCheckbox>
};