import React, { useState } from 'react';
import { StyledTabPanel, StyledTabButton, StyledTabPanelContent, StyledSuperScript } from './TabPanel.styled';
import { StyledContainerRightItems, StyledContainerHeader } from '../View/View.styled';

interface ITab {
  id: string,
  label: string | JSX.Element,
  super?: string | number,
  content: JSX.Element
}

interface ITabPanelProps {
  tabs: ITab[]
  initialTabId?: string
  navButtons?: JSX.Element[],
  onTabChange?: (tabId: string, index: number) => void
}


//Root view for admins. Holds a list of PARTNERS 
export const TabPanel: React.FC<ITabPanelProps> = ({ initialTabId = 'facilitators', tabs, navButtons, onTabChange}) => {
  const [activeTabId, setActiveTabId] = useState(initialTabId || tabs[0].id);
  //renderedTabIds are used so that tabs are simply hidden/shown after their first display (instead of destroyed/re-rendered)
  //this prevents excessive re-rendering, but more importantly, excessive API calls
  const [renderedTabIds, setRenderedTabIds] = useState<string[]>([activeTabId]);

  const setActiveTab = (tabId: string, index: number) => {
    if(!renderedTabIds.includes(tabId)) setRenderedTabIds([...renderedTabIds, tabId]);
    setActiveTabId(tabId);
    if(onTabChange) onTabChange(tabId, index);
  };

  return <StyledTabPanel>
    <StyledContainerHeader>
      {tabs.map((tab, index) => <StyledTabButton key={index} isActive={activeTabId === tab.id} onClick={() => setActiveTab(tab.id, index)}>
        {tab.label}
        {tab.super !== undefined && <StyledSuperScript>{tab.super}</StyledSuperScript>}
      </StyledTabButton>)}
      {navButtons && <StyledContainerRightItems>{navButtons}</StyledContainerRightItems>}
    </StyledContainerHeader>
      {tabs.map((tab, index) => {
        if(!renderedTabIds.includes(tab.id)) return null;
        return <StyledTabPanelContent key={index} isActive={activeTabId === tab.id}>{tab.content}</StyledTabPanelContent>;
      })}
  </StyledTabPanel>
};