import Environment, { ENVIRONMENT_TYPE } from "./services/Environment";

export const LOCAL_STORAGE = {
  redirectOnLogin: 'redirectOnLogin',
  testingToolsEnabled: 'testingToolsEnabled'
};

export const USER_ROLE = {
  Admin: 1,
  Partner: 2,
  Facilitator: 3,
  Player: 4,
  SuperAdmin: 5,
  DeliveryPartner: 6
};

export enum SESSION_TYPE {
  TheChallenge = 'Resilience', //the challenge
  HelpOutAMate = 'Literacy' //HOAM - help out a mate
}

export enum AUDIENCE_TYPE {
  players = 'players',
  parents = 'parents',
  coaches = 'coaches',
  null = ''
}

export enum CHAPTER_IDENTIFIER {
  ManagingYourThoughts = 'managing-your-thoughts', // chapter 1
  ProblemSolving = 'problem-solving', // chapter 2
  PlayingToYourStrengths = 'playing-to-your-strengths', // chapter 3
  ControllingTheControllables = 'controlling-the-controllables', //chapter 4
  KeepingYourCool = 'keeping-your-cool', // chapter 5
}

export const USER_ROLE_NAMES = {
  [USER_ROLE.Admin]: 'Admin',
  [USER_ROLE.Partner]: 'Implementation Partner',
  [USER_ROLE.Facilitator]: 'Facilitator',
  [USER_ROLE.Player]: 'Player',
  [USER_ROLE.SuperAdmin]: 'Super Admin',
  [USER_ROLE.DeliveryPartner]: 'Delivery Partner'
}

export const HTTP_METHOD = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
}

// SESSION_STATUS and USER_STATUS are used in the frontend ONLY
// They are inferred from other fields (eg. deliveryDate, archivedAt, etc)
export const SESSION_STATUS = {
  Active: 'active',
  Pending: 'pending',
  Archived: 'archived',
  Null: '',
};

export const USER_STATUS = {
  PendingReview: 'pendingreview', //Pending review
  EmailSent: 'emailsent', // the user has been invited to the portal, but not logged in yet
  Active: 'active', //a user who can log into the portal/app and do things
  Archived: 'archived', //a user who has been soft deleted
};

export const COHORT_ARCHIVED_AFTER_DAYS = {
  Pending: 53, //53 days after start session date
  Archived: 60 //60 days after start session date
}

//HOLD UP: Changing the SPORT_TYPE enums? They're on the frontend/admin portal/backend too! Make sure you change them all
export enum SPORT_TYPE {
  Rugby = 'rugby-league',
  AustralianRules = 'australian-rules',

  WoWTest = 'wow-test',
}

//HOLD UP: Changing the COUNTRY_TYPE enums? They're on the frontend/admin portal/backend too! Make sure you change them all
export enum COUNTRY_TYPE {
  AU = 'australia',
  GB = 'england',
}

// above are the enum values for each country and sport
// below are the sorted countries/sports plus their display labels

const COUNTRY_OPTIONS = [{value: COUNTRY_TYPE.GB, label: 'England'}, {value: COUNTRY_TYPE.AU, label: 'Australia'}];
export const SORTED_COUNTRY_OPTIONS = COUNTRY_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));
const SPORT_OPTIONS = [{value: SPORT_TYPE.Rugby, label: 'Rugby League'}, {value: SPORT_TYPE.AustralianRules, label: 'Australian Rules'}];
if (!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD)) {
  SPORT_OPTIONS.push({
    value: SPORT_TYPE.WoWTest, label: "World of Warcraft"
  })
}
export const SORTED_SPORT_OPTIONS = SPORT_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));

export const TOTAL_CHAPTER_NUMBER = 5;

export const DEMO_MODE_ACTIVE = Environment.getCurrentEnvironment().isForDebugging;
export const IS_FACILITATOR_REVIEW_DISABLED = !Environment.getCurrentEnvironment().isForDebugging;

export enum TASK_TYPE {
  facilitatorApprove = 'Facilitator Approval'
}

export enum NOTIFICATION_TYPE {
  INFO = "info",
}

export enum PAGE {
  ADMIN = "Admin",
  PARTNER = 'Implementation Partner',
  DELIVERY_PARTNER = 'Delivery Partner',
  FACILITATOR = 'Facilitator',
  COHORT = 'Cohort',
  SESSION = 'Session',
  TASK = 'Task',
  TASK_DETAIL = 'Task Detail',
}