import React, { useState, useEffect, forwardRef } from "react";
import Api from '../../services/Api';
import editIcon from '../../images/edit.svg';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { GridFilterConfig, completedPercentRenderer, userStatusRenderer } from "./GridContainer/GridContainer";
import { getUserStatus, isoDateToLocalDate, sortRowsByIdFn } from "../../utils/helper";
import { LOCAL_STORAGE, SORTED_COUNTRY_OPTIONS, SORTED_SPORT_OPTIONS, USER_STATUS } from "../../constants";
import { ResendEmailModal } from "../ResendEmailModal/ResendEmailModal";
import { IDeliveryPartner } from "../../models/IUser";
import { AgGridReact } from 'ag-grid-react';

export const DEFAULT_DELIVERY_PARTNER_GRID_FILTERS: GridFilterConfig = {
  'status-active': {
    label: 'Active',
    isOn: true,
    filterFn: (data: IDeliveryPartner) => getUserStatus(data) === USER_STATUS.Active
  },
  'status-email-sent': {
    label: 'Email Sent',
    isOn: true,
    filterFn: (data: IDeliveryPartner) => getUserStatus(data) === USER_STATUS.EmailSent
  },
  'status-archived': {
    label: 'Archived',
    isOn: false,
    filterFn: (data: IDeliveryPartner) => getUserStatus(data) === USER_STATUS.Archived
  }
};

interface IDeliveryPartnersGridProps {
  forPartnerId?: string
}

enum TooltipsContent {
  FirstName = 'Name of the Delivery Partner',
  RegistrationStatus = 'Status of the Email Invitation to Delivery Partner',
  Country = 'Country of the Delivery Partner',
  Sport = 'Sport of the Delivery Partner',
  PlayerCompletedCount = 'Percentage of Players assigned to Delivery Partner that have completed the Challenge',
  Edit = 'Edit the details of a Delivery Partner',
}

//For the MANAGEMENT of delivery partners
export const DeliveryPartnersGrid: React.FC<IDeliveryPartnersGridProps> = forwardRef((props, agGridRef: any) => {
  const { forPartnerId } = props;

  const [rowData, setRowData] = useState();
  const [emailForResend, setEmailForResend] = useState<string>(''); //null/'' = no modal being shown

  useEffect(() => {
    Api.getDeliveryPartners(forPartnerId)
      .then((deliverypartners: any) => {
        const sortedRowData = deliverypartners.sort(sortRowsByIdFn);
        setRowData(sortedRowData);
      });
  }, [forPartnerId]);

  return <>
    {emailForResend && <ResendEmailModal email={emailForResend} onClose={() => setEmailForResend('')} />}
    <AgGridReact 
      {...props}
      ref={agGridRef}
      tooltipShowDelay={0}
      rowData={rowData}
      columnDefs={[
        {
          field: "Id",
          hide: true
        },
        {
          field: "FirstName",
          headerName: "Organisation",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/deliverypartner/${params.data.Id}`}>{params.value}</Link>,
          headerTooltip: "Name of the Delivery Partner",
          flex: 1,
          suppressSizeToFit: true
        },
        {
          field: "RegistrationStatus",
          headerName: "Status",
          minWidth: 170,
          cellRenderer: (params: ICellRendererParams) => userStatusRenderer(params.data, () => setEmailForResend(params.data.Email)),
          headerTooltip: "Status of the Email Invitation to Delivery Partner",
          suppressSizeToFit: true
        },
        {
          field: "LastLoginAt",
          headerName: "Last Login",
          cellRenderer: (params: ICellRendererParams) => {
            const dp = params.data;
            return dp.LastLoginAt ? isoDateToLocalDate(dp.LastLoginAt) : 'N/A'
          },
          headerTooltip: "Date of last login of the Delivery Partner",
          maxWidth: 140,
          suppressSizeToFit: true,
          hide: !localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled)
        },
        {
          field: "DeliveryPartner_Country",
          headerName: "Country",
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value) {
              const matchedCountry = SORTED_COUNTRY_OPTIONS.find(country => country.value === params.value);
              if (matchedCountry) return matchedCountry.label;
            }
            return ''
          },
          headerTooltip: "Country of the Delivery Partner",
          width: 120
        },
        {
          field: "DeliveryPartner_Sport",
          headerName: "Sport",
          cellRenderer: (params: ICellRendererParams) => {
            if (params.value) {
              const matchedSport = SORTED_SPORT_OPTIONS.find(sport => sport.value === params.value);
              if (matchedSport) return matchedSport.label;
            }
            return ''
          },
          headerTooltip: "Sport of the Delivery Partner",
          width: 120
        },
        {
          field: "CountData.FacilitatorCount",
          width: 110,
          headerName: "Facilitators",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/deliverypartner/${params.data.Id}/facilitators`}>{params.value}</Link>,
          headerTooltip: "Number of Facilitators assigned to the Delivery Partner",
          type: "numericColumn"
        },
        {
          field: "CountData.CohortCount",
          width: 110,
          headerName: "Cohorts",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/deliverypartner/${params.data.Id}/cohorts`}>{params.value}</Link>,
          headerTooltip: "Number of Cohorts assigned to the Delivery Partner",
          type: "numericColumn"
        },
        {
          field: "CountData.PlayerCount",
          width: 110,
          headerName: "Players",
          cellRenderer: completedPercentRenderer,
          headerTooltip: "Number of Players assigned to the Delivery Partner",
          type: "numericColumn"
        },
        {
          field: "Edit",
          headerName: "Edit",
          cellRenderer: (params: ICellRendererParams) => <Link to={`/edit/deliverypartner/${params.data.Id}`}><img src={editIcon} alt='edit icon' /></Link>,
          headerTooltip: "Edit the Delivery Partner",
          suppressSizeToFit: true,
          sortable: false,
          suppressMenu: true,
          width: 80
        }
      ]}
    />
  </>
});