import auth0 from 'auth0-js';
import jwt_decode from "jwt-decode";
import Environment from './Environment';

interface IAuthData {
  accessToken: string; //access token for the API specified by `audience`
  expiresAt: number; // access token's expiration time
  user: any;
}

let AUTH_CLIENT: any;
let AUTH_DATA: IAuthData = {
  accessToken: '',
  expiresAt: 0,
  user: null
}

export default {
  resetAuthData() {
    AUTH_DATA = {
      accessToken: '',
      expiresAt: 0,
      user: null
    }
  },
  
  isValidToken(): boolean {
    if(Environment.getCurrentEnvironment().isForDebugging) console.log(AUTH_DATA);
    
    return !!AUTH_DATA.accessToken && !!AUTH_DATA.expiresAt && new Date().getTime() < AUTH_DATA.expiresAt;
  },
  
  getAccessToken() {
    return this.isValidToken() ? AUTH_DATA.accessToken : "";
  },
  
  getUser() {
    return this.isValidToken() ? AUTH_DATA.user : null;
  },

  getAuth0UserId() {
    let user = this.getUser();
    return user && user.sub;
  },
  
  logout() {
    this.resetAuthData();
    this.getWebAuthClient().logout({
      returnTo: window.location.origin,
      clientID: Environment.getCurrentEnvironment().auth0ClientId,
    })
  },
  
  login(authResult: any) {
    window.location.hash = '';
    AUTH_DATA.accessToken = authResult.accessToken;
    AUTH_DATA.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    AUTH_DATA.user = jwt_decode(authResult.idToken);
  },
  
  async tryRenewToken() {
    return new Promise((resolve, reject) => {
      this.getWebAuthClient().checkSession({
        // add 3s timeout when renewing token to avoid too long waiting time
        timeout: 3000
      }, (err: any, authResult: any) => {
        if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
          this.login(authResult);
          resolve(authResult);
        } else {
          this.resetAuthData();
          reject(err || authResult);
        }
      });
    });
  },
  
  async handleAuthentication(){
    return new Promise((resolve, reject) => {
      this.getWebAuthClient().parseHash({ hash: window.location.hash }, (err: any, authResult: any) => {
        if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
          this.login(authResult);
          resolve(authResult);
        } else if (err) {
          console.log('Parse auth res failed', err);
          reject(err);             
        } else {
          // No token found
          resolve(null);
        }
      });
    });
  },
  
  async isAuthenticated() {
    if (this.isValidToken()) return true;
    
    try {
      //If there is no valid token, try to renew one
      await this.tryRenewToken();
      return true;
    } catch (err) {
      return false;
    }
  },

  getWebAuthClient() {
    if(!AUTH_CLIENT) {
      const env = Environment.getCurrentEnvironment();
      AUTH_CLIENT = new auth0.WebAuth({
        domain: env.auth0CustomDomain,
        clientID: env.auth0ClientId,
        redirectUri: `${window.location.origin}`,
        responseType: 'token id_token',
        scope: "read:current_user update:current_user_metadata openid profile email",
        audience: `https://${env.auth0Domain}/api/v2/`,
      });
    }
    
    return AUTH_CLIENT;      
  },
  
  loginWithEmailPassword(email: string, password: string, callback: (err: any, res: any) => void) {
    this.getWebAuthClient().redirect.loginWithCredentials({
      realm: 'Username-Password-Authentication',
      username: email,
      password: password
    }, callback);
  },

  registerWithEmailPassword(email: string, password: string, firstName: string, lastName: string, accessCode: string, callback: (err: any, res: any) => void) {
    this.getWebAuthClient().redirect.signupAndLogin({
      connection: 'Username-Password-Authentication',
      email: email,
      password: password,
      user_metadata: {
        firstName,
        lastName,
        accessCode
      }
    }, callback);
  },

  // No longer used, but kept for reference. Use the one in Api.ts
  // resetPassword(email: string, callback: (err: any, res: any) => void) {
  //   this.getWebAuthClient().changePassword({
  //     connection: 'Username-Password-Authentication',
  //     email: email,
  //   }, callback);
  // },
  
  signup(email: string, password: string, cb: (err: any) => void) {
    console.log("signup by username/pw");
    const webAuth = this.getWebAuthClient();
    webAuth.signup({
        connection: 'Username-Password-Authentication',
        email: email,
        password: password,
    }, function(err: any) {
        cb(err)
    });
},
}