import React from "react"
import {
  StyledMaxSessionModalContainer,
  StyledMaxSessionModalHeading,
} from "./MaxSessionsModal.styled";
import { Button } from "../../Button/Button";

interface IMaxSessionModalProps {
  onClose: () => void;
}

export const MaxSessionsModal: React.FC<IMaxSessionModalProps> = ({ onClose }) => {
  return <StyledMaxSessionModalContainer>
    <StyledMaxSessionModalHeading text='Maximum sessions allocated' />
    <p>This Cohort has all Sessions allocated already. Please create a new Cohort to create additional Sessions.</p>
    <Button solid width='100%' label='Ok' onClick={onClose}></Button>
  </StyledMaxSessionModalContainer>
}
