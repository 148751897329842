
import styled, { css } from 'styled-components';

import { IStyledProps } from '../../theme/AotgAdminTheme'

interface IStyledHeadingProps extends IStyledProps {
  primary: boolean
  level: string
}

const getCommonHeadingCss = (props: IStyledHeadingProps) => {
  return props.primary
    ? css`
      margin: 0;
      text-align: center;
      font-family: ${(props.theme.fonts.heading)};
      text-transform: uppercase;
      font-weight: 300;

      ${ /* This is to vertically center uppercase font, fine to override elsewhere, but caution if changing here */'' }
      line-height: 0.85em;
      padding-top: 0.14em;
    `
    : css`
      margin: 0;
      text-align: center;
      font-family: ${(props.theme.fonts.body)};
      font-weight: 400;
      line-height: 1.2em;
    `;
};

export const StyledH1 = styled.h1`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 70px;
      `
      : css`
      
      `;
  }}
`;

export const StyledH2 = styled.h2`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 52px;
        letter-spacing: 0.02em;
      `
      : css`

      `;
  }}
`;

export const StyledH3 = styled.h3`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 42px;
        letter-spacing: 0.04em;
      `
      : css`
        font-size: 28px;
      `;
  }}
`;

export const StyledH4 = styled.h4`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 36px;
      `
      : css`
        font-size: 20px;
        font-weight: 600;
      `;
  }}
`;

export const StyledH5 = styled.h5`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 24px;
        letter-spacing: 0.04em;
      `
      : css`
        font-size: 18px;
      `;
  }}
`;

export const StyledH6 = styled.h6`
  ${(props: IStyledHeadingProps) => { return getCommonHeadingCss(props)} }
  ${(props: IStyledHeadingProps) => {
    return props.primary 
      ? css`
        font-size: 20px;
      `
      : css`
        font-size: 14px;
      `;
  }}
`;