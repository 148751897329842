import { IUser } from '../models/IUser';
import Api from './Api';
import Auth from './Auth';

let UserData: IUser | null;

export default {
  async loadUserData() {
    try {
      UserData = await Api.getUser();
      if(!UserData) {
        throw new Error('User has Auth0 token but has no record in DB, logging out.')
      }
    } catch(err) {
      //Could not match a valid Auth0 account to a user record in our DynamoDB (an Admin,IP,DP,Facilitator).

      //Can be caused by:
      // - Being logged into Auth0 as a Player via the player app in the same browser
      // - Trying to log into an account that has a DB record on test env, but not the prod env (or vice versa)
      if(window.confirm(`Authentication Error. Could not match auth account to a DB user.
        \nPlease ensure your browser does not have any tabs or windows with the 'Ahead of the Game' Player App open.
        \nClick OK to logout and try again.
        \nDetails: ${err}`) === true) {
        Auth.logout();
      }
    }
  },

  //Other methods should also get data using this method to ensure the error is thrown clearly if needed
  getUserData(): IUser {
    if(!UserData) throw new Error('Trying to access UserData before it is set');
    return UserData;
  },

  getUserId(): string {
    return this.getUserData().Id;
  },

  getUserName(): string {
    return this.getUserData().FirstName + ' ' + this.getUserData().LastName;
  },

  getUserRole(): number {
    return this.getUserData().Role;
  }
}