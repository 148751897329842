import React, { useState, useEffect } from 'react';
import Api from '../../../services/Api';
import { IDeliveryPartner } from '../../../models/IUser';
import UserData from '../../../services/UserData';

import { DEFAULT_FACILITATOR_GRID_FILTERS, FacilitatorsGrid } from '../../Grids/FacilitatorsGrid';
import { CohortsGrid, DEFAULT_COHORT_GRID_FILTERS } from '../../Grids/CohortsGrid';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { CreateNewButton } from '../../CreateNewButton/CreateNewButton';
import { makeBreadcrumbs } from '../../../utils/breadcrumbs';
import { PAGE } from '../../../constants';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

interface IDeliveryPartnerViewProps {
  deliveryPartnerId?: string //if unspecified, will try to get self
  initialTabId?: string
}

//Root view FOR delivery partners. For the management of their facilitators, cohorts, players 
export const DeliveryPartnerView: React.FC<IDeliveryPartnerViewProps> = ({ deliveryPartnerId, initialTabId = 'cohorts' }) => {

  const [deliveryPartner, setDeliveryPartner] = useState<IDeliveryPartner | null>(null);

  //If no id supplied, user is getting themself
  if (!deliveryPartnerId) deliveryPartnerId = UserData.getUserId();

  useEffect(() => {
    if (deliveryPartnerId) {
      Api.getDeliveryPartnerById(deliveryPartnerId)
        .then((deliveryPartner: IDeliveryPartner | undefined) => {
          if (!deliveryPartner) throw new Error('Delivery Partner not found');
          setDeliveryPartner(deliveryPartner)
        });
    }
  }, [deliveryPartnerId]);

  const sendBreadCrumbInfo = (deliveryPartner: IDeliveryPartner) => {
    return makeBreadcrumbs(PAGE.DELIVERY_PARTNER, UserData.getUserRole(), deliveryPartner);
  }

  return <div>
    {deliveryPartner
      ? 
      <>
        <Breadcrumbs breadCrumbs={sendBreadCrumbInfo(deliveryPartner)} />
        <GridContainer
          initialTabId={initialTabId}
          extraButtons={[<CreateNewButton appendIdString={`dpOwnerId=${deliveryPartner.Id}`} canAddFacilitator canAddCohort />]}
          gridConfigs={[{
            id: 'cohorts',
            title: 'Cohorts',
            gridElement: <CohortsGrid forDeliveryPartnerId={deliveryPartnerId} />,
            filterConfig: DEFAULT_COHORT_GRID_FILTERS,
            count: deliveryPartner.CountData.CohortCount || 0
          }, {
            id: 'facilitators',
            title: 'Facilitators',
            gridElement: <FacilitatorsGrid forDeliveryPartnerId={deliveryPartnerId} />,
            filterConfig: DEFAULT_FACILITATOR_GRID_FILTERS,
            count: deliveryPartner.CountData.FacilitatorCount || 0
          }]} />
      </>
      : <LoadingSpinner />}
  </div>;
}