import styled from "styled-components";
import { IStyledProps } from "../../theme/AotgAdminTheme";

//These styled components make up the bulk of the admin portal
// (container, container headers, title, buttons, etc.)

export const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: #D9D9D9;
  a { 
    color: ${(props: IStyledProps) => props.theme.colors.black};
  }
`;

export const StyledContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #DEDEDE;
  height: 60px;
`;

export const StyledContainerTitle = styled.div`
  font-size: 30px;
  line-height: 30px;
  color: #6D6D6D;
  font-family: 'Teko';
  text-transform: uppercase;
  padding-left: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledContainerRightItems = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5em;

  > * {
    margin-left: 4px;
  }
`;

const INFO_PADDING_PX = `30px`;

export const StyledInfo = styled.div`
  background-color: ${(props: IStyledProps) => props.theme.colors.white};
  padding: 32px 76px;
  border-bottom: 1px solid #74777C;
  border-top: 1px solid #74777C;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: ${INFO_PADDING_PX};

  a {
    text-decoration: underline;
  }
`;

export const StyledInfoInner = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledButtonContainer = styled.div`
  position: absolute;
  right: 1%;
  display: flex;
  margin-top: 11px;
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${INFO_PADDING_PX};
  min-width: 50%;
  margin:0 15px;
`;

export const StyledAccessCodeContainer = styled.div`
  display: flex;
  gap: 7px; 
  align-items: center; 
  margin-top: -3px;

  b {
    border: 1px solid ${(props: IStyledProps) => props.theme.colors.black}; 
    padding: 3px;
    height: 24px;
    width: 50%; 
    text-align: center; 
  }

  img {
    display: flex;
    width: 14px; 
    height: 16px; 
  }
`;

export const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  & div {
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .audience {
    text-transform: capitalize;
  }

  i {
    color: ${(props: IStyledProps) => props.theme.colors.disabled300};
  }
`;

export const StyledInfoLabel = styled.div`
  min-width: 210px;
`;

export const StyledFormLinkContainer = styled.div`
  margin-top: 30px;
`;