import styled from "styled-components";

const CHECKBOX_SIZE = 18;

export const StyledCheckbox = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  text-wrap: nowrap;

  // Hide the default checkbox
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
`;

export const StyledCheckboxBox = styled.div<{ checked: boolean}>`
  height: ${CHECKBOX_SIZE}px;
  width: ${CHECKBOX_SIZE}px;
  background-color: #eee;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent; 
  border: 2px solid ${(props) => props.theme.colors.black}; 
  border-radius: 2px;

  ${(props) => props.checked && `
    :before {
      content: '';
      height: ${CHECKBOX_SIZE / 2}px; 
      width: ${CHECKBOX_SIZE / 6}px; 
      border-bottom: 2px solid ${props.theme.colors.black}; 
      border-right: 2px solid ${props.theme.colors.black}; 
      transform: rotate(45deg) translate(-1px, -1px)
    }
  `};
`;