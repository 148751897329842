import styled from 'styled-components';
import { Heading } from '../Heading/Heading';
import { StyledApproveDenyButton } from '../Forms/FacilitatorReviewForm/FacilitatorReviewForm.styled';
import { StyledFormButtonContainerColumn, StyledFormButtonContainerRow, StyledFormFooterText } from '../Forms/Form.styled';

export const StyledSpanText = styled(Heading).attrs({ level: 'h4'})`
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
`;

export const StyledTaskButtonContainerColumn = styled.div`
margin-top:5rem;
display: flex;
flex-direction: row;
margin-bottom: 1rem;
gap: 10px;
`;

export const StyledBackButton = styled.img`
  cursor: pointer;
`;