import styled from "styled-components"
import { IStyledProps } from "../../theme/AotgAdminTheme"

export const StyledSearchField = styled.div<IStyledProps>`
    width: 200px;
    padding: 7px 7px;
    background-color #FFFFFFE5;
    display: flex;
    align-items: center;
    border: ${(props: IStyledProps) => `1px solid ${props.theme.colors.black}`};
`

export const StyledSearchInputField = styled.input`
    font-size: 1rem;
    outline: none;
    width: 100%;
    border:none;

    ::placeholder {
        font-size: 0.8rem;
    }

`

export const StyledSearchIcon = styled.img`
    margin-right: 4px;
`