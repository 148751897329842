import React, { useRef, useState } from 'react';
import { StyledDropdownContainer, StyledDropdownOption, StyledDropdownButtonContainer } from './DropdownButton.styled';
import { GridButton } from '../Grids/GridButton/GridButton';
import { IButtonProps } from '../Button/Button';

interface IDropdownOptionProps {
  label: string;
  onClick?: () => void;
}

interface IDropdownButtonProps extends IButtonProps {
  options?: IDropdownOptionProps[];
  dropdownContent?: React.ReactNode;
}

export const DropdownButton: React.FC<IDropdownButtonProps> = (props) => {
  const { label, className, icon, onClick, disabled, options, dropdownContent } = props;
  const [isMenuShown, setIsMenuShown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
 
  const hideMenuOnClickOutside = (e: any) => {
    if(containerRef.current && !containerRef.current.contains(e.target)) {
      changeMenuShown(false);
    }
  }
  
  const changeMenuShown = (newIsShown: boolean) => {
    if(newIsShown) {
      //Make a listener on the whole window to close the menu if the user clicks something outside the filterMenuRef element
      window.addEventListener('click', hideMenuOnClickOutside)
    } else {
      window.removeEventListener('click', hideMenuOnClickOutside)
    }

    setIsMenuShown(newIsShown);
  };

  const onDropdownOptionClick = (option: IDropdownOptionProps) => {
    if (option.onClick) option.onClick();
  }

  return (
    <StyledDropdownButtonContainer ref={containerRef}>
      <GridButton
        {...props}
        icon={icon}
        disabled={disabled}
        onClick={() => changeMenuShown(!isMenuShown)}
        className={className}
        label={label}
      />
      {isMenuShown && <StyledDropdownContainer>
        { /* Show any dropdownContent supplied */}
        {dropdownContent}
        { /* If options were provided, show them*/}
        {options && options.map(option => 
          <StyledDropdownOption onClick={() => onDropdownOptionClick(option)}>
            {option.label}
          </StyledDropdownOption>)
        }
        </StyledDropdownContainer>
      }
    </StyledDropdownButtonContainer>
  );
}

