import styled, { css } from 'styled-components';
import { StyledFormContainer } from '../../Forms/Form.styled';
import { Heading } from '../../Heading/Heading';
import { Button } from '../../Button/Button';

export const StyledMaxSessionModalContainer = styled(StyledFormContainer)`
  min-width: 450px;
  line-height: 140%;
`;

export const StyledMaxSessionModalHeading = styled(Heading).attrs({ level: 'h4', primary: true })`
  text-align: left;
  opacity: 0.5;
  margin-bottom: 2rem;
`;