import React, { useState } from 'react';
import Api from '../../services/Api';
import exportIcon from "../../images/export.svg"; 
import { GridButton } from '../Grids/GridButton/GridButton';

export const ExportButton: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const onDownload = async () => {
        const email = prompt("Because exports can take awhile, we have to send it to a email address, please enter your prefered email address: ");

        if (!email || !validateEmail(email)) {
            alert("Please enter a valid email address!");
            return;
        }

        if (isLoading) return;
        setIsLoading(true);
        try {
            await Api.getUserExport(email);

            alert("We have successfully queued your user export request!");
        } catch (err) {
            alert("A problem occured trying to create your request, please double check your email and try again.")
        } finally {
            setIsLoading(false);
        }
    }
    
    const validateEmail = (email: string) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    return <GridButton
      icon={exportIcon}
      onClick={onDownload}
      disabled={isLoading}
      label={isLoading ? 'Loading...' : "Export All Data"}/>;
}

