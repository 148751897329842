import { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { SESSION_TYPE, USER_ROLE } from '../../../constants';
import { Heading } from '../../Heading/Heading';
import { StyledAddButton, StyledAddContainer, StyledHint, StyledRemove, StyledContent } from '../CohortEditForm/CohortEditForm.styled';
import { StyledDisabledField, StyledFormLabel, StyledErrorMessage, StyledFormButtonContainerColumn, StyledFormContainer, StyledFormButtonContainerRow, StyledFormWrapper } from '../Form.styled';
import { GenerateDot, GenericReactHookForm, Input, LabeledSelect } from '../ReactHookForm';
import add from './../../../images/add.png';
import ReactSwitch from 'react-switch';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { getQueryParam } from '../../../utils/helper';
import Api from '../../../services/Api';
import React from 'react';
import UserData from '../../../services/UserData';
import { StyledSwitchContainer } from './SessionEditForm.styled';
import { ICohort } from '../../../models/ICohort';
import { Modal } from '../../Modal/Modal';
import {
  StyledDescription,
  StyledHeading,
  StyledModalWrapper
} from './PastDateModal.styled';
import { SessionArchiveForm } from '../ArchiveForms/SessionArchiveForm';
import { FACILITATOR_REVIEW_RESULT } from '../../../models/IUser';
import { Button } from '../../Button/Button';

interface sessionData {
  type: string,
  facilitatorId: string,
  deliveryDate: string,
  isTeamFeedbackEnabled?: boolean,
  teams?: string,
  headCount?: number,
}

interface IFormProps {
  sessionId?: string;
  cohortId: string;
}

interface IFacilitatorOptions {
  key: string,
  value: string,
  label: string,
}

export const SessionEditForm: React.FC<IFormProps> = ({ sessionId, cohortId }) => {

  let defaultValues: sessionData = {
    type: '',
    facilitatorId: '',
    deliveryDate: '',
  }

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInitialised] = useState<boolean>(false);
  const [showNextPage, setShowNextPage] = useState<boolean>(false);
  const [isTheChallenge, setIsTheChallenge] = useState<boolean>(false);
  const [formData, setFormData] = useState<sessionData>(defaultValues);
  const [teamName, setTeamName] = useState<string>('');
  const [teamsArray, setTeamsArray] = useState<string[]>([]);
  const [teamNameError, setTeamNameError] = useState<boolean>(false);
  const [isTeamFeatureEnabled, setIsTeamFeatureEnabled] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const Switch = ReactSwitch as any;
  const suppliedDeliveryPartnerOwnerId = getQueryParam('dpOwnerId');
  const suppliedImplementationPartnerOwnerId = getQueryParam('ipOwnerId');

  const [showPastDateModal, setShowPastDateModal] = useState<boolean>(false);

  const [archiveModal, setArchiveModal] = useState<any>();

  const [facilitatorOptions, setFacilitatorOptions] = useState<IFacilitatorOptions[]>([]);

  const [getCohort, setGetCohort] = useState<ICohort>();

  const currentDate = new Date().toLocaleDateString("en-AU").split('/').reverse().map(v => `${v}`.length === 1 ? `0${v}` : v).join('-');

  const onSubmit = (data: sessionData) => {
    setErrorMessage('');

    if (data.deliveryDate < currentDate) {
      setShowPastDateModal(true);
      setFormData(data);
      return;
    }

    if (isTheChallenge) {
      setFormData(data);
      setShowNextPage(true);
    } else {
      onSubmitAll(data);
    }

  }

  const onSubmitAll = (data: sessionData) => {
    setErrorMessage('');
    setIsLoading(true);
    if (isTheChallenge) {
      data = { ...formData };
      data.isTeamFeedbackEnabled = isTeamFeatureEnabled;
      data.teams = teamsArray.join();
    }
    Api.createOrUpdateSession({ ...data, cohortId: cohortId, sessionId: sessionId ?? '' })
      .then((res) => {
        setIsLoading(false);
        history.push(`/cohort/${cohortId}/session/${res.Id ?? sessionId}`);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err.message);
        setErrorMessage(err.message);
      });
  }

  useLayoutEffect(() => {
    const getCohortAndFacilitatorData = async () => {
      let cohort: ICohort | undefined;

      //If the user is a facilitator, they can only select themself (they cannot assign to another facilitator)
      if (UserData.getUserRole() === USER_ROLE.Facilitator) {
        const self = { key: UserData.getUserId(), value: UserData.getUserId(), label: UserData.getUserName() };
        setFacilitatorOptions([self]);
      } else {
        let dpId, iPid;
        cohort = await Api.getCohortById(cohortId);
        if (cohort) {
          setGetCohort(cohort);
          dpId = cohort.DeliveryPartnerId || suppliedDeliveryPartnerOwnerId;
          iPid = cohort.PartnerId || suppliedImplementationPartnerOwnerId
        } else {
          throw Error('no cohort found');
        }

        // Don't show archived facilitators when creating/editing a session
        const facilitators = (await Api.getFacilitators(iPid, dpId));
        if (facilitators.length > 0) {
          const facilitatorOptions = facilitators
            .filter(facilitator => {
              if (facilitator.Status && facilitator.Status !== 'archived') return false;
              if (facilitator.Facilitator_ReviewResult !== FACILITATOR_REVIEW_RESULT.Approved) return false;
              return true
            })
            .map(facilitator => {
              return { key: facilitator.Id, value: facilitator.Id, label: facilitator.FirstName + ' ' + facilitator.LastName }
            });
          setFacilitatorOptions(facilitatorOptions);
        } else {
          throw Error('no facilitator found');
        }
      }

      if (sessionId) {
        //We check if the cohort already exists because we might have already got it in during the facilitator fetch above
        if (!cohort) {
          cohort = await Api.getCohortById(cohortId);
          setGetCohort(cohort);
        }

        if (!cohort) throw Error('cohort not found');
        const chosenSession = Object.keys(cohort.Sessions).find((each) => {
          return cohort!.Sessions[each as SESSION_TYPE]?.Id === sessionId
        });
        const session = cohort.Sessions[chosenSession as SESSION_TYPE];
        if (session) {
          let existingSession: sessionData = {
            type: chosenSession as SESSION_TYPE,
            facilitatorId: session.FacilitatorId,
            deliveryDate: session.DeliveryDate,
            headCount: session.HeadCount,
          }
          if (chosenSession === SESSION_TYPE.TheChallenge) {

            setIsTheChallenge(true);
            if (session.Teams) {
              setTeamsArray(session.Teams?.split(','));
            }
            setIsTeamFeatureEnabled(session.IsTeamFeedbackEnabled || false);
          }
          setFormData(existingSession);
        } else {
          throw Error('no session found');
        }
      }
      return true
    }
    getCohortAndFacilitatorData().then(() => {
      setIsInitialised(true)
    }).catch((err) => {
      setIsInitialised(true)
      alert(err);
    })
  }, [])

  //Validation config
  // const today = new Date().toLocaleDateString("en-AU").split('/').reverse().map(v => `${v}`.length == 1 ? `0${v}` : v).join('-');
  const facilitatorMessage = { required: 'Please select a Facilitator' }
  const deliveryDate = { required: 'Please select a Delivery Date' }
  const typeMessage = { required: 'Please select a Type' }
  const requiredValidation = { required: true }
  const dateValidateion = { required: true /* min: today */ }
  const headCountValidateion = { min: 0 }
  const headCountMessage = { min: 'HeadCount can not be nagative' }

  const typeOptions = [
    { key: 'TheChallenge', value: SESSION_TYPE.TheChallenge, label: 'The Challenge' },
    { key: 'HelpOutAMate ', value: SESSION_TYPE.HelpOutAMate, label: 'Literacy Session' },
  ]

  const updateProgressIndicator = (event: any) => {
    if (event.target.value === SESSION_TYPE.TheChallenge) {
      setIsTheChallenge(true)
    } else {
      setIsTheChallenge(false);
    }
  }

  const renderArchiveModal = (sessionId: string) => {
    const hideArchiveModal = () => setArchiveModal(null);
    setArchiveModal(<Modal>
      <SessionArchiveForm cohortId={cohortId} sessionId={sessionId} onCancel={hideArchiveModal} onArchive={() => {
        hideArchiveModal();
        history.push(`/cohort/${cohortId}`);
      }} />
    </Modal>)
  }

  const removeLinkUrl = (index: number) => {
    const updateLinksArray = [...teamsArray];
    updateLinksArray.splice(index, 1);
    setTeamsArray(updateLinksArray);
  }

  const disableSessionType = () => {
    if (getCohort) {
      const existingChallenge = getCohort.Sessions.Resilience; //challenge
      const existingHoam = getCohort.Sessions.Literacy; //help out a mate

      const disabledTypeOption = [
        { key: 'TheChallenge', value: SESSION_TYPE.TheChallenge, label: 'The Challenge', isDisabled: existingChallenge ? true : false },
        { key: 'HelpOutAMate ', value: SESSION_TYPE.HelpOutAMate, label: 'Literacy Session', isDisabled: existingHoam ? true : false },
      ]
      return disabledTypeOption;
    }
    return typeOptions;
  }
  const displayPastDateModal = () => {
    return <StyledFormContainer>
      <StyledModalWrapper>
        <StyledHeading text='Date selected occurs in the past' />
        <StyledDescription>The date you have selected for your session occurs in the past.<br /><br />Are you sure you want to proceed?</StyledDescription>
        <StyledFormButtonContainerRow>
          <Button width="100%" onClick={() => setShowPastDateModal(false)} label="Cancel" />
          <Button width="100%" solid type="submit" label={'Ok'} onClick={() => {
            if (isTheChallenge) {
              setShowNextPage(true);
            } else {
              onSubmitAll(formData);
            }
            setShowPastDateModal(false);
          }} />
        </StyledFormButtonContainerRow>
      </StyledModalWrapper>
    </StyledFormContainer>
  }

  return (<StyledFormWrapper>
    {!isInital ?
      <LoadingSpinner />
      :
      <StyledFormContainer>
        <Heading level="h3" primary text={sessionId ? "EDIT SESSION" : "ADD SESSION"} />

        <GenericReactHookForm<sessionData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading} style={!showNextPage ? {} : { display: 'none' }}>
          {!sessionId &&
            <>
              <StyledFormLabel>Type</StyledFormLabel>
              <LabeledSelect name='type' placeholder={'Select'} type='text' options={disableSessionType()} validate={requiredValidation} validatemessage={typeMessage} onChange={(event: any) => updateProgressIndicator(event)} />
            </>
          }
          <StyledFormLabel>Facilitator</StyledFormLabel>
          <LabeledSelect name='facilitatorId' placeholder={'Select'} type='text' options={facilitatorOptions} validate={requiredValidation} validatemessage={facilitatorMessage} />
          <StyledFormLabel>Delivery Date</StyledFormLabel>
          <Input name='deliveryDate' type='date' validate={dateValidateion} validatemessage={deliveryDate} />
          {
            sessionId &&
            <>
              <StyledFormLabel>Headcount</StyledFormLabel>
              <Input name='headCount' type='number' min={0} validate={headCountValidateion} validatemessage={headCountMessage} />
            </>
          }
          {
            sessionId &&
            <>
              <StyledFormLabel>Type</StyledFormLabel>
              <StyledDisabledField>{typeOptions.find((each) => {
                return each.value === formData.type
              })?.label}</StyledDisabledField>
            </>
          }
          {sessionId ?
            <StyledFormButtonContainerColumn>
              <Button width="100%" solid type="submit" label={isTheChallenge ? "Next" : "Save"} />
              <StyledFormButtonContainerRow>
                <Button width="100%" onClick={(e) => {
                  e.preventDefault(); //form will try to submit without this
                  history.goBack();
                }} label="Cancel" />
                <Button width="100%" type="button" label='Archive' onClick={() => renderArchiveModal(sessionId)} />
              </StyledFormButtonContainerRow>
            </StyledFormButtonContainerColumn>
            :
            <StyledFormButtonContainerRow>
              <Button width="100%" onClick={(e) => {
                e.preventDefault(); //form will try to submit without this
                history.goBack();
              }} label="Cancel" />
              <Button width="100%" solid type="submit" label={isTheChallenge ? "Next" : "Create"} />
            </StyledFormButtonContainerRow>
          }
        </GenericReactHookForm>

        <GenericReactHookForm<sessionData> defaultValues={formData} onSubmit={onSubmitAll} isLoading={isLoading} style={showNextPage ? {} : { display: 'none' }}>
          <StyledFormLabel style={{ color: '#090909', fontWeight: '700' }}>Team Feature:</StyledFormLabel>
          <StyledSwitchContainer >
            <Switch checked={isTeamFeatureEnabled} uncheckedIcon={false} checkedIcon={false} onColor={'#56cc3f'} onChange={(newValue: boolean) => { setIsTeamFeatureEnabled(newValue) }} />
            <div>Team Feedback Feature</div>
          </StyledSwitchContainer>
          <StyledHint>If this feature is turned on, players within this Cohort will be able to provide feedback to each other through the player app</StyledHint>
          {isTeamFeatureEnabled &&
            <>
              <StyledFormLabel>Team Names</StyledFormLabel>
              <StyledAddContainer>
                <input key='TeamName' value={teamName} className={teamNameError ? 'hasError' : ''} placeholder={'Team 1'} type='text' onChange={(event) => setTeamName(event.target.value)} />
                <StyledAddButton onClick={() => {
                  if (teamName === '') {
                    setTeamNameError(true);
                  } else {
                    setTeamNameError(false);
                    setTeamName('');
                    const updatedLinksArray = [...teamsArray];
                    const hasExistingLink = updatedLinksArray.filter(each => {
                      return each === teamName
                    });

                    if (hasExistingLink.length <= 0) {
                      setTeamsArray([...teamsArray, teamName])
                    }
                  }
                }}>
                  <img alt='add' src={add} />
                </StyledAddButton>
              </StyledAddContainer>
              <StyledHint>If only one team is attending leave this area blank</StyledHint>
              {teamNameError && <StyledErrorMessage>Team can't be empty</StyledErrorMessage>}
              {teamsArray.length > 0 &&
                <StyledContent>
                  {teamsArray.map((each, index) =>
                    <li key={index}><span style={{ display: 'flex' }}>{each} <StyledRemove onClick={() => removeLinkUrl(index)}> [Remove]</StyledRemove></span></li>
                  )
                  }
                </StyledContent>
              }
            </>
          }
          {sessionId ?
            <StyledFormButtonContainerColumn>
              <StyledFormButtonContainerRow>
                <Button width="100%" type="button" onClick={() => setShowNextPage(false)} label="Previous" />
                <Button width="100%" type="button" label='Archive' onClick={() => renderArchiveModal(sessionId)} />
              </StyledFormButtonContainerRow>
              <Button width="100%" solid type="submit" label={sessionId ? "Save" : "Create"} />
            </StyledFormButtonContainerColumn>
            :
            <StyledFormButtonContainerRow>
              <Button width="100%" type="button" onClick={() => setShowNextPage(false)} label="Previous" />
              <Button width="100%" solid type="submit" label={sessionId ? "Save" : "Create"} />
            </StyledFormButtonContainerRow>
          }
        </GenericReactHookForm>
        <StyledErrorMessage>
          {errorMessage}
        </StyledErrorMessage>
        {isTheChallenge && <GenerateDot max={2} current={showNextPage ? 1 : 0} />}
        {showPastDateModal && <Modal onCancel={() => setShowPastDateModal(false)}>
          {displayPastDateModal()}
        </Modal>}
        {archiveModal}
      </StyledFormContainer>
    }
  </StyledFormWrapper>
  );
}