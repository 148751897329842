import React, { useState, useEffect, forwardRef } from "react";
import Api from '../../services/Api';
import editIcon from '../../images/edit.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ICellRendererParams } from 'ag-grid-community';
import { GridFilterConfig, completedPercentRenderer, userStatusRenderer } from "./GridContainer/GridContainer";
import { IS_FACILITATOR_REVIEW_DISABLED, LOCAL_STORAGE, USER_STATUS } from "../../constants";
import { ResendEmailModal } from "../ResendEmailModal/ResendEmailModal";
import { getUserStatus, canUserApproveFacilitator, sortRowsByIdFn, isoDateToLocalDate, sortByFunctions } from "../../utils/helper";
import { IFacilitator } from "../../models/IUser";
import { QuickModal } from "../Modal/Modal";
import { AgGridReact } from "ag-grid-react";

export const DEFAULT_FACILITATOR_GRID_FILTERS: GridFilterConfig = {
  'status-active': {
    label: 'Active',
    isOn: true,
    filterFn: (data: IFacilitator) => getUserStatus(data) === USER_STATUS.Active
  },
  'status-pending': {
    label: 'Pending',
    isOn: true,
    filterFn: (data: IFacilitator) => getUserStatus(data) === USER_STATUS.PendingReview
  },
  'status-email-sent': {
    label: 'Email Sent',
    isOn: true,
    filterFn: (data: IFacilitator) => getUserStatus(data) === USER_STATUS.EmailSent
  },
  'status-archived': {
    label: 'Archived',
    isOn: false,
    filterFn: (data: IFacilitator) => getUserStatus(data) === USER_STATUS.Archived
  }
};


interface IFacilitatorsGridProps {
  forPartnerId?: string,
  forDeliveryPartnerId?: string
}

enum TooltipsContent {
  Edit = 'Edit the details of a Facilitator',
}


//For the MANAGEMENT of facilitators
export const FacilitatorsGrid: React.FC<IFacilitatorsGridProps> = forwardRef((props, agGridRef: any) => {
  const { forPartnerId, forDeliveryPartnerId } = props;

  const [rowData, setRowData] = useState<IFacilitator[]>();
  
  const [emailForResend, setEmailForResend] = useState<string>(""); //null/'' = no modal being shown
  const [showReviewModalForFacilitator, setShowReviewModalForFacilitator] = useState<IFacilitator>(); //null = no modal being shown
  
  const history = useHistory();

  useEffect(() => {
    Api.getFacilitators(forPartnerId, forDeliveryPartnerId)
      .then((facilitators: IFacilitator[]) => {
        const sortedFacilitators = facilitators.sort((a, b) => sortByFunctions(a, b, [sortRowsByIdFn]));
        setRowData(sortedFacilitators);
      });
  }, [forPartnerId, forDeliveryPartnerId]);

  const getFacilitatorReviewModal = (facilitator: IFacilitator) => {
    // we should only be here if the facilitator has been reviewed
    // this needs to have an approve/deny
    const modalButtons = canUserApproveFacilitator() ? [
      {
        solid: false,
        label: "Cancel",
        width: "50%",
        onClick: () => setShowReviewModalForFacilitator(undefined),
      }, {
        solid: true,
        label: "Continue",
        width: "50%",
        onClick: () => {
          // route to review
          setShowReviewModalForFacilitator(undefined);
          history.push(`/task/${facilitator.Id}`);
        },
      },
    ] : [{
      solid: true, 
      label: 'Ok', 
      width: '100%', 
      onClick: () => setShowReviewModalForFacilitator(undefined)
    }];

    return (
      <QuickModal
        innerHeader="Facilitator is awaiting approval"
        innerText={
          <>
            <p>
              Facilitator is currently awaiting approval so you are unable to
              edit their details.
            </p>
            {canUserApproveFacilitator() && <p>
              Do you want to continue to approve or archive this
              facilitator?
            </p>}
          </>
        }
        buttons={modalButtons}
      />
    );
  }

  return <>
  {emailForResend && <ResendEmailModal email={emailForResend} onClose={() => setEmailForResend('')}/>}
  {showReviewModalForFacilitator && getFacilitatorReviewModal(showReviewModalForFacilitator)}
  <AgGridReact 
    {...props}
    ref={agGridRef}
    rowData={rowData} 
    tooltipShowDelay={0}
    columnDefs={[
      {
        field: "Id",
        hide: true
      },
      {
        field: "FirstName",
        headerName: "Full Name",
        cellRenderer: (params: ICellRendererParams) => {
          const facilitator = params.data;
          const fullName = facilitator.FirstName + ' ' + facilitator.LastName;
          // If IS_FACILITATOR_REVIEW_DISABLED = true, everyone is considered approved 
          const isApproved = IS_FACILITATOR_REVIEW_DISABLED || getUserStatus(facilitator) !== USER_STATUS.PendingReview;
  
          return <Link 
            to={isApproved ? `/facilitator/${facilitator.Id}`: ''} 
            onClick={(e) => {
              if (!isApproved) {
                e.preventDefault();
                setShowReviewModalForFacilitator(facilitator);
              }
            }
          }>{fullName}</Link>
        },
        headerTooltip: "Name of the Facilitator",
        flex: 1,
        suppressSizeToFit: true,
        getQuickFilterText: (params) => {
          const facilitator = params.data;
          return facilitator.FirstName + ' ' + facilitator.LastName;
      }
      },
      {
        field: 'Registration Status',
        headerName: 'Status',
        cellRenderer: (params: ICellRendererParams) => userStatusRenderer(params.data, () => setEmailForResend(params.data.Email)),
        headerTooltip: 'Status of the Email Invitation to Facilitator',
        suppressSizeToFit: true,
        minWidth: 170
      },
      {
        field: "LastLoginAt",
        headerName: "Last Login",
        cellRenderer: (params: ICellRendererParams) => {
          const facilitator = params.data;
          return facilitator.LastLoginAt ? isoDateToLocalDate(facilitator.LastLoginAt) : 'N/A'
        },
        headerTooltip: "Date of last login of the Facilitator",
        maxWidth: 140,
        suppressSizeToFit: true,
        hide: !localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled)
      },
      {
        field: "CountData.CohortCount",
        headerName: "Cohorts",
        cellRenderer: (params: ICellRendererParams) => <Link to={`/facilitator/${params.data.Id}/cohorts`}>{params.value}</Link>,
        headerTooltip: "Number of Cohorts assigned to the Facilitator",
        type: "numericColumn"
      },
      {
        field: "CountData.PlayerCount",
        headerName: "Players",
        headerTooltip: "Number of Players assigned to the Facilitator",
        type: "numericColumn"
      },
      {
        field: "CountData.PlayerCompletedCount",
        headerName: "Completed",
        cellRenderer: completedPercentRenderer,
        headerTooltip: "Percentage of Players assigned to Facilitator that have completed the Challenge",
        type: "numericColumn"
      },
      {
        field: "Edit",
        headerName: "Edit",
        cellRenderer: (params: ICellRendererParams) => {
          const facilitator = params.data;
          // If IS_FACILITATOR_REVIEW_DISABLED = true, everyone is considered approved 
          const isApproved = IS_FACILITATOR_REVIEW_DISABLED || getUserStatus(facilitator) !== USER_STATUS.PendingReview;
  
          // check if we're able to edit
          return <Link 
            to={isApproved ? `/edit/facilitator/${facilitator.Id}?ip=${forPartnerId}`: ''}
            onClick={(e) => {
              if (!isApproved) {
                e.preventDefault();
                setShowReviewModalForFacilitator(facilitator);
              }
            }}
          ><img src={editIcon} alt='edit icon' /></Link>
        },
        headerTooltip: "Edit the Facilitator",
        sortable: false,
        suppressMenu: true,
        resizable: false,
        width: 80
      }
    ]} />
  </>
});