import React from 'react';

interface ITeamsDisplayProps {
  teams?: string
}

export const TeamsDisplay: React.FC<ITeamsDisplayProps> = ({ teams }) => {
  return (<>
    {teams ?
      <span>{teams.split(',').map((team: string) => team.trim()).join(', ')}</span>
      :
      <span>All players will be treated as a single team.</span>}
  </>);
}

