import styled, { css } from "styled-components";

export const StyledTaskNavigation = styled.div`
    position: absolute;
    right: 30px;
    top: 0;
    height: 42px;
    line-height: 42px;
    width: 120px;
    background-color: white;
    color: black;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    cursor: pointer;
`;

export const StyledTaskNumber = styled.div`
    color: black;
    border-radius: 10px;
    height: 20px;
    line-height: 20px;    
    text-align: center;
    min-width: 20px;
    padding:1.5px 5px 0 5px;
    ${(props: { tasks?: any[] }) => {
      if(props.tasks === undefined)  {
        return css`background-color: #DEDEDE;`
      } else if(props.tasks.length > 0) {
        return css`background-color: #EA7878;`
      } else { 
        return css`background-color: #A2ECC8;`
      }
    }}
`;