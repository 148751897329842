import React, { useLayoutEffect, useState } from 'react';
import { StyledDisabledField, StyledFormLabel, StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormFooterText, StyledFormWrapper } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm, Input } from '../ReactHookForm';
import { useHistory } from 'react-router';
import { IDeliveryPartner } from '../../../models/IUser';
import Api from '../../../services/Api';
import { USER_ROLE, USER_ROLE_NAMES } from '../../../constants';
import { ResendEmailModal } from '../../ResendEmailModal/ResendEmailModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { getQueryParam } from '../../../utils/helper';
import { Button } from '../../Button/Button';
interface IUserFormProps {
  deliveryPartnerId?: string
}

interface userFormData {
  firstName: string;
  lastName: string;
  email: string;
  partner: string;
  DeliveryPartner_PartnerId: string;
  country?: string;
  sport?: string;
}

export const DeliveryPartnerEditForm: React.FC<IUserFormProps> = ({ deliveryPartnerId }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInital] = useState<boolean>(false);
  const suppliedImplementationPartnerOwnerId = getQueryParam('ipOwnerId');
  const isCreateNew = !deliveryPartnerId;

  let userFormData: userFormData = {
    firstName: '',
    lastName: '(Organisation)',
    email: '',
    partner: '',
    DeliveryPartner_PartnerId: '',
    country: '',
    sport: ''
  }

  const [formData, setFormData] = useState<userFormData>(userFormData);

  useLayoutEffect(() => {
    if (!isCreateNew) {
      Api.getDeliveryPartnerById(deliveryPartnerId)
        .then(async (user: IDeliveryPartner | undefined) => {
          if (!user) return alert('No user found');
          else {
            let partnerOwnerId = user.DeliveryPartner_PartnerId;
            const partner = await Api.getPartnerById(partnerOwnerId);
            if (!partner) throw new Error('Invalid partner ID');
            setFormData({
              firstName: user.FirstName,
              lastName: user.LastName,
              email: user.Email,
              partner: partner.FirstName,
              DeliveryPartner_PartnerId: user.DeliveryPartner_PartnerId
            })
          }
          setIsInital(true);
        })
        .catch(err => {
          console.log(err);
          setIsInital(true);
          alert("Retrieve user failed");
        });
    } else {
      setIsInital(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPartnerId])

  useLayoutEffect(() => {
    Api.getPartnerById(suppliedImplementationPartnerOwnerId).then((partner) => {
      if (partner?.Partner_Country && partner?.Partner_Sport) {
        setFormData({
          ...formData,
          country: partner.Partner_Country,
          sport: partner.Partner_Sport
        })
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliedImplementationPartnerOwnerId])

  const saveUser = (data?: userFormData) => {
    setIsLoading(true);
    const updateFormData = data ? data : formData;
    if (!updateFormData) return;
    const userBundle = { userId: deliveryPartnerId, ...updateFormData }
    Api.createOrUpdateUser(USER_ROLE.DeliveryPartner, userBundle)
      .then((res) => {
        setIsLoading(false);
        history.push(`/deliverypartner/${res.Id ?? deliveryPartnerId}`);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err.message);
        setErrorMessage(err.message);
      })
  }

  const onSubmit = (data: userFormData) => {
    setErrorMessage('');
    if (suppliedImplementationPartnerOwnerId) data.DeliveryPartner_PartnerId = suppliedImplementationPartnerOwnerId;
    setFormData({
      ...data,
      country: formData.country,
      sport: formData.sport,
    });
    
    saveUser(data);
  }

  const emailValidation = { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true };
  const requiredValidation = { required: true }
  const validateMessage = { required: 'Please enter a valid Email Address', pattern: 'Entered value does not match email format' }
  const firstNameMessage = { required: 'Please enter an Organisation Name' }


  return <StyledFormWrapper>
    {!isInital ?
      <LoadingSpinner />
      :
      <StyledFormContainer>
        <Heading level='h4' primary>{isCreateNew ? 'ADD' : 'EDIT'} {USER_ROLE_NAMES[USER_ROLE.DeliveryPartner].toUpperCase()}</Heading>
        <GenericReactHookForm<userFormData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading}>
          <StyledFormLabel>Organisation Name</StyledFormLabel>
          <Input name='firstName' placeholder={'Example Organisation'} type='text' validate={requiredValidation} validatemessage={firstNameMessage} />
          {!isCreateNew ? <StyledFormLabel>Email <br /><StyledDisabledField>{formData.email}</StyledDisabledField></StyledFormLabel>
            : <>
              <StyledFormLabel>Email</StyledFormLabel>
              <Input name='email' placeholder={'john@movember.com'} type='text' disabled={!isCreateNew} validate={emailValidation} validatemessage={validateMessage} />
            </>
          }
          {formData.partner && <StyledFormLabel>Implementation Partner <br /><StyledDisabledField>{formData.partner}</StyledDisabledField></StyledFormLabel>}
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
          <StyledFormButtonContainerRow>
            <Button width="100%" onClick={(e) => {
              e.preventDefault(); //form will try to submit without this
              history.goBack();
            }} label="Cancel" />
            <Button width="100%" solid type="submit" label={isCreateNew ? "Create" : "Save"} />
          </StyledFormButtonContainerRow>
          {isCreateNew && <StyledFormFooterText>Clicking 'Create' will send an email invitation to the Delivery Partner</StyledFormFooterText>}
        </GenericReactHookForm>
      </StyledFormContainer>
    }
  </StyledFormWrapper>

};