import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IStyledProps } from '../../../theme/AotgAdminTheme';
import { StyledHeadingContainer } from '../Form.styled';

export const StyledLoginHeading = styled(StyledHeadingContainer)`
  color: ${(props: IStyledProps) => props.theme.colors.white};
`;

export const ForgotLink = styled(Link)`
  text-align:center;
  margin-top:10px;
  bottom: 2px;
  right: 0;
  font-size: 14px;
  opacity: 0.6;
  font-family: 'Overpass';
  text-decoration: underline;
  color: ${(props: IStyledProps) => props.theme.colors.brandThemeDark500};
`
export const StyledInputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  // have used margin-top here instead of properly vertically aligning
  // the entire page as that requires modifying form.styled.ts which will be
  // changed soon when we find a new form package
  margin-top: 5rem;
`;

export const StyledShowPassword = styled.div`
  margin-top:16px;
`;

export const StyledLoginFormPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;