import React, { useEffect, useState } from 'react';
import { DEFAULT_PARTNER_GRID_FILTERS, PartnersGrid } from '../../Grids/PartnersGrid';

import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { CreateNewButton } from '../../CreateNewButton/CreateNewButton';
import { ExportButton } from '../../Export/ExportButton';

//Root view for admins. Holds a list of PARTNERS 
export const AdminView: React.FC = () => {

  return <GridContainer 
    extraButtons={[<ExportButton />, <CreateNewButton canAddPartner />]}
    gridConfigs={[{
      id: 'partners',
      title: 'All Implementation Partners',
      gridElement: <PartnersGrid />,
      filterConfig: DEFAULT_PARTNER_GRID_FILTERS
    }]} />;
}