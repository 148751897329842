import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme/AotgAdminTheme';

interface StyledButtonProps extends IStyledProps {
  icon?: string;
  solid?: boolean;
  disabled?: boolean;
  width?: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  width: ${(props: StyledButtonProps) => props.width || 'initial'};

  border: ${(props: StyledButtonProps) => `1px solid ${props.theme.colors.black}`};
  border-radius: 0px;
  
  &:focus {
    outline: none;
  }

  background-color: ${(props: StyledButtonProps) => props.solid 
    ? props.theme.colors.black
    : 'transparent'};
  color: ${(props: StyledButtonProps) => props.solid 
    ? props.theme.colors.white 
    : props.theme.colors.black};

  ${(props) => props.icon && css`
    :after {
      content: "";
      width: 1rem;
      height: 1rem;
      display: block;
      background-image: url(${props.icon});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 2px;

      ${props.solid && css`filter: invert(100%);`}
    } 
    `
  }

  h5 {
    text-align: center;
    letter-spacing: 0.1em;
    flex: 1;
  }

  ${(props) => props.disabled && css`
    pointer-events: none;
    cursor: default;
    border-color: ${props.theme.colors.disabled300};

    ${props.solid ? css`
      background-color: ${props.theme.colors.disabled300};
    ` : css`
      color: ${props.theme.colors.disabled300};
    `}

    :after {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  `}
`;