import React, { useLayoutEffect, useState } from 'react';
import { StyledDisabledField, StyledFormLabel, StyledErrorMessage, StyledFormButtonContainerColumn, StyledFormContainer, StyledFormButtonContainerRow, StyledFormWrapper } from '../Form.styled';
import { useHistory } from 'react-router';
import Api from '../../../services/Api';
import { ICohort } from '../../../models/ICohort';
import { GenerateDot, GenericReactHookForm, Input, LabeledSelect, Select } from '../ReactHookForm';
import { Heading } from '../../Heading/Heading';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { getQueryParam } from '../../../utils/helper';
import { AUDIENCE_TYPE, DEMO_MODE_ACTIVE } from '../../../constants';
import { debug } from 'console';
import { InfoPopup } from '../../InfoPopup/InfoPopup';
import { CohortArchiveForm } from '../ArchiveForms/CohortArchiveForm';
import { Modal } from '../../Modal/Modal';
import { Button } from '../../Button/Button';
interface ICohortFormProps {
  cohortId?: string
}
interface ICohortFormData {
  title: string;
  postcode: string;
  audience: AUDIENCE_TYPE;
  ageGroup: string;
  gender: string;
  clubName: string;
  clubContactName: string;
  clubContactPhoneNumber: string;
  clubContactEmail: string;
  equipmentAvailable?: string;
  notes?: string;

  isDemo?: boolean;

  partner: string;
  deliveryPartner: string;
  partnerId: string;
  deliveryPartnerId?: string;
}

export const CohortEditForm: React.FC<ICohortFormProps> = ({ cohortId }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInital] = useState<boolean>(false);
  const [showNextPage, setShowNextPage] = useState<boolean>(false);
  const suppliedImplementationPartnerOwnerId = getQueryParam('ipOwnerId');
  const suppliedDeliveryPartnerOwnerId = getQueryParam('dpOwnerId');

  const [archiveModal, setArchiveModal] = useState<any>();

  let cohortFormData: ICohortFormData = {
    title: '',
    postcode: '',
    audience: AUDIENCE_TYPE.null,
    ageGroup: '',
    gender: '',
    clubName: '',
    clubContactName: '',
    clubContactPhoneNumber: '',
    clubContactEmail: '',
    equipmentAvailable: '',
    notes: '',
    partner: '',
    deliveryPartner: '',
    partnerId: '',
    isDemo: false,
  }

  const [formData, setFormData] = useState<ICohortFormData>(cohortFormData);
  const [inputValues, setInputValues] = useState({
    inputValueIsDemo: 'False',
  });

  useLayoutEffect(() => {
    if (cohortId) {
      Api.getCohortById(cohortId)
        .then(async (cohort: ICohort | undefined) => {
          if (!cohort) return alert('No cohort found');
          else {
            let partnerOwnerId = cohort.PartnerId;
            let deliveryPartner;
            const deliveryPartnerOwnerId = cohort.DeliveryPartnerId;
            if (deliveryPartnerOwnerId) {
              deliveryPartner = await Api.getDeliveryPartnerById(deliveryPartnerOwnerId);
              if (!deliveryPartner) throw new Error('Invalid delivery partner ID');
              partnerOwnerId = deliveryPartner.DeliveryPartner_PartnerId
            }
            const partner = await Api.getPartnerById(partnerOwnerId);
            if (!partner) throw new Error('Invalid partner ID');

            setFormData({
              postcode: cohort.Postcode,
              audience: cohort.Audience as AUDIENCE_TYPE,
              ageGroup: cohort.AgeGroup,
              gender: cohort.Gender,
              clubName: cohort.ClubName,
              clubContactName: cohort.ClubContactName,
              clubContactPhoneNumber: cohort.ClubContactPhoneNumber,
              clubContactEmail: cohort.ClubContactEmail,
              equipmentAvailable: cohort.EquipmentAvailable || '',
              notes: cohort.Notes || '',
              partner: partner.FirstName,
              deliveryPartner: deliveryPartner ? deliveryPartner.FirstName : '',
              partnerId: cohort.PartnerId,
              title: ''
            });

            setInputValues({
              inputValueIsDemo: cohort.IsDemo ? 'True' : 'False',
            });
          }
          setIsInital(true);
        })
        .catch(err => {
          console.log(err);
          setIsInital(true);
          alert("Retrieve cohort failed");
        });
    } else {
      setIsInital(true)
    }
  }, [cohortId])

  const saveUser = (data?: ICohortFormData) => {
    setIsLoading(true);
    const updateFormData = data ? data : formData;
    if (!updateFormData) return;
    const userBundle = { cohortId, ...updateFormData }
    Api.createOrUpdateCohort(userBundle)
      .then((res) => {
        setIsLoading(false);
        history.push(`/cohort/${res.Id ?? cohortId}`);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err.message);
        setErrorMessage(err.message);
      })
  }

  const renderArchiveModal = (cohortId: string) => {
    const hideArchiveModal = () => setArchiveModal(null);
    setArchiveModal(<Modal>
      <CohortArchiveForm cohortId={cohortId} onCancel={hideArchiveModal} onArchive={() => {
        hideArchiveModal();
        history.goBack();
      }} />
    </Modal>)
  }

  const onSubmit = (data: ICohortFormData) => {
    setFormData(data);
    setShowNextPage(true);
  }

  const onSubmitAll = (data: ICohortFormData) => {
    setErrorMessage('');
    if (!cohortId) {
      data.partnerId = suppliedImplementationPartnerOwnerId;
      if (suppliedDeliveryPartnerOwnerId) {
        data.deliveryPartnerId = suppliedDeliveryPartnerOwnerId
      }
    }

    data.isDemo = inputValues.inputValueIsDemo === "True";

    data.ageGroup = formData.ageGroup;
    data.audience = formData.audience;
    data.gender = formData.gender;
    data.clubName = formData.clubName;
    data.title = `${data.clubName} ${data.audience} ${data.ageGroup} ${data.gender}`
    saveUser(data);
  }

  const requiredValidation = { required: true };

  const ageGroupMessage = { required: 'Please select a Team Age Group' };
  const genderMessage = { required: 'Please select a Team Gender' };
  const audienceMessage = { required: 'Please select an Audience' };
  const emailValidation = { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true };
  const emailMessage = { required: 'Please enter a valid Email Address', pattern: 'Entered value does not match email format' };
  const postcodeValidation = { pattern: /^[a-zA-Z0-9 ]*$/, required: true };
  const postcodeMessage = { required: 'Please enter a valid Postcode', pattern: 'Entered value does not match the required format' };
  const contactClubNameMessage = { required: 'Please enter a Club/School Name' };
  const contactNameMessage = { required: 'Please enter a Club/School Contact Name' };

  return <StyledFormWrapper>
    {!isInital ?
      <LoadingSpinner />
      :
      <StyledFormContainer>
        <Heading level='h4' primary text={cohortId ? 'EDIT COHORT' : 'ADD COHORT'} />
        <GenericReactHookForm<ICohortFormData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading} style={!showNextPage ? {} : { display: 'none' }}>
          <StyledFormLabel style={{ color: '#090909', fontWeight: '700' }}>Cohort Details</StyledFormLabel>
          <StyledFormLabel>Organisation Name</StyledFormLabel>
          <Input name='clubName' placeholder={'Example Organisation'} type='text' validate={requiredValidation} validatemessage={contactClubNameMessage} onChange={(e: any) => setFormData({ ...formData, clubName: e.target.value })} />
          {!cohortId && <>
            <StyledFormLabel>Audience</StyledFormLabel>
            <LabeledSelect name='audience' placeholder={'Select'} type='text' options={[{ key: 'one', value: AUDIENCE_TYPE.players, label: 'Players' }, { key: 'two', value: AUDIENCE_TYPE.parents, label: 'Parents' }, { key: 'three', value: AUDIENCE_TYPE.coaches, label: 'Coaches' }]} validate={requiredValidation} validatemessage={audienceMessage} onChange={(e: any) => setFormData({ ...formData, audience: e.target.value })} />
          </>
          }
          <StyledFormLabel>Player Age Group</StyledFormLabel>
          <Select name='ageGroup' placeholder={'Select'} type='text' options={[{ key: 'one', value: 'U/18' }, { key: 'two', value: 'U/17' }, { key: 'three', value: 'U/16' }, { key: 'four', value: 'U/15' }, { key: 'five', value: 'U/14' }, { key: 'six', value: 'U/13' }, { key: 'seven', value: 'U/12' }]} validate={requiredValidation} validatemessage={ageGroupMessage} onChange={(e: any) => setFormData({ ...formData, ageGroup: e.target.value })} />
          <StyledFormLabel>Player Gender</StyledFormLabel>
          <Select name='gender' placeholder={'Select'} type='text' options={[{ key: 'one', value: 'Male' }, { key: 'two', value: 'Female' }, { key: 'three', value: 'Mixed' }]} validate={requiredValidation} validatemessage={genderMessage} onChange={(e: any) => setFormData({ ...formData, gender: e.target.value })} />

          {DEMO_MODE_ACTIVE && <>
            <StyledFormLabel>Is in Demo Mode <InfoPopup value={<>Demo mode allows facilitators, delivery partners, and partners to test out the Challenge session. Players will <b>not</b> be able to register or login to the provided Access code.</>}>(What's demo?)</InfoPopup></StyledFormLabel>
            <Select name='isDemo' type='text' options={[{ key: 'one', value: 'False' }, { key: 'two', value: 'True' }]} value={inputValues.inputValueIsDemo} onChange={(e: any) => {
              setInputValues({ ...inputValues, inputValueIsDemo: e.target.value });
            }} />
          </>}

          {(formData.clubName || formData.audience || formData.ageGroup || formData.gender) && <StyledFormLabel>Cohort Name <br />
            <StyledDisabledField>{formData.clubName} {formData.audience} {formData.ageGroup} {formData.gender}</StyledDisabledField>
          </StyledFormLabel>}
          {cohortId && formData.audience && <StyledFormLabel>Audience <br /><StyledDisabledField>{formData.audience}</StyledDisabledField></StyledFormLabel>}
          {cohortId && formData.deliveryPartner && <StyledFormLabel>Delivery Partner <br /><StyledDisabledField>{formData.deliveryPartner}</StyledDisabledField></StyledFormLabel>}
          {cohortId && formData.partner && <StyledFormLabel>Implementation Partner <br /><StyledDisabledField>{formData.partner}</StyledDisabledField></StyledFormLabel>}
          {cohortId ?
            <StyledFormButtonContainerColumn>
              <StyledFormButtonContainerRow>
                <Button width="100%" onClick={(e) => {
                  e.preventDefault(); //form will try to submit without this
                  history.goBack();
                }} label="Cancel" />
                <Button width="100%" label='Archive' type="button" onClick={() => renderArchiveModal(cohortId)} />
              </StyledFormButtonContainerRow>
              <StyledFormButtonContainerRow>
                <Button width="100%" solid type="submit" label="Next" />
              </StyledFormButtonContainerRow>
            </StyledFormButtonContainerColumn>
            :
            <StyledFormButtonContainerRow>
              <Button width="100%" onClick={(e) => {
                e.preventDefault(); //form will try to submit without this
                history.goBack();
              }} label="Cancel" />
              <Button width="100%" solid type="submit" label="Next" />
            </StyledFormButtonContainerRow>
          }

        </GenericReactHookForm>
        <GenericReactHookForm<ICohortFormData> defaultValues={formData} onSubmit={onSubmitAll} isLoading={isLoading} style={showNextPage ? {} : { display: 'none' }}>
          <StyledFormLabel style={{ color: '#090909', fontWeight: '700' }}>Organisation Details</StyledFormLabel>
          <StyledFormLabel>Organisation Contact Name</StyledFormLabel>
          <Input name='clubContactName' placeholder={'John Smith'} type='text' validate={requiredValidation} validatemessage={contactNameMessage} />
          <StyledFormLabel>Organisation Contact Phone Number (Optional)</StyledFormLabel>
          <Input name='clubContactPhoneNumber' placeholder={'0123456789'} type='text' />
          <StyledFormLabel>Organisation Contact Email</StyledFormLabel>
          <Input name='clubContactEmail' placeholder={'john.smith@movember.com'} type='text' validate={emailValidation} validatemessage={emailMessage} />
          <StyledFormLabel>Postcode</StyledFormLabel>
          <Input name='postcode' placeholder={'ABC123'} type='text' validate={postcodeValidation} validatemessage={postcodeMessage} />
          <StyledFormLabel>Organisation Equipment (Optional)</StyledFormLabel>
          <Input name='equipmentAvailable' placeholder={'eg: projecter, power, speakers'} type='text' />
          <StyledFormLabel>Additional Information (Optional)</StyledFormLabel>
          <Input name='notes' placeholder={'eg: room access, timing, address'} type='text' />
          {cohortId ?
            <StyledFormButtonContainerColumn>
              <StyledFormButtonContainerRow>
                <Button width="100%" type="button" onClick={() => setShowNextPage(false)} label="Previous" />
                <Button width="100%" label='Archive' type="button" onClick={() => renderArchiveModal(cohortId)} />
              </StyledFormButtonContainerRow>
              <StyledFormButtonContainerRow>
                <Button width="100%" solid type="submit" label="Save" />
              </StyledFormButtonContainerRow>
            </StyledFormButtonContainerColumn>
            :
            <StyledFormButtonContainerRow>
              <Button width="100%" type="button" onClick={() => setShowNextPage(false)} label="Previous" />
              <Button width="100%" solid type="submit" label="Create" />
            </StyledFormButtonContainerRow>
          }
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
        </GenericReactHookForm>
        <GenerateDot max={2} current={showNextPage ? 1 : 0} />
        {archiveModal}
      </StyledFormContainer>
    }
  </StyledFormWrapper>

};