import styled from 'styled-components';
import { Link } from "react-router-dom";

export interface IStyledHeaderBreadcrumbProps {
  color?: string
  hoverColor?: string
}

export const StyledBreadcrumbs = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  min-height: 16px;
  margin-bottom: 30px;
`;

export const StyledHeaderBreadcrumb = styled(Link)`
  font-size: 36px;
  font-family: 'Teko';
  font-weight: 400;
  text-transform: uppercase;
  color: ${(props: IStyledHeaderBreadcrumbProps) => props.color || '#595959'};
  &:hover {
    color: ${(props: IStyledHeaderBreadcrumbProps) => props.hoverColor || '#173ED7'};
  }
`;

export const StyledPreviousBreadcrumb = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  > * {
    color: #6D6D6D;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }

  > div {
    font-size: 36px;
    line-height: 28px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;    
  }

  & div:hover {
    text-decoration: underline;
    color: #6D6D6D;
  }

  > span {
    font-size:14px;
    line-height:14px;
  }
`

export const StyledCurrentBreadcrumb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  > * {
    color: #FFFFFF;
    font-family: 'Teko';
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }

  > div {
    font-size: 36px;
    line-height: 28px;
  }

  > span {
    font-size:14px;
    line-height:14px;
  }
`