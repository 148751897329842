import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { StyledDescContainer, StyledFormLabel, StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormText, StyledFormWrapper, StyledHeadingContainer } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { useHistory } from 'react-router';
import { StyledLogoContainer } from '../LoginForm/LoginForm.styled';
import { GenericReactHookForm, Input } from '../ReactHookForm';
import { ReactComponent as Logo } from '../../../../src/images/logo.svg';
import Auth from '../../../services/Auth';
import { Button } from '../../Button/Button';
import Api from '../../../services/Api';

interface formData {
  email: string;
}

export const ResetPasswordForm: React.FC = () => {
  const [errorMessage, seterrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formData: formData = {
    email: ''
  }

  const onSubmit = async (data: formData) => {
    setIsLoading(true);
    seterrorMessage('');

    const res = await Api.resetPassword(data.email);
    
    if (res !== true) {
      console.log(res);
      const result = res as Response;
      seterrorMessage(`${result.body as unknown as string}`);
    } else {
      setIsSubmitted(true);
    }

    setIsLoading(false);
  }

  const [isSubmitted, setIsSubmitted] = useState(false);

  const history = useHistory();

  const onBackToLoginClick = (e: any) => {
    e.preventDefault();
    history.push('/login');
  }

  const emailValidation = { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true };
  const validateMessage = { required: 'Please enter a valid email address', pattern: 'Please enter a valid email address' }

  return <>
    <StyledFormWrapper>
      <StyledLogoContainer>
        <Logo />
      </StyledLogoContainer>
      <StyledFormContainer>
        {isSubmitted
          ? <>
            <Heading level="h4" primary text="CHECK YOUR EMAIL" />
            <StyledDescContainer>You can log in here once you have reset <br /> your password.</StyledDescContainer>
            <StyledFormButtonContainerRow>
              <Button width="100%" solid type="submit" onClick={onBackToLoginClick} label="Back to log in" />
            </StyledFormButtonContainerRow>
          </>
          : <>
            <Heading level="h4" primary text="FORGOTTEN PASSWORD" />
            <StyledDescContainer>Confirm your email address and we'll send instructions on how to reset your password:</StyledDescContainer>
            <GenericReactHookForm<formData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading}>
              <StyledFormLabel>Email</StyledFormLabel>
              <Input name='email' placeholder={'john@movember.com'} type='text' validate={emailValidation} validatemessage={validateMessage} />
              <StyledErrorMessage>
                {errorMessage}
              </StyledErrorMessage>
              <StyledFormButtonContainerRow>
                <Button width="100%" type="button" label="Back" onClick={() => history.goBack()} />
                <Button width="100%" solid type="submit" label="Reset" />
              </StyledFormButtonContainerRow>
            </GenericReactHookForm>
          </>
        }
      </StyledFormContainer>
    </StyledFormWrapper>
  </>
};
