import React, { useLayoutEffect, useState } from 'react';
import { StyledDisabledField, StyledFormLabel, StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormFooterText, StyledFormWrapper } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm, Input, LabeledSelect } from '../ReactHookForm';
import { useHistory } from 'react-router';
import { IPartner } from '../../../models/IUser';
import Api from '../../../services/Api';
import { COUNTRY_TYPE, SORTED_SPORT_OPTIONS, SPORT_TYPE, USER_ROLE, USER_ROLE_NAMES } from '../../../constants';
import { ResendEmailModal } from '../../ResendEmailModal/ResendEmailModal';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { Button } from '../../Button/Button';
interface IUserFormProps {
  partnerId?: string
}

interface userFormData {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  sport: string;
  Partner_Seasons_Intervals?: string;
}

export const PartnerEditForm: React.FC<IUserFormProps> = ({ partnerId }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInital] = useState<boolean>(false);
  const isCreateNew = !partnerId;

  let userFormData: userFormData = {
    firstName: '',
    lastName: '(Organisation)',
    email: '',
    sport: '',
    country: '',
    Partner_Seasons_Intervals: "[]"
  }

  const [formData, setFormData] = useState<userFormData>(userFormData);

  useLayoutEffect(() => {
    if (!isCreateNew) {
      Api.getPartnerById(partnerId)
        .then((user: IPartner | undefined) => {
          if (!user) return alert('No user found');
          else {
            setFormData({
              firstName: user.FirstName,
              lastName: user.LastName,
              email: user.Email,
              country: user.Partner_Country,
              sport: user.Partner_Sport,
              Partner_Seasons_Intervals: user.Partner_Seasons_Intervals 
                ? JSON.stringify(user.Partner_Seasons_Intervals) 
                : "[]",
            })
          }
          setIsInital(true);
        })
        .catch(err => {
          console.log(err);
          setIsInital(true);
          alert("Retrieve user failed");
        });
    } else {
      setIsInital(true)
    }
  }, [partnerId])

  const saveUser = (data?: userFormData) => {
    setIsLoading(true);
    const updateFormData = data ? data : formData;
    if (!updateFormData) return;
    const userBundle = { 
      ...updateFormData, 
      userId: partnerId, 
      Partner_Seasons_Intervals: updateFormData.Partner_Seasons_Intervals
        ? JSON.parse(updateFormData.Partner_Seasons_Intervals) 
        : []
    };
    Api.createOrUpdateUser(USER_ROLE.Partner, userBundle)
      .then((res) => {
        setIsLoading(false);
        history.push(`/partner/${res.Id ?? partnerId}`)
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
        console.log(err.message);
        setErrorMessage(err.message);
      })
  }

  const onSubmit = (data: userFormData) => {
    setErrorMessage('');
    setFormData(data);
    saveUser(data);
  }

  const emailValidation = { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true };
  const requiredValidation = { required: true }
  const validateMessage = { required: 'Please enter a valid Email Address', pattern: 'Entered value does not match email format' }
  const firstNameMessage = { required: 'Please enter an Organisation Name' }
  const sportMessage = { required: 'Please select a Sport' };
  const countryMessage = { required: 'Please select a Country' };

  // used to remove the hyphen from sport when displaying it in the edit form
  const displaySport = () => {
    const matchedSport = SORTED_SPORT_OPTIONS.find(sport => sport.value === formData.sport);
    if (matchedSport) return matchedSport.label;
  }

  return <StyledFormWrapper>
    {!isInital ?
      <LoadingSpinner />
      :
      <StyledFormContainer>
        <Heading level='h4' primary>{isCreateNew ? 'ADD' : 'EDIT'} {USER_ROLE_NAMES[USER_ROLE.Partner].toUpperCase()}</Heading>
        <GenericReactHookForm<userFormData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading}>
          <StyledFormLabel>Organisation Name</StyledFormLabel>
          <Input name='firstName' placeholder={'Example Organisation'} type='text' validate={requiredValidation} validatemessage={firstNameMessage} />
          {!isCreateNew ? <StyledFormLabel>Email <br /><StyledDisabledField>{formData.email}</StyledDisabledField></StyledFormLabel>
            : <>
              <StyledFormLabel>Email</StyledFormLabel>
              <Input name='email' placeholder={'john@movember.com'} type='text' disabled={!isCreateNew} validate={emailValidation} validatemessage={validateMessage} />
            </>
          }
          {!isCreateNew ? formData.country && <StyledFormLabel className='countrySport'>Country <br /><StyledDisabledField>{formData.country}</StyledDisabledField></StyledFormLabel>
            : <>
              <StyledFormLabel>Country</StyledFormLabel>
              <LabeledSelect name='country' placeholder={'Select'} type='text' disabled={!isCreateNew} options={[{ key: 'one', value: COUNTRY_TYPE.AU, label: 'Australia' }, { key: 'two', value: COUNTRY_TYPE.GB, label: 'England' }]} validate={requiredValidation} validatemessage={countryMessage} />
            </>
          }
          {!isCreateNew ? formData.sport && <StyledFormLabel className='countrySport'>Sport <br /><StyledDisabledField>{displaySport()}</StyledDisabledField></StyledFormLabel>
            : <>
              <StyledFormLabel>Sport</StyledFormLabel>
              <LabeledSelect name='sport' placeholder={'Select'} type='text' disabled={!isCreateNew} options={[{ key: 'one', value: SPORT_TYPE.AustralianRules, label: 'Australian Rules' }, { key: 'two', value: SPORT_TYPE.Rugby, label: 'Rugby' }]} validate={requiredValidation} validatemessage={sportMessage} />
            </>
          }
          <StyledFormLabel>Partner Session Intervals<br /><sub>([1719380442637, 1735689600000, ...])</sub></StyledFormLabel>
          <Input name='Partner_Seasons_Intervals' placeholder={'[array of DateTimes]'} type='text' />
          <StyledFormButtonContainerRow>
            <Button width="100%" onClick={(e) => {
              e.preventDefault(); //form will try to submit without this
              history.goBack();
            }} label="Cancel" />
            <Button width="100%" solid type="submit" label={isCreateNew ? "Create" : "Save"} />
          </StyledFormButtonContainerRow>
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
          {isCreateNew && <StyledFormFooterText>Clicking 'Create' will send an email invitation to the Implementation Partner</StyledFormFooterText>}
        </GenericReactHookForm>
      </StyledFormContainer>
    }
  </StyledFormWrapper>

};