import { Heading } from '../Heading/Heading';
import { StyledSpanText } from './Task.styled';


export const NoTaskGridOverlayTemplate = () => {
    return <div>
            <video src={'./thumbup.webm'} autoPlay muted />
            <Heading level='h4' primary>Great Work!</Heading>
            
            <StyledSpanText >You are up-to-date on your tasks </StyledSpanText>
        </div>
}