import React from 'react';
import { StyledOverlayBanner, StyledOverlayText } from './Overlay.styled';


const ArchivedOverlay: React.FC = () => {
  return (
    <StyledOverlayBanner>
        <StyledOverlayText>ARCHIVED</StyledOverlayText>
    </StyledOverlayBanner>
  )
  
};

export default ArchivedOverlay;
