import styled, { css } from 'styled-components';
import { IStyledProps } from '../../theme/AotgAdminTheme';

export const StyledTabPanel = styled.div`
`;


interface ITabPanelContentProps {
  isActive: boolean
}

export const StyledTabPanelContent = styled.div<ITabPanelContentProps>`
  position: absolute;
  width: 100%;
  z-index: ${(props) => props.isActive ? '2' : '1'};
`;

export const StyledSuperScript = styled.sup`
  color: #595959;
  font-size: 10px;
  font-weight: bold;
  vertical-align: top;
  line-height: 8px;
`;

interface IStyledTabButtonProps extends IStyledProps {
  isActive?: boolean
}

export const StyledTabButton = styled.button`
  font-family: 'Overpass';
  font-size: 16px;
  padding: 13px 23px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  height: 100%;
  background: ${(props: IStyledTabButtonProps) => props.isActive ? '#FFFFFF' : '#DEDEDE'};
  border-color: ${(props: IStyledTabButtonProps) => props.isActive ? '#FFFFFF' : '#DEDEDE'};
  border-style: solid;
  border-bottom-style: none;
  ${(props: IStyledTabButtonProps) => props.isActive && css`
    & .data {
      color: black;
    }
  `}
  :hover {
    .heading {
      text-decoration: ${(props: IStyledTabButtonProps) => props.isActive ? 'none' : 'underline solid black 2px'};
    }
  }  
`;