import React, { useState, useEffect } from 'react';
import Api from '../../../services/Api';
import { IPartner } from '../../../models/IUser';
import UserData from '../../../services/UserData';

import { DEFAULT_FACILITATOR_GRID_FILTERS, FacilitatorsGrid } from '../../Grids/FacilitatorsGrid';
import { CohortsGrid, DEFAULT_COHORT_GRID_FILTERS } from '../../Grids/CohortsGrid';
import { TabPanel } from '../../TabPanel/TabPanel';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

import { DEFAULT_DELIVERY_PARTNER_GRID_FILTERS, DeliveryPartnersGrid } from '../../Grids/DeliveryPartnersGrid';
import { CreateNewButton } from '../../CreateNewButton/CreateNewButton';
import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { makeBreadcrumbs } from '../../../utils/breadcrumbs';
import { PAGE } from '../../../constants';
import { userInfo } from 'os';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

interface IPartnerViewProps {
  partnerId?: string //if unspecified, will try to get self
  initialTabId?: string
}

//Root view FOR partners. For the management of their facilitators, cohorts, players 
export const PartnerView: React.FC<IPartnerViewProps> = ({ partnerId, initialTabId = 'cohorts' }) => {

  const [implementationPartner, setImplementationPartner] = useState<IPartner | null>(null);

  //If no id supplied, user is getting themself
  if (!partnerId) partnerId = UserData.getUserId();

  useEffect(() => {
    if (partnerId) {
      Api.getPartnerById(partnerId)
        .then((partner: IPartner | undefined) => {
          if (!partner) throw new Error('Partner not found');
          setImplementationPartner(partner)
        });
    }
  }, [partnerId]);

  const sendBreadCrumbInfo = (partner: IPartner) => {
    return makeBreadcrumbs(PAGE.PARTNER, UserData.getUserRole(), partner);
  }

  return <div>
    {implementationPartner
      ? <>
        <Breadcrumbs breadCrumbs={sendBreadCrumbInfo(implementationPartner)} />
          <GridContainer
            initialTabId={initialTabId}
            extraButtons={[<CreateNewButton appendIdString={`ipOwnerId=${implementationPartner.Id}`} canAddDeliveryPartner canAddFacilitator canAddCohort />]}
            gridConfigs={[{
              id: 'cohorts',
              title: 'Cohorts',
              count: implementationPartner.CountData.CohortCount || 0,
              gridElement: <CohortsGrid forPartnerId={partnerId} />,
              filterConfig: DEFAULT_COHORT_GRID_FILTERS
            }, {
              id: 'delivery-partners',
              title: 'Delivery Partners',
              count: implementationPartner.CountData.DeliveryPartnerCount || 0,
              gridElement: <DeliveryPartnersGrid forPartnerId={partnerId} />,
              filterConfig: DEFAULT_DELIVERY_PARTNER_GRID_FILTERS
            }, {
              id: 'facilitators',
              title: 'Facilitators',
              count: implementationPartner.CountData.FacilitatorCount || 0,
              gridElement: <FacilitatorsGrid forPartnerId={partnerId} />,
              filterConfig: DEFAULT_FACILITATOR_GRID_FILTERS
            }]} />
      </>
      : <LoadingSpinner />}
  </div>;
}