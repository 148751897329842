import styled from "styled-components";

export const StyledFilterMenu = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;

  > * {
    padding: 0.5rem 1rem;
  }
`;