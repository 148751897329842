import { useHistory } from 'react-router';
import { useLayoutEffect, useState } from "react";

import { StyledBackButton } from "./Task.styled";
import goBackIcon from "./arrow-left.png"; 
import React from "react";
import { TasksCtx } from "../../contexts/TasksContext";
import { FacilitatorReviewFormBasic } from "../Forms/FacilitatorReviewForm/FacilitatorReviewForm";
import { redirectBack } from "../../utils/helper";
import { ITask } from '../../models/IUser';
import { StyledContainerHeader, StyledContainerRightItems, StyledContainerTitle, StyledInfo } from '../View/View.styled';
import { makeTaskBreadcrumb } from '../../utils/breadcrumbs';
import { PAGE } from '../../constants';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

interface ITaskProps {
    userId: string
}


export const TaskDetail: React.FC<ITaskProps> = ({ userId }) => {
    const history = useHistory();
    const { tasks } = React.useContext(TasksCtx);
    const [task, setTask] = useState<ITask>();

    useLayoutEffect(() => {
        if (tasks) {
            const filteredTasks = tasks.find((task: ITask) => task.Id === userId);
            if (filteredTasks) {
                setTask(filteredTasks);
            } else {
                history.push('/task');
            }
        }
        
    }, [tasks])

    const sendInfoToBreadcrumb = (userId: string) => {
        return makeTaskBreadcrumb(PAGE.TASK_DETAIL, userId);
    }
    
    return <>
    { task && 
    <>
    <Breadcrumbs breadCrumbs={sendInfoToBreadcrumb(userId)}/>
    <StyledContainerHeader>
        <StyledContainerTitle>
          <StyledBackButton src={goBackIcon} alt="Go back" onClick={()=>redirectBack(history)}/>
          <span>{task.Type}</span>
        </StyledContainerTitle>
        <StyledContainerRightItems>
          <div>{task.Date.split('T')[0]}</div>
        </StyledContainerRightItems>
    </StyledContainerHeader>
    <StyledInfo>
        <FacilitatorReviewFormBasic facilitatorId={userId} />
    </StyledInfo>
    </>
    }
    </>
}