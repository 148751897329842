import { useState } from "react";
import SearchIcon from "../../images/search.svg";
import { StyledSearchField, StyledSearchIcon, StyledSearchInputField } from "./Search.styled";

interface ISearchBoxProps {
    placeHolderText: string
    onChange?: (value: string) => void;
}

const SearchField: React.FC<ISearchBoxProps> = ({ placeHolderText, onChange }) => {
    const [searchInput, setSearchInput] = useState<string>("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const input = e.target.value;
        setSearchInput(input);
        onChange && onChange(input);
    } 

    return (
        <StyledSearchField>
            {searchInput.length === 0 && <StyledSearchIcon alt="search" src={SearchIcon} />}
            <StyledSearchInputField
                type="text"
                placeholder={placeHolderText}
                value={searchInput}
                onChange={handleChange}
            />
        </StyledSearchField>
    )
}

export default SearchField;