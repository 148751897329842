import React, { useLayoutEffect, useState } from 'react';
import {
  StyledDisabledField,
  StyledFormLabel, 
  StyledErrorMessage,
  StyledFormButtonContainerRow,
  StyledFormContainer,
  StyledFormWrapper,
  StyledFormFooterText,
  StyledFormButtonContainerColumn,
} from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm, Input } from '../ReactHookForm';
import { useHistory } from 'react-router';
import { FACILITATOR_REVIEW_RESULT, IFacilitator } from '../../../models/IUser';
import Api from '../../../services/Api';
import { USER_ROLE, USER_ROLE_NAMES, IS_FACILITATOR_REVIEW_DISABLED } from '../../../constants';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { canUserApproveFacilitator, getQueryParam } from '../../../utils/helper';
import { QuickModal, Modal } from '../../Modal/Modal';
import { FacilitatorArchiveForm } from '../ArchiveForms/FacilitatorArchiveForm';
import { Button } from '../../Button/Button';

interface IUserFormProps {
  facilitatorId?: string
}

//TODO: Consider refactor this with IFacilitator
interface userFormData {
  firstName: string;
  lastName: string;
  email: string;
  Facilitator_ReviewResult?: FACILITATOR_REVIEW_RESULT;
  Facilitator_PartnerId?: string;
  Facilitator_DeliveryPartnerId?: string;
  partner: string;
  deliveryPartner: string;
  Facilitator_ReviewedAt?: string;
  Facilitator_ReviewedByUserId?: string,
}

export const FacilitatorEditForm: React.FC<IUserFormProps> = ({ facilitatorId }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInital] = useState<boolean>(false);
  const [showCompletionModalForId, setShowCompletionModalForId] = useState<string>("");

  const suppliedImplementationPartnerOwnerId = getQueryParam('ipOwnerId');
  const suppliedDeliveryPartnerOwnerId = getQueryParam('dpOwnerId');

  const [ originalFacilitator, setOriginalFacilitator ] = useState<IFacilitator | null>(null);

  const [archiveModal, setArchiveModal] = useState<any>();
  const isCreateNew = !facilitatorId;
  let userFormData: userFormData = {
    firstName: '',
    lastName: '',
    email: '',
    partner: '',
    deliveryPartner: '',
  }

  const [formData, setFormData] = useState<userFormData>(userFormData);

  useLayoutEffect(() => {
    if (!isCreateNew) {
      Api.getFacilitatorById(facilitatorId)
        .then(async (user: IFacilitator | undefined) => {
          if (!user) return alert('No user found');
          else {
            setOriginalFacilitator(user);

            let partnerOwnerId = user.Facilitator_PartnerId;
            let deliveryPartner;
            const deliveryPartnerOwnerId = user.Facilitator_DeliveryPartnerId;
            if (deliveryPartnerOwnerId) {
              deliveryPartner = await Api.getDeliveryPartnerById(deliveryPartnerOwnerId);
              if (!deliveryPartner) throw new Error('Invalid delivery partner ID');
              partnerOwnerId = deliveryPartner.DeliveryPartner_PartnerId
            }
            const partner = await Api.getPartnerById(partnerOwnerId);
            if (!partner) throw new Error('Invalid partner ID');
            setFormData({
              firstName: user.FirstName,
              lastName: user.LastName,
              email: user.Email,
              Facilitator_ReviewResult: user.Facilitator_ReviewResult,
              Facilitator_DeliveryPartnerId: user.Facilitator_DeliveryPartnerId,
              Facilitator_PartnerId: user.Facilitator_PartnerId,
              partner: partner.FirstName,
              deliveryPartner: deliveryPartner ? deliveryPartner.FirstName : '',
              Facilitator_ReviewedAt: user.Facilitator_ReviewedAt,
              Facilitator_ReviewedByUserId: user.Facilitator_ReviewedByUserId,
            });            
          }
          setIsInital(true);
        })
        .catch(err => {
          console.log(err);
          setIsInital(true);
          alert("Retrieve user failed");
        });
    } else {
      const atLeastOneId = suppliedDeliveryPartnerOwnerId || suppliedImplementationPartnerOwnerId;
      if (!atLeastOneId) throw new Error('DP/IP id has not been provided');
      if (!suppliedImplementationPartnerOwnerId) {
        Api.getDeliveryPartnerById(suppliedDeliveryPartnerOwnerId).then((deliveryPartner) => {
          if (!deliveryPartner) return alert('No partner found');
          setFormData({ ...formData, Facilitator_PartnerId: deliveryPartner.DeliveryPartner_PartnerId })
          setIsInital(true)
        })
      } else {
        setIsInital(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilitatorId]);

  const saveUser = async (data?: userFormData) => {
    setIsLoading(true);
    const updateFormData = data ? data : formData;
    if (!updateFormData) return;
    const userBundle = { userId: facilitatorId, ...updateFormData }
    return new Promise((resolve, reject) => {
      Api.createOrUpdateUser(USER_ROLE.Facilitator, userBundle)
        .then((res) => {
          setIsLoading(false);
          resolve(true);
          setShowCompletionModalForId(res.Id);
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err.message);
          setErrorMessage(err.message);
          reject();
        })
      });
  };

  const renderArchiveModal = (facilitatorId: string) => {
    const hideArchiveModal = () => setArchiveModal(null);
    setArchiveModal(<Modal>
      <FacilitatorArchiveForm facilitatorId={facilitatorId} onCancel={hideArchiveModal} onArchive={() => {
        hideArchiveModal();
        history.goBack();
      }} />
    </Modal>)
  }

  const onSubmit = async (data: userFormData) => {
    setErrorMessage('');
    if (suppliedImplementationPartnerOwnerId && !formData.Facilitator_PartnerId) data.Facilitator_PartnerId = suppliedImplementationPartnerOwnerId;
    if (suppliedDeliveryPartnerOwnerId) data.Facilitator_DeliveryPartnerId = suppliedDeliveryPartnerOwnerId;

    setFormData({ ...formData, ...data });
    saveUser(data);
  };

  const emailValidation = { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true };
  const requiredValidation = { required: true };
  const emailMessage = { required: 'Please enter a valid Email Address', pattern: 'Entered value does not match email format' };
  const firstNameMessage = { required: 'Please enter a First Name' };
  const lastNameMessage = { required: 'Please enter a Last Name' };

  const getFacilitatorReviewResult = () => {
    const facilitatorState = formData.Facilitator_ReviewResult;

    if (facilitatorState === FACILITATOR_REVIEW_RESULT.Approved) {
      return `Approved at ${formData?.Facilitator_ReviewedAt?.split('T')[0]} By ${formData.Facilitator_ReviewedByUserId === formData.Facilitator_PartnerId ? formData.partner : 'Movember Admin'}`
    }

    if (facilitatorState === FACILITATOR_REVIEW_RESULT.Rejected) {
      return "Facilitator has been denied.";
    }

    return "Facilitator request is pending.";
  }

  const getFacilitatorAccreditationText  = () => {
    if(!isCreateNew) return getFacilitatorReviewResult();
    
    if (canUserApproveFacilitator()) {
      return `By clicking ‘Create’ you are declaring that this Facilitator has completed all training and certifications required for approval.`
    } else {
      return `Facilitator request will be sent to Implementation Partner for approval to ensure they have completed all training and accreditations`
    }
  };

  const redirectBack = (facilitatorId: string) => {
    //TODO: maybe if(!canUserApproveFacilitator()) history.goBack(); ? they shouldn't be able to see a non approved facilitators page
    
    history.push(`/facilitator/${facilitatorId}`);
  }

  const getCompletionModal = () => {
    // if IS_FACILITATOR_REVIEW_DISABLED = true, redirect back without popup modal. 
    if (IS_FACILITATOR_REVIEW_DISABLED) {
      redirectBack(originalFacilitator ? originalFacilitator.Id : showCompletionModalForId);
    }

    // if the user was already approved, we can just leave
    if (originalFacilitator && originalFacilitator.Facilitator_ReviewResult === FACILITATOR_REVIEW_RESULT.Approved) {
      redirectBack(originalFacilitator.Id);
    };
    
    // if this user can't approve facilitators, we show a confirmation showing, 'facilitator request has been sent to partner for approval'
    if (!canUserApproveFacilitator()) {
      return (
        <QuickModal
          innerHeader="Facilitator Is Awaiting Approval"
          innerText={`Facilitator request has been sent to ${
            formData.partner || "Implementation Partner"
          } for approval`}
          buttons={[
            {
              solid: true,
              label: "Ok",
              width: "100%",
              onClick: () => {
                redirectBack(showCompletionModalForId);
              },
            },
          ]}
        />
      );
    } else {
      redirectBack(showCompletionModalForId);
    }
  }

  return <StyledFormWrapper>
    {!isInital ?
      <LoadingSpinner />
      :
      <StyledFormContainer>
        <Heading level='h4' primary>{facilitatorId ? 'EDIT' : 'ADD'} {USER_ROLE_NAMES[USER_ROLE.Facilitator].toUpperCase()}</Heading>
        <GenericReactHookForm<userFormData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading}>
          <StyledFormLabel>First Name</StyledFormLabel>
          <Input name='firstName' placeholder={'John'} type='text' validate={requiredValidation} validatemessage={firstNameMessage} />
          <StyledFormLabel>Last Name</StyledFormLabel>
          <Input name='lastName' placeholder={'Smith'} type='text' validate={requiredValidation} validatemessage={lastNameMessage} />
          {facilitatorId ? <StyledFormLabel>Email <br /><StyledDisabledField>{formData.email}</StyledDisabledField></StyledFormLabel>
            : <>
              <StyledFormLabel>Email</StyledFormLabel>
              <Input name='email' placeholder={'john@movember.com'} type='text' disabled={!!facilitatorId} validate={emailValidation} validatemessage={emailMessage} />
            </>
          }
          {formData.deliveryPartner && <StyledFormLabel>Delivery Partner <br /><StyledDisabledField>{formData.deliveryPartner}</StyledDisabledField></StyledFormLabel>}
          {formData.partner && <StyledFormLabel>Implementation Partner <br /><StyledDisabledField>{formData.partner}</StyledDisabledField></StyledFormLabel>}
          { !IS_FACILITATOR_REVIEW_DISABLED && <StyledFormLabel>Training and Accreditation<br/><StyledDisabledField>{getFacilitatorAccreditationText()}</StyledDisabledField></StyledFormLabel>}
          { IS_FACILITATOR_REVIEW_DISABLED && <br></br> }
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
          {facilitatorId ?
            <StyledFormButtonContainerColumn> 
              <StyledFormButtonContainerRow>
                <Button width="100%" onClick={(e: any) => {
                  e.preventDefault(); //form will try to submit without this
                  history.goBack();
                }} label="Cancel" />
                <Button width="100%" type="button" label='Archive' onClick={() => renderArchiveModal(facilitatorId)} />
              </StyledFormButtonContainerRow>
              <StyledFormButtonContainerRow>
                <Button width="100%" solid type="submit" label="Save" />
              </StyledFormButtonContainerRow>
            </StyledFormButtonContainerColumn>
            :
            <StyledFormButtonContainerRow>
              <Button width="100%" onClick={(e: any) => {
                e.preventDefault(); //form will try to submit without this
                history.goBack();
              }} label="Cancel" />
              <Button width="100%" solid type="submit" label={ canUserApproveFacilitator() ? 'Create' : 'Submit'} />
            </StyledFormButtonContainerRow>}
            {!IS_FACILITATOR_REVIEW_DISABLED && isCreateNew && <StyledFormFooterText>{canUserApproveFacilitator()
              ? `Clicking 'Create' will send an email invitation to the facilitator`
              : `Clicking 'Submit' will send an approval email to your Implementation Partner`}</StyledFormFooterText>
            }
        </GenericReactHookForm>
        {formData && showCompletionModalForId && getCompletionModal()}
        {archiveModal}
      </StyledFormContainer>
    }

  </StyledFormWrapper>

};