import styled from 'styled-components';
import { Heading } from '../../Heading/Heading';

export const StyledModalWrapper = styled.div`
  height: 225px;
`;

export const StyledHeading = styled(Heading).attrs({ level: 'h4', primary: true})`
  font-size: 33px;
  opacity: 0.5;
  text-align: left;
`;

export const StyledDescription = styled.div`
  margin-bottom: 2rem;
  margin-top: 2rem;
`;