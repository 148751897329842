import React, { createContext, useContext, useEffect, useState } from 'react';
import { StyledBreadcrumbs, StyledCurrentBreadcrumb, StyledPreviousBreadcrumb } from './Breadcrumbs.styled';
import arrowRight from "./../../images/arrow_right.png"; 

export interface BreadcrumbElement {
  title: string;
  description: string;
  toLocation: string;
}

export type Breadcrumbs = BreadcrumbElement[];

interface IBreadcrumbsProps {
  breadCrumbs?: Breadcrumbs
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ breadCrumbs }) => {
 
  return (
    
      <StyledBreadcrumbs>
        {breadCrumbs?.map((breadCrumb: BreadcrumbElement, index: number) => {
          return <>
          {index < breadCrumbs.length - 1 &&
            <div style={{display: 'flex'}}>
              <StyledPreviousBreadcrumb to={breadCrumb.toLocation}>
                <span>{breadCrumb.title}</span>
                <div>{breadCrumb.description}</div>
              </StyledPreviousBreadcrumb>
              <div style={{ marginTop:'8px' }}>
                <img alt='arrow right' src={arrowRight}></img>
              </div>
            </div>
          }

          {
            index === breadCrumbs.length - 1 &&
              <StyledCurrentBreadcrumb>
                <span>{breadCrumb.title}</span>
                <div>{breadCrumb.description}</div>
              </StyledCurrentBreadcrumb>
          }
          </>
        })}
      </StyledBreadcrumbs> 
  )
}