import React, { useLayoutEffect, useState } from 'react';
import { StyledErrorMessage, StyledFormLabel, StyledFormButtonContainerRow, StyledFormContainer } from '../Form.styled';
import Api from '../../../services/Api';
import { useHistory } from 'react-router';
import { openFacilitatorApp } from '../../../utils/helper';
import { ICohort } from '../../../models/ICohort';
import { GenericReactHookForm, Input } from '../ReactHookForm';
import { Heading } from '../../Heading/Heading';
import { Button } from '../../Button/Button';

interface ICohortEditFormProps {
  onClose: () => void,
  onSuccess: (headCount: number, sessionStartDateTime: string) => void,
  cohortId: string,
  sessionId?: string
}

interface userFormData {
  headCount?: number,
  sessionStartDateTime?: string
  sessionEndDateTime?: string
  facilitatorId?: string,
  deliveryDate?: string,
}

export const StartSessionForm: React.FC<ICohortEditFormProps> = ({ onClose, onSuccess, sessionId, cohortId }) => {
  const history = useHistory();

  const [errorMessage, seterrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInital, setIsInital] = useState<boolean>(false);
  const today = new Date().toISOString();
  let userFormData: userFormData = {
    sessionStartDateTime: '',
    sessionEndDateTime: '',
    facilitatorId: ''
  }

  const [formData, setFormData] = useState<userFormData>(userFormData);

  const [cohort, setCohort] = useState<ICohort | null>(null);
  useLayoutEffect(() => {
    if (sessionId) {
      Api.getCohortById(cohortId)
        .then(async (cohort: ICohort | undefined) => {
          if (!cohort) return alert('No cohort found');
          setCohort(cohort);
          let tempSession;
          if (cohort.Sessions?.Resilience?.Id === sessionId) {
            tempSession = cohort.Sessions?.Resilience;
          }
          if (cohort.Sessions?.Literacy?.Id === sessionId) {
            tempSession = cohort.Sessions?.Literacy;
          }
          setFormData({
            headCount: tempSession?.HeadCount,
            sessionStartDateTime: tempSession?.SessionStartDateTime,
            sessionEndDateTime: tempSession?.SessionEndDateTime,
            facilitatorId: tempSession?.FacilitatorId,
            deliveryDate: tempSession?.DeliveryDate,
          });
          setIsInital(true);
        })
    } else {
      setIsInital(true);
    }
  }, [sessionId, cohortId])

  const saveUser = (data?: userFormData) => {
    setIsLoading(true);
    const updateFormData = data ? data : formData;
    if (!updateFormData) return;
    const userBundle = { sessionId, ...updateFormData, cohortId }
    Api.updateSessionForSessionStart(userBundle)
      .then(() => {
        setIsLoading(false);
        onSuccess(updateFormData.headCount || 0, updateFormData.sessionStartDateTime || today);
        // history.push(`/cohort/${cohortId}/session/${sessionId}/`);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
        alert('Save session failed!');
        seterrorMessage('');
      })
  }

  const onSubmit = (data: userFormData) => {
    const tempData = {
      ...formData,
      sessionStartDateTime: formData.sessionStartDateTime ?? today,
      headCount: data.headCount,
    }
    setFormData(tempData);
    saveUser(tempData);
    openFacilitatorApp(cohort?.Sport);
  }

  const headcountMessage = { required: 'Please enter a headcount', pattern: 'Please enter a valid headcount' };
  const headcountValidation = { pattern: /^[0-9]*$/, required: true };

  return <>
    {isInital && <StyledFormContainer>
      <Heading level='h4' primary text='START COHORT SESSION' />
      <br />
      <p>Take a headcount and provide it in the field below (number of players/parents/coaches present)</p>
      <GenericReactHookForm<userFormData> defaultValues={formData} onSubmit={onSubmit} isLoading={isLoading}>
        <StyledFormLabel>Headcount</StyledFormLabel>
        <Input name='headCount' placeholder={'0'} type='text' validatemessage={headcountMessage} validate={headcountValidation} />
        <StyledErrorMessage>
          {errorMessage}
        </StyledErrorMessage>
        <br />
        <p>Upon starting the session, the cohort start date will be set and some details will no longer be editable. <br /><br /> The facilitator presentation will be launched in a new window once started. <br /><br /> Are you ready to start the session?</p>
        <StyledFormButtonContainerRow>
          <Button width="100%" type="button" onClick={(e) => {
            e.preventDefault(); //form will try to submit without this
            onClose();
          }} label="Cancel" />
          <Button width="100%" solid type="submit" label="Start" />
        </StyledFormButtonContainerRow>
      </GenericReactHookForm>
    </StyledFormContainer>}
  </>
};
