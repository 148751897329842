import React, { useState, useEffect, forwardRef } from "react";
import Api from '../../services/Api';
import { CHAPTER_IDENTIFIER, TOTAL_CHAPTER_NUMBER } from "../../constants";
import { ICellRendererParams } from 'ag-grid-community';
import { IPlayer } from "../../models/IUser";
import { sortByFunctions, sortRowsByIdFn, toPercent } from "../../utils/helper";
import { FcCheckmark } from 'react-icons/fc';
import { RxCross2 } from 'react-icons/rx'
import { ReactComponent as ArchiveIcon} from '../../images/archive.svg';
import { Modal } from "../Modal/Modal";
import { PlayerArchiveForm } from "../Forms/ArchiveForms/PlayerArchiveForm";
import { AgGridReact } from "ag-grid-react";

interface IPlayersGridProps {
  forPartnerId?: string;
  forFacilitatorId?: string;
  forDeliveryPartnerId?: string;
  forCohortId?: string;
}

export const PlayersGrid: React.FC<IPlayersGridProps> = forwardRef((props, agGridRef: any) => {
  const { forPartnerId, forFacilitatorId, forCohortId, forDeliveryPartnerId } = props;

  const [rowData, setRowData] = useState<IPlayer[]>();
  const [archiveModal, setArchiveModal] = useState<any>();

  useEffect(() => {
    Api.getPlayers({
      forPartnerId: forPartnerId,
      forDeliveryPartnerId: forDeliveryPartnerId,
      forFacilitatorId: forFacilitatorId,
      forCohortId: forCohortId
    }).then((players: IPlayer[]) => {
      const sortedPlayers = players.sort((a, b) => sortByFunctions(a, b, [sortRowsByIdFn]));
      setRowData(sortedPlayers)
    })
  }, [forPartnerId, forFacilitatorId, forCohortId, forDeliveryPartnerId]);

  const getActionColumns = () => {
    const renderArchiveModal = (playerId: string) => {
      const hideArchiveModal = () => setArchiveModal(null);
      setArchiveModal(<Modal>
        <PlayerArchiveForm playerId={playerId} onCancel={hideArchiveModal} onArchive={() => {
          hideArchiveModal();
          window.location.reload();
        }}/>
      </Modal>)
    }
    
    return [
      {
        field: 'archive',
        headerName: 'Archive',
        cellRenderer: (params: ICellRendererParams) => <ArchiveIcon onClick={() => renderArchiveModal(params.data.Id)}/>,
        cellStyle: {cursor: 'pointer'}
      }
    ]
  };
  
  const getPlayerDetailsColumns = () => {
    const cols = [
      { field: "Id", hide: true, isPlayerDetails: true },
      { field: "FirstName", headerName: "Name", cellRenderer: (params: ICellRendererParams) => params.data.FirstName + ' ' + params.data.LastName, headerTooltip: "Name of the Player" },
      { field: "CreatedAt", headerName: "Created", cellRenderer: getFormattedDate, headerTooltip: "Date Player signed up to the Challenge" }
    ];
  
    return cols.map(col => ({
      ...col,
      width: 160,
      minWidth: 160,
      flex: 1
    }));
  };

  return <>
    <AgGridReact
      {...props}
      ref={agGridRef}
      rowData={rowData}
      tooltipShowDelay={0}
      columnDefs={[
        {
          headerName: "Player Details",
          children: getPlayerDetailsColumns()
        },
        {
          headerName: "Chapter Progress",
          children: getChapterProgressColumns()
        },
        {
          headerName: "Action",
          children: getActionColumns(),
        }
      ]}
    />
    {archiveModal}
  </>
});

const getChapterProgressColumns = () => {
  const cols = [
      { field: "CompletionPercent", 
      headerName: "Completed", 
      cellRenderer: (params: ICellRendererParams) => `${toPercent(getCompletionCount(params), TOTAL_CHAPTER_NUMBER)}%`, 
      headerTooltip: "Status of Player completing the Challenge"
    },
    ...Array.from({length: TOTAL_CHAPTER_NUMBER}).map((_, idx) => {
      const chapterName = `${idx + 1}`;
      return { 
        field: chapterName, 
        headerName: chapterName, 
        cellRenderer: (params: ICellRendererParams) => {
          const chapterIdentifier = Object.values(CHAPTER_IDENTIFIER); 
          const chapterId = chapterIdentifier[parseInt(params.colDef?.headerName || '') - 1];
          const result = params.data.Player_Data?.userProgress?.chapterProgress[chapterId]?.firstCompletedAt ? <FcCheckmark/> : <RxCross2 color="red"/>;
          return result;
        }, 
        columnGroupShow: 'open', 
        sortable: false
      }
    }),
  ];

  return cols.map(col => ({
    ...col,
    minWidth: 80,
    flex: 2  
  }));
};

const getFormattedDate = (params: ICellRendererParams) => new Date(params.data.CreatedAt).toLocaleString();
const getCompletionCount = (params: ICellRendererParams) => {
  const chaptersProgress: any[] = Object.values(params.data.Player_Data?.userProgress?.chapterProgress || {});
  return chaptersProgress.reduce((total: number, chapter: { firstCompletedAt: any; }) => (chapter?.firstCompletedAt ? total + 1 : total), 0)
};
