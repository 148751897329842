import React from 'react';

//A base component used for consistent styling
//Avoid putting lots of optional props onto this

import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6
} from './Heading.styled';

interface HeadingProps {
  primary?: boolean //true = Teko, false = Overpass. levels differ too
  level?: string //h1,h2,h3,h4,h5,h6
  text?: string
  
  className?: string //Used to inherit any other styles
}

export const Heading: React.FC<HeadingProps> = ({ level = 'h1', primary = false, children, text = "", className }) => {

  const headingProps = {
    className,
    primary: primary,
    level
  };

  const content = <>
    <div> {text} </div>
    {children}
  </>;

  switch(level) {
    case 'h6':
      return <StyledH6 {...headingProps}>{content}</StyledH6>;
    case 'h5':
      return <StyledH5 {...headingProps}>{content}</StyledH5>;
    case 'h4':
      return <StyledH4 {...headingProps}>{content}</StyledH4>;
    case 'h3':
      return <StyledH3 {...headingProps}>{content}</StyledH3>;
    case 'h2':
      return <StyledH2 {...headingProps}>{content}</StyledH2>;
    case 'h1':
    default:
      return <StyledH1 {...headingProps}>{content}</StyledH1>;
  };
};