import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { ICellRendererParams } from 'ag-grid-community';

import { getUserStatus, toPercent } from "../../../utils/helper";
import { StyledGridContainer, StyledCellHyperLink } from "./GridContainer.styled";
import { USER_STATUS } from "../../../constants";
import { IUser } from "../../../models/IUser";
import { TabPanel } from "../../TabPanel/TabPanel";
import { GridDownloadButton } from "../GridDownloadButton/GridDownloadButton";
import { StyledContainerRightItems, StyledContainerHeader, StyledContainerTitle } from "../../View/View.styled";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import GridFilters from "../GridFilters/GridFIlters";

export interface GridFilterConfig {
  [id: string]: {
    label: string,
    isOn: any,
    filterFn: (data: any) => boolean
  }
}

export interface GridConfig {
  id: string, //used for initialTabId
  title?: string, //tab title. if only 1 grid is provided, this will appear as a container title.
  count?: number,
  gridElement: ReactElement,

  filterConfig?: GridFilterConfig //If specified, will show a filter button
}

export interface GridContainerProps extends AgGridReactProps {
  initialTabId?: string,
  extraButtons?: ReactElement[], //TODO: If specified, will show a create new button
  
  //gridConfigs are immediately transferred from props into state and used to manage each grid's filters/state
  //if more than one specified, the tabs will be shown
  gridConfigs: GridConfig[] 
}

//Our grid with the default theme/dimensions 
export const GridContainer: React.FC<GridContainerProps> = (props) => {
  const { initialTabId, extraButtons } = props;

  //Must be an array to allow for multiple grids
  const gridRefs = useRef<AgGridReact[]>([]);

  //the passed-in gridConfigs are immediately moved into state so they can can be updated (eg. filters)
  const [gridConfigs, setGridConfigs] = useState<GridConfig[]>(props.gridConfigs);

  const initialIndex = initialTabId ? gridConfigs.findIndex(gridConfig => gridConfig.id === initialTabId) : 0;
  const [activeGridIndex, setActiveGridIndex] = useState<number>(initialIndex >= 0 ? initialIndex : 0);
  const activeGrid = gridConfigs[activeGridIndex];
  
  const [searchActive, setSearchActive] = useState<boolean>(false);

  useEffect(() => {
    if(!gridRefs.current || !gridRefs.current[activeGridIndex] || !gridRefs.current[activeGridIndex].api) return;
    gridRefs.current[activeGridIndex].api.onFilterChanged();
  }, [gridConfigs]);

  const onFilterChange = (filterId: string, isOn: boolean) => {
    //We update the grid config for this grid to the new filter settings.
    //This will maintain this grid's settings when swapping between grids/tabs
    setGridConfigs([...gridConfigs.map(gridConfig => {
      if(gridConfig.id === activeGrid.id && gridConfig.filterConfig) {
        return {
          ...gridConfig,
          filterConfig: {
            ...gridConfig.filterConfig,
            [filterId]: {
              ...gridConfig.filterConfig[filterId],
              isOn
            }
          }
        }
      } else {
        return gridConfig;
      }
    })])
  };

  const onSearchChange = (value: string) => {
    setSearchActive(value.length > 0);
    gridRefs.current[activeGridIndex].api.setQuickFilter(value);
  }

  const getGridComponent = (gridConfig: GridConfig, gridIndex: number) => {
    //Takes the passed in grid component and applies extra properties to it that are required
    // to manipulate the grid inside this container (eg. filtering, ref, defaults, etc)
    const DEFAULT_GRID_CONFIG = {
      defaultColDef: {
        sortable: true, 
        filter: true, 
        resizable: true, 
        suppressMovable: true, 
        suppressSizeToFit: false,
      },
      pagination: true,
      paginationPageSize: 16
    };

    return React.cloneElement(gridConfig.gridElement, { 
      ...DEFAULT_GRID_CONFIG,
      key: gridIndex, 
      tooltipShowDelay: 0,
      className: `${gridConfig.gridElement.props.className} ${gridIndex === activeGridIndex || "not-visible"} grid ag-theme-alpine`,

      getRowClass: (params: any) => {
        //A catch-all class for styling archived rows, might need to be moved to be grid-specific at some point
        if (!!params.node.data.ArchivedAt || params.node.data.Status === 'archived') return 'archived-row';
      },
      isExternalFilterPresent: () => {
        const filters = gridConfigs[gridIndex].filterConfig;
        return !searchActive &&  filters && Object.keys(filters).length > 0;
      },
      doesExternalFilterPass: (node: any) => {
        if (searchActive) return true;

        const filters = gridConfigs[gridIndex].filterConfig;
        if(!filters) return true;
    
        //Find any filter that the node passes to return true
        return !!Object.keys(filters)
          .filter(filterId => filters[filterId].isOn)
          .find(filterId => filters[filterId].filterFn(node.data));
      },
      ref: (ref: any) => {
        gridRefs.current[gridIndex] = ref;
      }
    });
  };

  const getGridContainerContent = () => {
    const gridButtons = [];
  
    if(activeGrid.filterConfig) gridButtons.push(<GridFilters activeGrid={activeGrid} onFilterChange={onFilterChange} onSearchChange={onSearchChange} />);

    // check whether we have tabs, we want to make sure we create the grid before instantiating the download button
    if (gridConfigs.length > 1) {
      //Create the array of grid buttons on the toolbar
      const gridTabs = gridConfigs.map((gridConfig, index) => ({ 
        id: gridConfig.id, 
        super: gridConfig.count,
        label: gridConfig.title || gridConfig.id, 
        content: getGridComponent(gridConfig, index)}));

      const gridDownloadButton = <GridDownloadButton activeGrids={gridRefs} activeGridIndex={activeGridIndex} />;
      gridButtons.push(gridDownloadButton);
      if (extraButtons) gridButtons.push(...extraButtons);

      return <TabPanel 
        navButtons={[...gridButtons]} 
        initialTabId={initialTabId} 
        onTabChange={(tabId, index) => setActiveGridIndex(index)} 
        tabs={gridTabs} />
    } 

    const gridComponent = getGridComponent(gridConfigs[activeGridIndex], activeGridIndex) as any;
    const gridDownloadButton = <GridDownloadButton activeGrids={gridRefs} activeGridIndex={activeGridIndex} />;
    gridButtons.push(gridDownloadButton);
    if (extraButtons) gridButtons.push(...extraButtons);

    return <>
    {(activeGrid.title && gridButtons.length > 0) && <StyledContainerHeader>
      <StyledContainerTitle>{activeGrid.title}</StyledContainerTitle>
      <StyledContainerRightItems>{[...gridButtons]}</StyledContainerRightItems>
    </StyledContainerHeader>}
    {gridComponent}
    </>
  }

  return <StyledGridContainer>
    {getGridContainerContent()}
  </StyledGridContainer>;
};

/****************************/
//Re-usable grid renderers - not sure if they belong here...
/****************************/

export const completedPercentRenderer = (params: ICellRendererParams) => {
  return <span>
    {params.data.CountData.PlayerCompletedCount > 0
      ? `${toPercent(params.data.CountData.PlayerCompletedCount, params.data.CountData.PlayerCount)}% (${params.data.CountData.PlayerCompletedCount})`
      : '0'}
  </span>
}
;

//User status
export const userStatusRenderer = (user: IUser, onResendEmailClick?: () => void) => {
  const status = getUserStatus(user);

  if (status === USER_STATUS.Active) return "Active";
  if (status === USER_STATUS.Archived) return "Archived";
  if (status === USER_STATUS.PendingReview) return "Pending";
  if (status === USER_STATUS.EmailSent) return <>Email Sent <StyledCellHyperLink
    onClick={(e) => {
      e.preventDefault();
      if(onResendEmailClick) onResendEmailClick();
    }}>{'[Resend]'}</StyledCellHyperLink></>;

  return "Unknown";
}