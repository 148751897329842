import React, { useState, useEffect, useCallback, forwardRef } from "react";
import Api from '../../services/Api';
import editIcon from '../../images/edit.svg';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { getCohortStatus, sortByFunctions, sortRowsByArchivedFn, sortRowsByIdFn, toPercent } from "../../utils/helper";
import { GridFilterConfig, completedPercentRenderer } from "./GridContainer/GridContainer";
import { ICohort } from "../../models/ICohort";
import { CHAPTER_IDENTIFIER, TOTAL_CHAPTER_NUMBER } from "../../constants";
import { AgGridReact } from "ag-grid-react";

interface ICustomCellRendererParams extends ICellRendererParams{
  index: number;
}
interface ICohortsGridProps {
  forPartnerId?: string;
  forDeliveryPartnerId?: string;
  forFacilitatorId?: string;
};

export const DEFAULT_COHORT_GRID_FILTERS: GridFilterConfig = {
  'status-active': {
    label: 'Active',
    isOn: true,
    filterFn: (data: ICohort) => {
      return !data.ArchivedAt;
    }
  },
  'status-archived': {
    label: 'Archived',
    isOn: false,
    filterFn: (data: ICohort) => {
      return !!data.ArchivedAt;
    }
  }
};

//For the MANAGEMENT of cohorts
export const CohortsGrid: React.FC<ICohortsGridProps> = forwardRef((props, agGridRef: any) => {
  const { forPartnerId, forDeliveryPartnerId, forFacilitatorId } = props;
  const [rowData, setRowData] = useState<ICohort[]>();

  useEffect(() => {
    Api.getCohorts(forPartnerId, forFacilitatorId, forDeliveryPartnerId)
      .then((cohorts: ICohort[]) => {
        const sortedCohorts = cohorts.sort((a, b) => sortByFunctions(a, b, [sortRowsByArchivedFn, sortRowsByIdFn]));
        setRowData(sortedCohorts);
      }); 
  }, [forPartnerId, forDeliveryPartnerId, forFacilitatorId]);


  const getCohortDetailsColumns = () => {
    const cols = [
      { field: 'Title', headerName: "Cohort Name", cellRenderer: (params: ICellRendererParams) => <Link to={`/cohort/${params.data.Id}`}>{params.data.IsDemo ? <b>DEMO </b> : ""}{params.value}</Link>, headerTooltip: 'Name of the Cohort', flex: 1 },
      { field: 'Postcode', headerName: 'Post Code', headerTooltip: 'Postcode of the Cohort', maxWidth: 100 },
      { field: 'Status', headerName: 'Status', cellRenderer: (params: ICellRendererParams) => getCohortStatus(params.data), headerTooltip: 'Status of the Cohort' }
    ]

    return cols.map(col => ({
      ...col,
      width: 100,
      minWidth: 100,
      flex: 1,
    }));
  }
  const getTheChallengeDetailsColumns = () => {
    const chapterIdentifier = Object.values(CHAPTER_IDENTIFIER); 
    const cols = [
      { field: 'Sessions.Resilience.DeliveryDate', headerName: 'Delivery Date', cellRenderer: (params: ICellRendererParams) => params.value || 'TBD', headerTooltip: 'Delivery date for The Challenge Workshop' },
      { field: 'CountData.PlayerCount', headerName: 'Players', cellRenderer: (params: ICellRendererParams) => params.value || 0, columnGroupShow: 'open', headerTooltip: 'The number of players signed up to The Challenge App' },
      ...Array.from({ length: TOTAL_CHAPTER_NUMBER }).map((_, idx) => {
        const chapterNumber = `${idx + 1}`;
        
        return { field: `CountData.ChapterCompletionCounts.${chapterIdentifier[idx]}`, 
          headerName: chapterNumber, 
          columnGroupShow: 'open', 
          cellRenderer: (params: ICustomCellRendererParams) => {
            return `${toPercent(params.value, params.data.CountData.PlayerCount)}% (${params.value || 0})`;
          }, 
          headerTooltip: `The percentage and (number) of players who have completed Chapter ${chapterNumber}`, 
          sortable: false
        }
      }),
    ];

    return cols.map(col => ({
      ...col,
      minWidth: 100,
      flex: 2
    }));
  }


  return <AgGridReact 
    {...props}
    ref={agGridRef}
    rowData={rowData}
    columnDefs={[
      {
        headerName: 'Cohort Details',
        children: getCohortDetailsColumns()
      },
      {
        headerName: 'The Challenge',
        children: getTheChallengeDetailsColumns()
      },
      {
        headerName: 'Literacy',
        children: [
          { field: 'Sessions.Literacy.DeliveryDate', headerName: 'Delivery Date', cellRenderer: (params: ICellRendererParams) => params.value || 'TBD', headerTooltip: 'Delivery date for the Literacy Workshop', minWidth: 80, flex: 1 }
        ]
      },
      {
        headerName: '',
        children: [
          { field: 'Edit', headerName: 'Edit', cellRenderer: (params: ICellRendererParams) => <Link to={`/edit/cohort/${params.data.Id}`}><img src={editIcon} alt='edit icon' /></Link>, headerTooltip: 'Edit the details of a Cohort', sortable: false, suppressMenu: true, resizable: false, suppressSizeToFit: true, width: 80 }
        ]
      }
    ]}
  />;
});