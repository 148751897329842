import React from 'react';
import { StyledButton } from './Button.styled';
import { Heading } from '../Heading/Heading';

export interface IButtonProps {
  label: string;
  type?: "button" | "submit" | undefined;
  solid?: boolean;
  disabled?: boolean;
  onClick?: (event: any) => void;
  icon?: string;
  width?: string; //eg. 160px, 100%, auto

  className?: string;
  children?: React.ReactNode;
}

export const Button: React.FC<IButtonProps> = ({ type = "button", onClick, label, className, disabled, icon, width, solid = false, children }) => {
  return (
    <StyledButton
      type={type}
      icon={icon}
      solid={solid}
      disabled={disabled}
      width={width}
      onClick={(event) => !disabled && onClick && onClick(event)}
      className={className}
    >
       <Heading primary level="h5" text={children ? '' : label}>{children}</Heading> 
    </StyledButton>
  );
}

