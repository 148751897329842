import React from 'react';
import { CHAPTER_IDENTIFIER, SESSION_TYPE, SORTED_COUNTRY_OPTIONS, SORTED_SPORT_OPTIONS } from '../../../constants';
import { getUserStatus, toPercent } from '../../../utils/helper';
import { AgGridReact } from 'ag-grid-react';
import { GridButton } from '../GridButton/GridButton';
import downloadIcon from "../../../images/download.svg"; 

interface GridDownloadButtonProps {
  activeGrids: React.MutableRefObject<AgGridReact[]>,
  activeGridIndex?: number
}

export const GridDownloadButton: React.FC<GridDownloadButtonProps> = ({ activeGrids, activeGridIndex }) => {
  const onDownload = () => {
    // check if we've got a grid
    if (!activeGrids) {
      console.error(`Grids not assigned to GridDownloadButton`);

      return;
    };
    
    const grid = activeGrids.current[activeGridIndex ?? 0];

    if (!grid) {
      console.error(`Unable to resolve current grid`);
      
      return;
    }

    const shownColumns = grid.columnApi.getAllGridColumns().filter(col => !['Edit', 'Id'].includes(col.getColId())).map(col => col.getColId());
    
    grid.api.exportDataAsCsv({
      allColumns: false,
      columnKeys: shownColumns,
      processCellCallback: (cell) => {
        const colDef = cell.column.getColDef();
        const data = cell.node?.data;
        if (!data) return cell.value;
        switch (colDef.cellRenderer) {
          case 'typeView':
            if(data.Type === SESSION_TYPE.HelpOutAMate) return 'HOAM';
            if(data.Type === SESSION_TYPE.TheChallenge) return 'The Challenge';
            break;
          case 'statusRenderer':
            const userStatus = getUserStatus(data);
            
            return userStatus;
          case 'cohortStatus':
            return data.ArchivedAt ? 'Archived' : 'Active';
          case 'fullNameView':
            if (data.FirstName && data.LastName) {
              return `${data.FirstName} ${data.LastName}`;
            }
            if (data.cohort) {
              const fullName = `${data.cohort.ClubName} ${data.cohort.AgeGroup} ${data.cohort.Gender} ${data.cohort.Audience}`;

              return fullName;
            }
            break;
          case 'completedPercentRenderer':
            if (data.CountData.PlayerCompletedCount && data.CountData.PlayerCount) {
              const playerCount = data.CountData.PlayerCount || data.PlayerCount;
              return `${toPercent(data.CountData.PlayerCompletedCount, playerCount)}% (${data.CountData.PlayerCompletedCount})`
            }
            break;
          case 'countryRenderer':
            if (cell.value) {
              const matchedCountry = SORTED_COUNTRY_OPTIONS.find(country=>country.value === cell.value);
              if (matchedCountry) return matchedCountry.label;
            }
            break;
          case 'sportRenderer':
            if (cell.value) {
              const matchedSport = SORTED_SPORT_OPTIONS.find(sport=>sport.value === cell.value);
              if (matchedSport) return matchedSport.label;
            }
            break;
          case 'dateRenderer':
            return new Date(cell.value).toLocaleString();
          case 'chapterCompletion':
            return `${toPercent(cell.value, data.CountData.PlayerCount)}% (${cell.value || 0})`;
          case 'playerCountRenderer':
            if (data.Role === 2) {
              return data.CountData.PlayerCount;
            }
            return `${toPercent(data.CountData.PlayerCompletedCount, data.CountData.PlayerCount)}% (${cell.value || 0})`;
          case 'getChapterCompletedStatus':
            const chapterIdentifier = Object.values(CHAPTER_IDENTIFIER); 
            const chapterId = chapterIdentifier[parseInt(colDef.headerName || '') - 1];
            return (data.Player_Data?.userProgress?.chapterProgress[chapterId]?.firstCompletedAt) ? 'Y' : 'N';
        }
        return cell.value;
      }
    });
  }

  return <GridButton 
    icon={downloadIcon}
    onClick={onDownload}
    label="Download"></GridButton>;
}

