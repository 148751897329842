import React, { useState } from 'react';
import { StyledErrorMessage, StyledFormButtonContainerRow, StyledFormContainer, StyledFormWrapper } from '../Form.styled';
import { Heading } from '../../Heading/Heading';
import { GenericReactHookForm } from '../ReactHookForm';
import Api from '../../../services/Api';
import { Button } from '../../Button/Button';

interface IUserFormProps {
  cohortId: string;
  sessionId: string;
  onArchive?: () => void;
  onError?: () => void;
  onCancel?: () => void;
}

export const SessionArchiveForm: React.FC<IUserFormProps> = ({ cohortId, sessionId, onArchive, onError, onCancel }) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const archiveSession = (cohortId: string, sessionId: string) => {
    setIsLoading(true);
    Api.archiveSession(cohortId, sessionId)
      .then((res: any) => {
        setIsLoading(false);
        onArchive && onArchive();
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        console.log(err.message);
        setErrorMessage('Network Error. Please try again');
        onError && onError();
      })
  }

  const onSubmit = () => {
    setErrorMessage('');
    archiveSession(cohortId, sessionId);
  }

  return <StyledFormWrapper>
    
      <StyledFormContainer>
        <Heading level='h4' primary>
          CONFIRM SESSION ARCHIVE
        </Heading>
        <GenericReactHookForm<any> defaultValues={{}} onSubmit={onSubmit} isLoading={isLoading}>
          <br/>
          <div>Once a Session is archived, you may only restore them by contacting the Movember Admin Team</div>
          <br/>
          <div>Are you sure you want to continue?</div>
          
          <StyledFormButtonContainerRow>
            <Button width="100%" onClick={(e) => {
              e.preventDefault(); //form will try to submit without this
              onCancel && onCancel();
            }} label="Cancel" />\
            <Button width="100%" solid type="submit" label={'Archive'} />
          </StyledFormButtonContainerRow>
          <StyledErrorMessage>
            {errorMessage}
          </StyledErrorMessage>
        </GenericReactHookForm>
      </StyledFormContainer>
  </StyledFormWrapper>

};