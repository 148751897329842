import React from "react";
import { Modal } from "../Modal/Modal";
import { StyledDisabledNotificationWrapper, StyledDisabledNotificationHeading, StyledDisabledNotificationText } from "../Modal/Modal.styled";
import { Button } from "../Button/Button";

interface IDisabledEditModalProps {
    onClose: () => void;
}

export const DisabledEditModal: React.FC<IDisabledEditModalProps> = ({ onClose }) => {
    return <Modal onCancel={onClose}>
        <StyledDisabledNotificationWrapper>
            <StyledDisabledNotificationHeading>Session already completed</StyledDisabledNotificationHeading>
            <StyledDisabledNotificationText>This session has already been run and is no longer editable.</StyledDisabledNotificationText>
            <Button width="100%" solid label='OK' onClick={onClose} />
        </StyledDisabledNotificationWrapper >
    </Modal>
}