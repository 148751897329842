import { PAGE, SESSION_STATUS, SPORT_TYPE, USER_ROLE, USER_STATUS } from "../constants";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import { ICohort, ISession } from "../models/ICohort";
import { IDeliveryPartner, IFacilitator, IPartner, IUser } from "../models/IUser";
import UserData from "../services/UserData";

export const numToAlpha = (num: number) => String.fromCharCode(num + 64);

export const getQueryParam = (paramName: string) : string => {
  let params = new URLSearchParams(window.location.search);
  return params.has(paramName)
    ? (params.get(paramName) || '')
    : '';
};

export const toPercent = (amountCount: number = 0, totalCount: number = 1) => {
  if(!totalCount) return 0;
  return ((amountCount / totalCount) * 100).toFixed(0);
};

export const checkPermission = {
  isAdmin: () => {
    return [USER_ROLE.SuperAdmin, USER_ROLE.Admin].includes(UserData.getUserRole());
  },
  isImplementationPartnerOrHigher: () => {
    return (UserData.getUserRole() === USER_ROLE.Partner) || checkPermission.isAdmin();
  },
  isDeliveryPartnerOrHigher: () => {
    return (UserData.getUserRole() === USER_ROLE.DeliveryPartner) || checkPermission.isImplementationPartnerOrHigher();
  },
  isFacilitatorOrHigher: () => {
    return (UserData.getUserRole() === USER_ROLE.Facilitator) || checkPermission.isDeliveryPartnerOrHigher();
  }
};

export interface IChainOfOwnership {
  facilitatorId: string | null,
  deliveryPartnerId: string | null,
  implementationPartnerId: string | null,
}

export type Roles = {
  cohort: ICohort;
  facilitator: IFacilitator;
  deliveryPartner: IDeliveryPartner;
  implementationPartner: IPartner;
}

export type Role = RequireExactlyOne<Roles, 'cohort' | 'facilitator' | 'deliveryPartner' | 'implementationPartner'>;

export const getChainOfOwnership = (role: Role): IChainOfOwnership | undefined => {
  if (checkPermission.isFacilitatorOrHigher()) {
    const chain: IChainOfOwnership = {
      facilitatorId: null,
      deliveryPartnerId: null,
      implementationPartnerId: null,
    }
    if (role.cohort) {
      // chain.facilitatorId = role.cohort.FacilitatorId || null;
      chain.deliveryPartnerId = role.cohort.DeliveryPartnerId || null; 
      chain.implementationPartnerId = role.cohort.PartnerId || null;
    } 
    if (role.facilitator) {
      chain.deliveryPartnerId = role.facilitator.Facilitator_DeliveryPartnerId || null;
      chain.implementationPartnerId = role.facilitator.Facilitator_PartnerId || null;
    } 
    if (role.deliveryPartner) {
      chain.implementationPartnerId = role.deliveryPartner.DeliveryPartner_PartnerId || null;
    }

    // filter based on access
    if (!checkPermission.isImplementationPartnerOrHigher()) {
      chain.implementationPartnerId = null;
      if (!checkPermission.isDeliveryPartnerOrHigher()) {
        chain.deliveryPartnerId = null;
      } 
    } 
    
    return chain;
  }
  return undefined;
}

export const convertHexToRGBA = (hex: string, opacity: number) => {
  const tempHex = hex.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const isAlphaNumeric = (str: string) => {
  const regex = /^[a-z0-9 ]+$/gi;
  return regex.test(str);
};

export const copyToClipboard = (str: string) => {
  const el = document.createElement('input');
  el.value = str;
  el.id = 'copy-clipboard-input';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  el.remove();
};

export type RequireExactlyOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
        [K in Keys]-?:
            Required<Pick<T, K>>
            & Partial<Record<Exclude<Keys, K>, undefined>>
    }[Keys];

export const openFacilitatorApp = (sport?: string) => {
  switch (sport) {
    case SPORT_TYPE.AustralianRules:
      window.open('https://movember.app.box.com/s/tm4yiagmi3xq1qelu2aygcwmsvv9rez2', '_blank', 'noopener,noreferrer');
      break;
    case SPORT_TYPE.WoWTest:
      window.open('https://www.wowhead.com', '_blank', 'noopener,noreferrer');
      break;
    default:
      window.open(window.location.origin + '/aotg-facilitator-app/START.html', '_blank');
      break;
  }
}

export const canUserEditSession = (session: ISession) => {

  //Facilitators can only edit if they are assigned to the session
  if (UserData.getUserRole() === USER_ROLE.Facilitator && UserData.getUserId() !== session.FacilitatorId) {
    return false;
  } 
  return true;
}

// Takes in two items (a + b) and sorts them based on an array of individual sortFns,
// allows you to easily have multiple sort conditions (eg. first sort archived to the bottom, then sort by the date created, then by... etc)
// If all of the sortFns return undefined (ie. no sort conditions met), this will return 0 for no change. 
export const sortByFunctions = (a: any, b: any, sortFns: ((a: any, b: any) => number | undefined)[]) => {
  let sortResult: number | undefined;

  sortFns.forEach(sortFn => {
    if(sortResult === undefined) {
      sortResult = sortFn(a, b);
    }
  });

  return sortResult || 0;
}

export const sortRowsByIdFn = (a: any, b: any) => {
  if (a.Id < b.Id) return -1;
  if (a.Id > b.Id) return 1;
};

export const sortRowsByArchivedFn = (a: any, b: any) => {
  //First, sort all records with ArchivedAt to the bottom of the list
  if (a.ArchivedAt && !b.ArchivedAt) return 1;
  if (!a.ArchivedAt && b.ArchivedAt) return -1;
  if (a.ArchivedAt && b.ArchivedAt) {
    //If both records have ArchivedAt, sort the oldest to the bottom
    if (a.ArchivedAt < b.ArchivedAt) return 1;
    if (a.ArchivedAt > b.ArchivedAt) return -1;
    return 0; //equal ArchivedAt... no change
  }
}

export const sortRowsByDateFn = (a: any, b: any) => {
  if (a.Date > b.Date) return -1;
  if (a.Date < b.Date) return 1;
}

export const canUserViewPlayers = (cohort: ICohort) => {
  if (UserData.getUserRole() === USER_ROLE.Facilitator) {
    const FacilitatorIdList = (Object.values(cohort.Sessions)
      .filter((session: ISession) => {
        return session && session.FacilitatorId
      })
      .map(session => session.FacilitatorId));
      return FacilitatorIdList.includes(UserData.getUserId());
  } 
  return true;
}

export const isSessionConsideredStarted = (session: ISession) => {
  if (session.SessionStartDateTime || session.DeliveryDate < new Date().toISOString()){
    return true;
  } else {
    return false;
  }
}

export const getUserStatus = (user: IUser) => {
  if(user.ArchivedAt) {
    return USER_STATUS.Archived;
  }

  //This only should apply to facilitators/delivery partners but there is no harm
  // in displaying it for players/IPs too if they somehow got into this state without an auth0id
  if(!user.Auth0Id) {
    return USER_STATUS.PendingReview;
  }

  //Again, this should only apply to facilitators/delivery partners
  // But for movember admins, they may want to see this for IPs too.
  // Players should not ever get in a state where they have an Auth0Id but no LastLogin, but let's keep this here anyway
  if(!user.LastLoginAt) {
    return USER_STATUS.EmailSent
  }

  return USER_STATUS.Active;
}

export const getCohortStatus = (cohort: ICohort): string => {
  return cohort.ArchivedAt ? 'Archived' : 'Active';
};

export const getSessionStatus = (session: ISession): string => {
  const status = session.Status;
  
  if (status === SESSION_STATUS.Archived) return "Archived";
  if (status === SESSION_STATUS.Pending) return "Archive Pending";
  if (status === SESSION_STATUS.Active) return "Active";
  return "Not Started";
}

export const sortSessionsByStatus = (sessions: ISession[]): ISession[] => {

  return sessions.sort((a, b) => {
    const statusA = getSessionStatus(a);
    const statusB = getSessionStatus(b);

    return (statusA === "Archived" ? 1 : 0) - (statusB === "Archived" ? 1 : 0); // making sure Archived sessions are sorted to the bottom
  });
}

export const canUserApproveFacilitator = () => {
  return [USER_ROLE.Partner, USER_ROLE.Admin, USER_ROLE.SuperAdmin].includes(UserData.getUserRole())
}

export const isoDateToLocalDate = (isoDate: string) => {
  return new Date(isoDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }).replace(',', '')
};

export  const redirectBack = (history: any) => {
  //Go back, or if no history (Eg. navigated straight here from a link) go back to dashboard
  history.length > 1 ? history.goBack() : history.push(`/`);
}

export const filterBreadcrumbs = (userRole: number, breadcrumbs: Breadcrumbs): Breadcrumbs => {
  switch (userRole) {
    case USER_ROLE.SuperAdmin, USER_ROLE.Admin:
      return breadcrumbs;
    case USER_ROLE.Player:
      return breadcrumbs.filter((breadcrumbs) => breadcrumbs.title !== PAGE.ADMIN)
    case USER_ROLE.DeliveryPartner:
      return breadcrumbs.filter((breadcrumb) => breadcrumb.title !== PAGE.ADMIN && breadcrumb.title !== PAGE.PARTNER);
    case USER_ROLE.Facilitator:
      return breadcrumbs.filter((breadcrumb) => breadcrumb.title !== PAGE.ADMIN && breadcrumb.title !== PAGE.DELIVERY_PARTNER && breadcrumb.title !== PAGE.PARTNER);
    default:
      return breadcrumbs;
  }
}
