import React from 'react';
import { StyledFilterMenu } from './GridFilterButton.styled';
import { GridFilterConfig } from '../GridContainer/GridContainer';
import { Checkbox } from '../../Checkbox/Checkbox';
import filterIcon from "../../../images/filter.svg"; 
import { DropdownButton } from '../../DropdownButton/DropdownButton';

interface GridFilterButtonProps {
  filterConfig: GridFilterConfig,
  isDisabled: boolean
  onFilterChange: (filterId: string, value: any) => void
}

export const GridFilterButton: React.FC<GridFilterButtonProps> = ({ filterConfig, isDisabled, onFilterChange }) => {

  return <DropdownButton 
    icon={filterIcon}
    label="Filter"
    disabled={isDisabled}
    dropdownContent={<StyledFilterMenu>
      {Object.keys(filterConfig).map((filterId, index) => {
        return <Checkbox 
          key={index}
          label={filterConfig[filterId].label}
          checked={filterConfig[filterId].isOn}
          onChange={(checked) => onFilterChange(filterId, checked)}
        />
      })}
    </StyledFilterMenu>}
  />;
}

