import styled, { css, keyframes } from 'styled-components';
import { NOTIFICATION_TYPE } from '../../constants';
import closeIcon from "../../images/close.svg"; 


const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

export const StyledContainerDiv = styled.div<{ type: NOTIFICATION_TYPE, isVisible: boolean }>`
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 32px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    animation: ${({ isVisible }) => (isVisible ? slideDown : slideUp)} 0.5s forwards;

    ${(props) =>
        props.type === NOTIFICATION_TYPE.INFO &&
        css`
            background-color: #AED2E1;
            color: #396382;
        `
    }
`

export const StyledMessage = styled.p<{ type: NOTIFICATION_TYPE }>`
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    margin-left: 10px;
`

export const StyledCloseButton = styled.button`
    position: absolute;
    right: 20px;
    cursor: pointer;
    background: none;
    border: none;
    width: 1rem;
    height: 1rem;
    background-size: contain;
    background-image: url(${closeIcon});
    color: inherit;
  }
`