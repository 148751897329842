import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import _languageEN from './translations/en.json';

const resources = {
    "en": _languageEN
}

i18n
.use(initReactI18next)
.init({
    resources,
    lng: 'en', //TODO: LUKE get the language properly
    fallbackLng: "en",
    debug: true,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        bindI18n: 'languageChanged loaded',
        nsMode: 'default'
    }
})

export default i18n;