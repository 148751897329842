import React, { useState } from 'react';
import Auth from '../../../services/Auth';
import { useTranslation } from "react-i18next";
import { StyledFormLabel, StyledErrorMessage, StyledFormContainer, StyledFormButtonContainerRow } from '../Form.styled';
import { ForgotLink, StyledLogoContainer, StyledShowPassword, StyledLoginFormPage } from './LoginForm.styled';
import { Heading } from '../../Heading/Heading';
import { ReactComponent as Logo } from '../../../../src/images/logo.svg';
import { GenericReactHookForm, Input } from './../ReactHookForm';
import { Checkbox } from '../../Checkbox/Checkbox';
import { Button } from '../../Button/Button';

interface loginData {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, seterrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loginData: loginData = {
    email: '',
    password: '',
  }

  const onSubmit = (data: loginData) => {
    setIsLoading(true);
    seterrorMessage('');
    console.log(data);
    Auth.loginWithEmailPassword(data.email, data.password, (err: any, res: any) => {
      if (err) {
        setIsLoading(false);
        seterrorMessage("Email and password don't match");
        console.log(err);
        // setFormError("Email and password don't match");
      }
    });
  }

  const passwordValidation = { required: true, minLength: 8};
  const emailValidation = {pattern:  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, required: true};
  const validateMessage = { required: 'This is a required field', pattern: 'Entered value does not match email format', minLength: 'Minimum length needs to larger than 8'}

  return <StyledLoginFormPage>
    <StyledLogoContainer>
      <Logo />
    </StyledLogoContainer>
    <StyledFormContainer>

      <Heading level="h4" primary text="LOG IN TO ADMIN PORTAL" />

      <GenericReactHookForm<loginData> defaultValues={loginData} onSubmit={onSubmit} isLoading={isLoading}>

        <StyledFormLabel>Email</StyledFormLabel>
        <Input name='email' placeholder={'john@movember.com'} type='text' validate={emailValidation} validatemessage={validateMessage} />
        <StyledFormLabel>Password</StyledFormLabel>
        <Input name='password' placeholder={'********'} type={showPassword ? 'text' : 'password'} validate={passwordValidation} validatemessage={validateMessage} />
        <StyledShowPassword>
          <Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} label="Show password" />
        </StyledShowPassword>

        <StyledErrorMessage>
        {errorMessage}
        </StyledErrorMessage>
        <StyledFormButtonContainerRow>
          <Button solid width='100%' label="Log in" type="submit" />
        </StyledFormButtonContainerRow>
        <ForgotLink tabIndex={-1} to="/reset-password">Forgot password?</ForgotLink>

      </GenericReactHookForm>
    </StyledFormContainer>

  </StyledLoginFormPage>
};