import React, { useState, useEffect } from 'react';
import Api from '../../../services/Api';
import UserData from '../../../services/UserData';
import { IFacilitator } from '../../../models/IUser';

import { CohortsGrid, DEFAULT_COHORT_GRID_FILTERS } from '../../Grids/CohortsGrid';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';

import { GridContainer } from '../../Grids/GridContainer/GridContainer';
import { makeBreadcrumbs } from '../../../utils/breadcrumbs';
import { PAGE } from '../../../constants';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

interface IFacilitatorViewProps {
  facilitatorId?: string
  initialTabId?: string
}

export const FacilitatorView: React.FC<IFacilitatorViewProps> = ({ facilitatorId, initialTabId = 'cohorts' }) => {

  const [facilitator, setFacilitator] = useState<IFacilitator | null>(null);

  //If no id supplied, user is getting themself
  if(!facilitatorId) facilitatorId = UserData.getUserId();

  useEffect(() => {
    if(facilitatorId) {
      Api.getFacilitatorById(facilitatorId)
        .then((facilitator: IFacilitator | undefined) => {
          if(!facilitator) throw new Error('Facilitator not found');
          setFacilitator(facilitator);
        });
    }
  }, [facilitatorId]);

  const sendBreadCrumbInfo = (facilitator: IFacilitator) => {
    return makeBreadcrumbs(PAGE.FACILITATOR, UserData.getUserRole(), facilitator);
  }

  return <div>
    {facilitator 
      ? <>
        <Breadcrumbs breadCrumbs={sendBreadCrumbInfo(facilitator)}/>
        <GridContainer
          initialTabId={initialTabId}
          gridConfigs={[{
            id: 'cohorts',
            title: 'Cohorts',
            gridElement: <CohortsGrid forFacilitatorId={facilitatorId} />,
            filterConfig: DEFAULT_COHORT_GRID_FILTERS,
            count: facilitator.CountData.CohortCount || 0
          }]} /></>
    : <LoadingSpinner />}
  </div>;
}