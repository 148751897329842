import { DefaultTheme } from 'styled-components';

const colors: DefaultTheme['colors'] = {
  white: '#ffffff',
  black: '#000000',

  primaryFont: '#0c1e2c',
  secondaryFont: '#1c1f26',

  primaryBackground: '#DEDEDE',
  secondaryBackground: '#d8e2e7',

  primaryInteraction: '#001eff',

  actionPrimary300: '#173ed7',
  brandThemeDark500: '#242424',
  disabled300: '#797979',
  error300: '#d40000',
  textField: {
    border: {
      color: '#ddd',
      focusColor: '#444'
    },
    disabled: {
      color: '#cecece',
      borderColor: '#EEEFF0',
    },
  }
} as const;

const breakpoints = {
  xxs: '320px',
  xs: '520px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1760px'
};

export const AotgAdminTheme: DefaultTheme = {
  dark: false,
  name: 'AOTG Admin Theme',
  colors,
  breakpoints,
  containerMaxWidth: '1080px',
  fonts: {
    body: 'Overpass',
    heading: 'Teko'
  }
};

//For styled components props
export interface IStyledProps {
  theme: DefaultTheme;
}