import styled, { keyframes } from 'styled-components';
import { IStyledProps } from '../../theme/AotgAdminTheme';

const getLoadingSpinnnerKeyFrames = () => {
  return keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `;
};

const LOADING_SPINNER_SIZE_PX = 100;

interface IStyledLoadingSpinnerProps extends IStyledProps {
  backgroundColour?: string
}

export const StyledLoadingSpinner = styled.div`
  position: absolute;
  left: calc(50% - ${LOADING_SPINNER_SIZE_PX / 2}px);
  top: calc(50% - ${LOADING_SPINNER_SIZE_PX / 2}px);
  font-size: 10px;
  width: ${LOADING_SPINNER_SIZE_PX}px;
  height: ${LOADING_SPINNER_SIZE_PX}px;
  border-radius: 50%;
  background: ${(props: IStyledLoadingSpinnerProps) => props.theme.colors.white};
  background: linear-gradient(to right, ${(props: IStyledLoadingSpinnerProps) => props.theme.colors.white} 10%, ${(props: IStyledLoadingSpinnerProps) => (props.backgroundColour || props.theme.colors.black)} 42%);
  animation: ${getLoadingSpinnnerKeyFrames()} 1.4s infinite linear;
  transform: translateZ(0);

  :before {
    width: 50%;
    height: 50%;
    background: ${(props: IStyledLoadingSpinnerProps) => props.theme.colors.white};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  :after {
    background: ${(props: IStyledLoadingSpinnerProps) => (props.backgroundColour || props.theme.colors.black)};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;