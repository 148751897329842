import styled from 'styled-components';
import { AotgAdminTheme } from '../../theme/AotgAdminTheme';
import { Heading } from '../Heading/Heading';

export const StyledModalWrapper = styled.div`
  display: block;
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: rgba(0,0,0,0.7);
  z-index: 1;
`;
export const StyledModalContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledEmailModalWrapper = styled.div`
  padding: 2rem 3rem;
  background: ${AotgAdminTheme.colors.primaryBackground};
  width: 400px;
`;

export const StyledDisabledNotificationWrapper = styled(StyledEmailModalWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 270px;
  padding: 40px 40px 30px 40px;
`;

export const StyledDisabledNotificationHeading = styled(Heading).attrs({ primary: true })`
  display: flex;
  color: #00000080;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  line-height: 43px;
  `;

export const StyledDisabledNotificationText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const EditIcon = styled.div`
cursor: pointer;
`;

export const StyledModalWrapperCloseButton = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 4rem;
  height: 4rem;
  background: rgba(0,0,0,0.5);
  border-radius:100%;
  cursor: pointer;

  :before, :after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    background: rgba(255,255,255,1);
    width: 2rem;
    height: 0.35rem;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  :before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;