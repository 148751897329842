import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import { ICohort } from '../../models/ICohort';
import Api from '../../services/Api';
import plusIcon from '../../images/plus.svg';
import { Modal } from '../Modal/Modal';
import { MaxSessionsModal } from '../View/CohortView/MaxSessionsModal';
interface ICreateNewButtonProps {
  //If ID specified, allows the creation of a session for this cohort
  canAddSessionForCohort?: ICohort;

  //Appended to the URL when doing any of the below actions
  appendIdString?: string;

  canAddPartner?: boolean;
  canAddDeliveryPartner?: boolean;
  canAddFacilitator?: boolean;
  canAddCohort?: boolean;
}

export const CreateNewButton: React.FC<ICreateNewButtonProps> = ({ canAddSessionForCohort: addSessionForCohort, appendIdString, canAddCohort, canAddFacilitator, canAddDeliveryPartner, canAddPartner }) => {
  const history = useHistory();
  const [showMaxSessionsModal, setShowMaxSessionsModal] = useState<boolean>(false);

  const createNewSession = () => {
    addSessionForCohort?.Sessions.Literacy && addSessionForCohort?.Sessions.Resilience
      ? setShowMaxSessionsModal(true)
      : history.push(`/edit/cohort/${appendIdString}`)
  };
  const createNewCohort = () => {
    history.push(`/edit/cohort?${appendIdString}`)
  };
  const createNewFacilitator = () => {
    history.push(`/edit/facilitator?${appendIdString}`)
  };
  const createNewDeliveryPartner = () => {
    history.push(`/edit/deliveryPartner?${appendIdString}`)
  };
  const createNewPartner = () => {
    history.push(`/edit/partner`)
  };


  const options = [];
  if (addSessionForCohort) {
    options.push({ label: "Session", onClick: createNewSession })
  }
  if (canAddCohort) {
    options.push({ label: "Cohort", onClick: createNewCohort })
  }
  if (canAddFacilitator) {
    options.push({ label: "Facilitator", onClick: createNewFacilitator })
  }
  if (canAddDeliveryPartner) {
    options.push({ label: "Delivery Partner", onClick: createNewDeliveryPartner })
  }
  if (canAddPartner) {
    options.push({ label: "Partner", onClick: createNewPartner })
  }

  return <>
    {showMaxSessionsModal
      ? <Modal onCancel={() => setShowMaxSessionsModal(false)}>
        <MaxSessionsModal onClose={() => setShowMaxSessionsModal(false)} />
      </Modal>
      : <DropdownButton solid icon={plusIcon} label="Create New" options={options} />}
  </>;
}

