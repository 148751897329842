import styled from 'styled-components';
import { IStyledProps } from '../../../theme/AotgAdminTheme';

const evenRowBackgroundColor = '#FFFFFF';
const oddRowBackgroundColor = '#F4F4F4';
const backgroundColor = '#DEDEDE';
const columnHeaderDividerColor = '#BABFC7';

export const StyledGridContainer = styled.div`
  --ag-background-color: ${backgroundColor};
  --ag-header-background-color: ${evenRowBackgroundColor};
  --ag-header-column-resize-handle-color: ${columnHeaderDividerColor};

  width: auto;
  .ag-root {
    min-height: 30vh;
  }
  .grid .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
    flex: 0 1 100%;
  }
  .grid.not-visible {
    display: none !important;
  }
  .session-grid {
    height: 30vh !important;
  }
  .task-grid {
    .ag-header-cell[aria-colindex="1"] {
      .ag-cell-label-container {
          justify-content: left !important;
          margin-left: 10px;
        }
    }
  }
  a {
    color: ${(props: IStyledProps) => props.theme.colors.black};
    :hover {
      text-decoration: underline;
    }
  }
  .ag-row {
    background-color: ${evenRowBackgroundColor} !important;
    border-bottom-style: none;

    &.archived-row {
      font-style: italic;
      color: #6D6D6D;
      a {
        color: #6D6D6D;
      }
    }
  } 
  .ag-row-odd {
    background-color: ${oddRowBackgroundColor} !important;
  }
  .ag-cell:hover {
    background-color: ${backgroundColor} ;
  }
  .ag-ltr .ag-cell {
    text-align: center !important;
    text-transform: capitalize;
  }
  // column headings
  .ag-header-container {
    * {
      justify-content: center !important;
    }
    // hides the filter menu icon
    .ag-icon-menu::before {
      display: none;
    }

  }

  .ag-tooltip {
    max-width: 180px;
    white-space: normal;
    background-color: #F8F8F8 !important;
    padding: 10px !important;
    border-radius: 4px;
    border: 1px solid #BBBFC6;
  }
`;

export const StyledCellHyperLink = styled.a`
    color: ${(props: IStyledProps) => props.theme.colors.actionPrimary300} !important;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
`;

export const StyledTaskGrid = styled.div`

`;