import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import { PAGE } from "../constants";
import { ICohort, ISession } from "../models/ICohort";
import { IDeliveryPartner, IFacilitator, IPartner } from "../models/IUser";
import { filterBreadcrumbs } from "./helper";

// TODO: Im not a very big fan of this way of function type overloading but not sure typescript has a usefull/better way to do this
export function makeBreadcrumbs(
    page: PAGE,
    userRole: number,
    user?: IPartner | IDeliveryPartner | IFacilitator,
    cohort?: ICohort,
    session?: ISession
): Breadcrumbs {
    let breadcrumbs: Breadcrumbs = [];

    switch (page) {
        case PAGE.PARTNER:
            breadcrumbs = breadcrumbTemplates[PAGE.PARTNER](user as IPartner);
            break;

        case PAGE.DELIVERY_PARTNER:
            breadcrumbs = breadcrumbTemplates[PAGE.DELIVERY_PARTNER](user as IDeliveryPartner);
            break;

        case PAGE.FACILITATOR:
            breadcrumbs = breadcrumbTemplates[PAGE.FACILITATOR](user as IFacilitator);
            break;

        case PAGE.COHORT:
            if (cohort) {
                breadcrumbs = breadcrumbTemplates[PAGE.COHORT](cohort);
            }
            break;

        case PAGE.SESSION:
            if (cohort && session) {
                breadcrumbs = breadcrumbTemplates[PAGE.SESSION](cohort, session);
            }
            break;

        case PAGE.TASK:
            breadcrumbs = breadcrumbTemplates[PAGE.TASK]();
            break;
    }

    return filterBreadcrumbs(userRole, breadcrumbs);
}

export function makeTaskBreadcrumb(taskPage: PAGE.TASK | PAGE.TASK_DETAIL, taskId?: string) {
    let breadcrumbs: Breadcrumbs = [];
    switch (taskPage) {
        case PAGE.TASK:
            breadcrumbs = breadcrumbTemplates[PAGE.TASK]();
            break;
        case PAGE.TASK_DETAIL:
            if (taskId) {
                breadcrumbs = breadcrumbTemplates[PAGE.TASK_DETAIL](taskId);
            }
    }

    return breadcrumbs;
}


const breadcrumbTemplates = {
    [PAGE.PARTNER]: (partner: IPartner) => [
        adminBreadcrumb,
        implmentationPartnerBreadcrumb(partner.FirstName + ' ' + partner.LastName, partner.Id)
    ],

    [PAGE.DELIVERY_PARTNER]: (deliveryPartner: IDeliveryPartner) => [
        adminBreadcrumb,
        implmentationPartnerBreadcrumb(deliveryPartner.DeliveryPartner_PartnerName, deliveryPartner.DeliveryPartner_PartnerId),
        deliveryPartnerBreadcrumb(deliveryPartner.FirstName + ' ' + deliveryPartner.LastName, deliveryPartner.Id)
    ],

    [PAGE.FACILITATOR]: (facilitator: IFacilitator) => [
        adminBreadcrumb,
        implmentationPartnerBreadcrumb(facilitator.Facilitator_PartnerName, facilitator.Facilitator_PartnerId),
        ...(facilitator.Facilitator_DeliveryPartnerId ? [deliveryPartnerBreadcrumb(facilitator.Facilitator_DeliveryPartnerName, facilitator.Facilitator_DeliveryPartnerId)] : []),
        facilitatorBreadcrumb(facilitator.FirstName + ' ' + facilitator.LastName, facilitator.Id)
    ],

    [PAGE.COHORT]: (cohort: ICohort) => [
        adminBreadcrumb,
        implmentationPartnerBreadcrumb(cohort.ImplmentationPartnerName, cohort.PartnerId),
        ...(cohort.DeliveryPartnerId ? [deliveryPartnerBreadcrumb(cohort.DeliveryPartnerName, cohort.DeliveryPartnerId)] : []),
        cohortBreadcrumb(cohort.Title, cohort.Id)
    ],

    [PAGE.SESSION]: (cohort: ICohort, session: ISession) => [
        adminBreadcrumb,
        implmentationPartnerBreadcrumb(cohort.ImplmentationPartnerName, cohort.PartnerId),
        ...(cohort.DeliveryPartnerId ? [deliveryPartnerBreadcrumb(cohort.DeliveryPartnerName, cohort.DeliveryPartnerId)] : []),
        cohortBreadcrumb(cohort.Title, cohort.Id),
        sessionBreadcrumb(cohort.AgeGroup + ' ' + cohort.Gender + ' ' + cohort.Audience, session.Id)
    ],

    [PAGE.TASK]: () => [
        adminBreadcrumb,
        taskBreadcrumb
    ],

    [PAGE.TASK_DETAIL]: (taskId: string) => [
        adminBreadcrumb,
        taskBreadcrumb,
        taskDetailBreadcrumb(taskId)
    ]
};

const adminBreadcrumb = { title: 'Admin', description: 'Movember Admin', toLocation: '/' }
const implmentationPartnerBreadcrumb = (description?: string, id?: string) => ({ title: 'Implementation Partner', description: `${description}`, toLocation: `/partner/${id}` })
const deliveryPartnerBreadcrumb = (description?: string, id?: string) => ({ title: 'Delivery Partner', description: `${description}`, toLocation: `/deliverypartner/${id}` })
const facilitatorBreadcrumb = (description?: string, id?: string) => ({ title: 'Facilitator', description: `${description}`, toLocation: `/facilitator/${id}` })
const cohortBreadcrumb = (description?: string, id?: string) => ({ title: 'Cohorts', description: `${description}`, toLocation: `/cohort/${id}` })
const sessionBreadcrumb = (description?: string, id?: string) => ({ title: 'Session', description: `${description}`, toLocation: `/session/${id}` })
const taskBreadcrumb = { title: '', description: "Tasks", toLocation: '/task' }
const taskDetailBreadcrumb = (id: string) => ({ title: '', description: "FACILITATOR APPROVAL", toLocation: `/task/${id}` })