import { ICountData } from "./ICountData";
export enum FACILITATOR_REVIEW_RESULT {
  Approved = "approved",
  Rejected = "rejected",
}

export interface IUser {
  Id: string,
  Role: number,
  FirstName: string,
  LastName: string,
  Email: string,
  
  Auth0Id: string, //Used to work out if someone is pending review or not
// UserData: string, //json blob, questionable

  CountData: ICountData

  CreatedAt: string, //isostring format
  ModifiedAt: string,
  ArchivedAt?: string,
  LastLoginAt?: string,

  Status?: string
}

export interface IPartner extends IUser {
  Partner_Country: string;
  Partner_Sport: string;

  Partner_Seasons_Intervals?: number[];
}

export interface IDeliveryPartner extends IUser {
  DeliveryPartner_Country?: string;
  DeliveryPartner_Sport?: string;

  DeliveryPartner_PartnerId: string
  DeliveryPartner_PartnerName?: string,
}

export interface IFacilitator extends IUser {
  Facilitator_PartnerId: string;
  Facilitator_PartnerName: string;
  Facilitator_DeliveryPartnerId?: string;
  Facilitator_DeliveryPartnerName?: string;
  Facilitator_ReviewResult?: FACILITATOR_REVIEW_RESULT,
  Facilitator_ReviewedAt?: string,
  Facilitator_ReviewedByUserId?: string,
}

export interface IPlayer extends IUser {
  HasCompleted: boolean,
  Player_CohortId: string,
  Status: string
  Player_Data: any;
}

export interface ITask {
  Type: string,
  Id: string,
  Title: string,
  Date: string,
}