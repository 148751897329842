import React, { forwardRef, useMemo } from "react";
import arrowRight from '../TaskManage/arrow-right.png';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { ITask } from "../../models/IUser";
import { NoTaskGridOverlayTemplate } from "../TaskManage/NoTaskGridOverlayTemplate";
import { TasksCtx } from "../../contexts/TasksContext";
import { AgGridReact } from 'ag-grid-react';

interface ITaskGridProps {
  className?: string;
}

export const TaskGrid: React.FC<ITaskGridProps> = forwardRef((props, agGridRef: any) => {
  const { tasks } = React.useContext(TasksCtx);

  const noRowsOverlayComponent = useMemo(() => { return NoTaskGridOverlayTemplate }, []);

  return <AgGridReact 
      {...props}
      noRowsOverlayComponent ={noRowsOverlayComponent}
      rowData={tasks}
      columnDefs={[
        {
          field: "Notifications",
          headerName: "Notifications",
          cellRenderer: (params: ICellRendererParams) => {
            const task = params.data as ITask;
            const taskType = task.Type;
            return <div style={{float: 'left'}}>{taskType + ': ' + task.Title}</div>
          },
          initialFlex: 3,
          flex: 3,
          cellStyle: { 'justify-content': 'left !important' }
        },
        {
          field: "Date Created",
          headerName: "Date Created",
          cellRenderer: (params: ICellRendererParams) => {
            const task = params.data as ITask;
            const [year, month, day] = task.Date.split('T')[0].split('-');
            return <div>{day + '-' + month + '-' + year}</div>
        },
          initialFlex: 1,
          flex: 1
        },
        {
          field: "View",
          headerName: "View",
          cellRenderer: (params: ICellRendererParams) => {
            return <Link 
              to={'/task/' + params.data.Id}
            ><img src={arrowRight} alt='arrow right icon' /></Link>
          },
          sortable: false,
          initialFlex: 1,
          flex: 1
        }
      ]}
    />
});